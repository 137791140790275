import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { alert, confirm } from 'devextreme/ui/dialog';
import { SecurityService } from '../../shared/services/security.service';
import { StorageService } from '../../shared/services/storage.service';
import { PatentService } from '../patent.service';
import { DataExportComponent } from './data-export/data-export.component';
import { DeliveryReportComponent } from './delivery-report/delivery-report.component';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent{

  pdfsDataSource: any = {};
  toobarItems: any = {};
  pdfStatus: any = [];
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild(DeliveryReportComponent, { static: true }) deliveryReport: DeliveryReportComponent;
  @ViewChild(DataExportComponent, { static: true }) dataExport: DataExportComponent;
  constructor(private patentService: PatentService, private securityService: SecurityService, private router: Router, private storage: StorageService) {
    this.pdfsDataSource.store = new CustomStore({
      load: async (loadOptions: any) => {
        let params = '?';
        params += 'skip=' + (loadOptions.skip || 0);
        params += '&take=' + (loadOptions.take || 12);
        params += loadOptions.filter ? '&filter=' + JSON.stringify(loadOptions.filter) : '';
        params += loadOptions.sort ? '&sort=' + JSON.stringify(loadOptions.sort) : '';
        params += '&requireTotalCount=true';

        this.dataGrid.instance.clearSelection();
        return this.patentService.getPdfs(params).toPromise()
      }
    });

    this.pdfStatus = this.patentService.pdfStatus().subscribe(data => {
      this.pdfStatus = data;
    });
  }

  assign() {
    const datas = this.dataGrid.instance.getSelectedRowsData();
    if (!(datas && datas[0] && datas[0].id)) {
      alert('No PDF file select for this action, please choose a pdf first!', 'Error');
    } else {
      const params = {
        Id: datas[0].id,
      };

      this.patentService.pdfAssign(params).subscribe(id => {
        this.router.navigate(['/patent', { pdfId: id }]);
      });
    }
  }

  upload() {
    this.router.navigate(['pdfupload']);
  }

  export() {
    this.deliveryReport.popupVisible = true
  }

  data_export(){
    this.dataExport.popupVisible = true

  }
  // UserId: <string>this.securityService.UserData.id,
  // EditorEmail: this.securityService.UserData.userPrincipalName,

  edit(data: any) {
    this.router.navigate(['/patent', { pdfId: data.id }]);
  }

  delete(key: number) {
    this.patentService.getProcessedPatents(key).subscribe(d => {
      if (d.length > 0) {
        alert('Contains processed legal events on this pdf, please remove it first', 'Error');
      } else {
        const dialog = confirm('Do you really want to delete this PDF?', 'Confirm');
        dialog.then(dialogResult => {
          if (dialogResult) {
            this.patentService.deletePdf(key).subscribe(res => {
              this.dataGrid.instance.getDataSource().reload();
            });
          }
        });
      }
    });
  }
  show(data: any) {
    // return data.status === 3 && data.userId === this.securityService.UserData.id;
    return true;
  }
}
