<accordion-group #group14 *ngIf="config && config.visible(stable)" [isOpen]="openToggle" (paste)="paste($event)"
    [ngClass]="{'heading-blue': !stable}">
    <div accordion-heading>
        {{headerText}}
        <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
    </div>
    <div [formGroup]="formGroup" class="row">
        <div class="col-md-8" *ngIf="config && config.pctApplNumber.visible">
            <div class="form-group">
                <label class="app-biblio-title">(86) PCT Application Number</label>
                <input id="pctApplNumber" class="form-control form-input" type="text" formControlName="pctApplNumber">
                <div *ngIf="formGroup.get('pctApplNumber').hasError('required') && formGroup.get('pctApplNumber').touched"
                    class="alert alert-danger">Required field.</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctApplKind.visible">
            <div class="form-group">
                <label class="app-biblio-title">(86) PCT Application Kind</label>
                <input id="pctApplKind" class="form-control form-input" placeholder="^[A-Z][0-9]?$" type="text" formControlName="pctApplKind">
                <div *ngIf="formGroup.get('pctApplKind').hasError('required') && formGroup.get('pctApplKind').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctApplKind').hasError('pattern')" class="alert alert-danger">Pattern
                    not matched</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctApplDate.visible">
            <div class="form-group">
                <label class="app-biblio-title">(86) PCT Application Date</label>
                <input id="pctApplDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                    formControlName="pctApplDate">
                <div *ngIf="formGroup.get('pctApplDate').hasError('required')  && formGroup.get('pctApplDate').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctApplDate').hasError('error')" class="alert alert-danger">Date format not
                    match</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctApplCountry.visible">
            <div class="form-group">
                <label class="app-biblio-title">(86) PCT Application Country</label>
                <input id="pctApplCountry" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                    formControlName="pctApplCountry">
                <div *ngIf="formGroup.get('pctApplCountry').hasError('required')  && formGroup.get('pctApplCountry').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctApplCountry').hasError('pattern')" class="alert alert-danger">Pattern
                    not matched</div>
            </div>
        </div>
        <div class="col-md-8" *ngIf="config && config.pctPublNumber.visible">
            <div class="form-group">
                <label class="app-biblio-title">(87) PCT Publication Number</label>
                <input id="pctPublNumber" class="form-control form-input" type="text" formControlName="pctPublNumber">
                <div *ngIf="formGroup.get('pctPublNumber').hasError('required')  && formGroup.get('pctPublNumber').touched"
                    class="alert alert-danger">Required field.</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctPublKind.visible">
            <div class="form-group">
                <label class="app-biblio-title">(87) PCT Publication Kind</label>
                <input id="pctPublKind" class="form-control form-input" placeholder="^[A-Z][0-9]?$" type="text" formControlName="pctPublKind">
                <div *ngIf="formGroup.get('pctPublKind').hasError('required') && formGroup.get('pctPublKind').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctPublKind').hasError('pattern')" class="alert alert-danger">Pattern
                    not matched</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctPublDate.visible">
            <div class="form-group">
                <label class="app-biblio-title">(87) PCT Publication Date</label>
                <input id="pctPublDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                    formControlName="pctPublDate">
                <div *ngIf="formGroup.get('pctPublDate').hasError('required')  && formGroup.get('pctPublDate').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctPublDate').hasError('error')" class="alert alert-danger">Date format not
                    match</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctPublCountry.visible">
            <div class="form-group">
                <label class="app-biblio-title">(87) PCT Publication Country</label>
                <input id="pctPublCountry" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                    formControlName="pctPublCountry">
                <div *ngIf="formGroup.get('pctPublCountry').hasError('required')  && formGroup.get('pctPublCountry').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctPublCountry').hasError('pattern')" class="alert alert-danger">Pattern
                    not matched</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctSearchDate.visible">
            <div class="form-group">
                <label class="app-biblio-title">(88) Date of deferred publication of the search report</label>
                <input id="pctSearchDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                    formControlName="pctSearchDate">
                <div *ngIf="formGroup.get('pctSearchDate').hasError('required')  && formGroup.get('pctSearchDate').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctSearchDate').hasError('error')" class="alert alert-danger">Date format
                    not match</div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="config && config.pctNationalDate.visible">
            <div class="form-group">
                <label class="app-biblio-title">(85) PCT National Phase Date</label>
                <input id="pctNationalDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                    formControlName="pctNationalDate">
                <div *ngIf="formGroup.get('pctNationalDate').hasError('required')  && formGroup.get('pctNationalDate').touched"
                    class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('pctNationalDate').hasError('error')" class="alert alert-danger">Date format
                    not match</div>
            </div>
        </div>
        <div class="col-md-8" *ngIf="config && config.designatedStates.state.visible">
            <div class="form-group">
                <label class="app-biblio-title">(84) Designated States</label>
                <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addDesignatedState()">
                    Add</button>
                <div formArrayName="designatedStates" class="well well-lg">
                    <div *ngFor="let ds of designatedStates.controls; let i=index" [formGroupName]="i" class="row">
                        <div class="col">
                            <div class="form-group">
                                <input id="designatedStates-{{i}}-state" class="form-control form-input" type="text"
                                    placeholder="[A-Z]{2}" formControlName="state">
                                <div *ngIf="designatedStates.at(i).get('state').hasError('required') && designatedStates.at(i).get('state').touched"
                                    class="alert alert-danger">Required field.</div>
                                <div *ngIf="designatedStates.at(i).get('state').hasError('pattern')" class="alert alert-danger">Pattern
                                    not matched</div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group align-text-bottom">
                                <a (click)="deleteDesignatedState(i)" class="dx-icon-remove"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</accordion-group>