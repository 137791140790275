<div [formGroup]="formGroup" *ngIf="config && config.visible(stable)" (paste)="paste($event)">
  <div formArrayName="formArray" class="well well-lg">
    <div class="row">
      <h4 style="margin: 0px 15px">{{headerText}}</h4>
    </div>
    <div *ngFor="let item of formArray.controls; let i=index" [formGroupName]="i" class="row">
      <div class="col-md-6" *ngIf="config && config.name.visible">
        <div class="form-group">
          <label class="app-biblio-title">Name</label>
          <input id="{{i}}-name" class="form-control form-input" type="text" formControlName="name" style="background-color: lightblue; ">
          <div *ngIf="formArray.at(i).get('name').hasError('required') && formArray.at(i).get('name').touched" class="alert alert-danger">Required
            field.</div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="config && config.country.visible">
        <div class="form-group">
          <label class="app-biblio-title">Country</label>
          <input id="{{i}}-country" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="country">
          <div *ngIf="formArray.at(i).get('country').hasError('required') && formArray.at(i).get('country').touched"
            class="alert alert-danger">Required field.</div>
          <div *ngIf="formArray.at(i).get('country').hasError('pattern')" class="alert alert-danger">Pattern
            not matched</div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="config && config.language.visible">
        <div class="form-group">
          <label class="app-biblio-title">Language</label>
          <input id="{{i}}-language" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="language">
          <div *ngIf="formArray.at(i).get('language').hasError('required') && formArray.at(i).get('language').touched"
            class="alert alert-danger">Required field.</div>
          <div *ngIf="formArray.at(i).get('language').hasError('pattern')" class="alert alert-danger">Pattern
            not matched</div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="config && config.address.visible">
        <div class="form-group">
          <label class="app-biblio-title">Address</label>
          <input id="{{i}}-address" class="form-control form-input" type="text" formControlName="address">
          <div *ngIf="formArray.at(i).get('address').hasError('required') && formArray.at(i).get('address').touched"
            class="alert alert-danger">Required field.</div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group align-text-bottom">
          <a (click)="deleteFormArray(i)" class="dx-icon-remove"></a>
        </div>
      </div>
      <div *ngIf="showTranslations && config && (config.translations.language.visible || config.translations.trName.visible || config.translations.trAddress.visible)"
        formArrayName="translations" style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
        <div class="translation">Next Language
          <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addTranslation(item)"> Add
            Language</button>
        </div>
        <div *ngFor="let translation of translationsArray(i).controls; let j=index" [formGroupName]="j" class="row">
          <div class="col-md-2" *ngIf="config && config.translations.language.visible">
            <div class="form-group">
              <label class="app-biblio-title">Language</label>
              <input id="{{i}}-translations-{{j}}-language" class="form-control form-input" type="text" formControlName="language">
              <div *ngIf="formArray.at(i).get('translations').at(j).get('language').hasError('required') && formArray.at(i).get('translations').at(j).get('language').touched"
                class="alert alert-danger">Required field.</div>
              <div *ngIf="formArray.at(i).get('translations').at(j).get('language').hasError('pattern')" class="alert alert-danger">Pattern
                not matched</div>
            </div>
          </div>
          <div class="col-md-8" *ngIf="config && config.translations.trName.visible">
            <div class="form-group">
              <label class="app-biblio-title">Name</label>
              <input id="{{i}}-translations-{{j}}-trName" class="form-control form-input" type="text" formControlName="trName">
              <div *ngIf="formArray.at(i).get('translations').at(j).get('trName').hasError('required') && formArray.at(i).get('translations').at(j).get('trName').touched"
                class="alert alert-danger">Required field.</div>
            </div>
          </div>
          <div class="col-md-10" *ngIf="config && config.translations.trAddress.visible">
            <div class="form-group">
              <label class="app-biblio-title">Address</label>
              <input id="{{i}}-translations-{{j}}-trAddress" class="form-control form-input" type="text"
                formControlName="trAddress">
              <div *ngIf="formArray.at(i).get('translations').at(j).get('trAddress').hasError('required') && formArray.at(i).get('translations').at(j).get('trAddress').touched"
                class="alert alert-danger">Required field.</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group align-text-bottom">
              <a (click)="deleteTranslate(i, j)" class="dx-icon-remove"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addFormArray()"> Add</button>
      <button *ngIf="config && (config.translations.language.visible || config.translations.trName.visible || config.translations.trAddress.visible)"
        class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addTranslations()"> Add Language</button>
    </div>
  </div>
</div>