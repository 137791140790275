import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Translation } from '../../../shared/models/ibiblio.model';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';
import { BiblioBaseComponent } from '../biblio-base.component';

@Component({
  selector: 'app-biblio-legal-event',
  templateUrl: './biblio-legal-event.component.html',
  styleUrls: ['./biblio-legal-event.component.scss']
})
export class BiblioLegalEventComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  showTranslations = false;
  private previousValue = '';
  @Input() biblioId?: number;

  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      this.showTranslations = false;
      (this.formGroup as AbstractControl).patchValue(Object.assign({}, this.data, { translations: [] }));
      this.setTranslations(this.formGroup, this.data.translations);
    }
    if (this.data && changes.config && this.config && this.config.legalEvent
      && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      const transGroup = this.formGroup.get('translations') as FormArray;
      if (transGroup && transGroup.length < 1 && this.config.legalEvent['translations'].required()) {
        this.addTranslation();
        this.showTranslations = true;
      }
      this.setValidator(this.formGroup, this.config.legalEvent, this.config.legalEvent.ConfigName);
    }
  }

  setTranslations(itemG: FormGroup, data: Translation[]) {
    this.translations.clear();
    if ((null === data || (data && data.length === 0)) && this.config.legalEvent.translations.required()) {
      data = [<Translation>{ type: 'INID', language: this.config.legalEvent.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  get translations() {
    return this.formGroup.get('translations') as FormArray;
  }


  addTranslation() {
    // const item = this.formArray.at(i)
    const translations = this.formGroup.get('translations') as FormArray;
    const translate = this.formBuilder.group({
      type: 'INID',
      language: this.config.legalEvent.translations.language.default,
      tr: '',
    });

    this.formGroup.get('note').valueChanges.subscribe(value => this.getTranslate(translate.get('tr'), translate.get('language').value, value));
    this.getTranslate(translate.get('tr'), translate.get('language').value, this.formGroup.get('note').value);

    translations.push(translate);
    this.showTranslations = true;
    this.setValidator(translate, this.config.legalEvent.translations, 'translations');

  }

  deleteTranslate(j: number) {
    const translations = this.formGroup.get('translations') as FormArray;
    translations.removeAt(j);
    this.showTranslations = (translations.length > 0);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      number: '',
      date: '',
      note: '',
      language: '',
      translations: this.formBuilder.array([])
    });

    this.formGroup.get('number').valueChanges.subscribe((value) => {
      if (!this.biblioId && this.previousValue !== value) {
        this.previousValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, legalNumber: value });
      }
    });
  }

  get TranslationsValue(): any {
    const values: Translation[] = [];
    this.formGroup.value.translations.forEach(tr => {
      if (tr.tr && tr.tr !== '' && tr.tr.trim().length > 0) {
        values.push(<Translation>{
          type: tr.type,
          language: tr.language,
          org: this.formGroup.value.note,
          tr: tr.tr
        });
      }
    });
    return values;
  }

}
