<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.number.visible">
      <label>(31) {{headerText}}</label>
      <div class="value">{{item.number}}</div>
    </div>
    <div *ngIf="config.date.visible">
      <label>(32) {{headerText}}</label>
      <div class="value">{{item.date}}</div>
    </div>
    <div *ngIf="config.country.visible">
      <label>(33) {{headerText}}</label>
      <div class="value">{{item.country}}</div>
    </div>
  </div>
</div>