<div class="app-config-list container">
    <h4>Configurations</h4>
    <dx-toolbar>
        <dxi-item location="before">
            <dx-button icon="add" text="Add" (click)="add()"></dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button icon="download" text="Export" (click)="export()"></dx-button>
        </dxi-item>
        <!-- <dxi-item location="before">
            <dx-button icon="download" text="Export" (click)="check()"></dx-button>
        </dxi-item> -->
    </dx-toolbar>
    <dx-data-grid [showBorders]="true" [showRowLines]="true" [dataSource]="configsDataSource" [hoverStateEnabled]="true" [allowColumnResizing]="true">
        <dxi-column dataField="countryCode"></dxi-column>
        <dxi-column dataField="subCode" sortOrder="asc"></dxi-column>
        <dxi-column dataField="st17Code"></dxi-column>
        <dxi-column dataField="legalEventCategoryId" caption="Legal Event Category">
            <dxo-lookup [dataSource]="categories" displayExpr="value" valueExpr="key">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="sectionHeaderEng"></dxi-column>
        <dxi-column dataField="editorEmail"></dxi-column>
        <!-- <dxi-column dataField="editorFullName"></dxi-column>
        <dxi-column dataField="lastUpdate"></dxi-column> -->
        <dxi-column caption="Actions" cellTemplate="actionTemplate">
        </dxi-column>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true"></dxo-remote-operations>
        <dxo-paging [pageSize]="12"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
        <dxo-filter-row [visible]="true" applyFilter="auto"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-state-storing [enabled]="true" type="localStorage" savingTimeout="500" storageKey="configGridState"></dxo-state-storing>

        <div *dxTemplate="let info of 'actionTemplate'">
            <button (click)="edit(info.data)" class="btn btn-link btn-success btn-sm">
                <span class="dx-icon-edit"></span>Edit
            </button>
            <button (click)="delete(info.data.id)" class="btn btn-link btn-success btn-sm" style="margin-left:10px;">
                <span class="dx-icon-remove"></span>Delete
            </button>
        </div>
    </dx-data-grid>
</div>

<app-config-download #configDownload></app-config-download>