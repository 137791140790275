<accordion-group [formGroup]="formGroup" [isOpen]="openToggle" *ngIf="config && config.visible(stable)" (paste)="paste($event)"
  [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
    {{headerText}}
    <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div formArrayName="formArray" class="well well-lg">
    <div *ngFor="let item of formArray.controls; let i=index" [formGroupName]="i" class="row">
      <div class="col-md-2" *ngIf="config && config.language.visible">
        <div class="form-group">
          <label class="app-biblio-title">Language</label>
          <input id="{{i}}-language" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="language">
          <div *ngIf="formArray.at(i).get('language').hasError('required') && formArray.at(i).get('language').touched"
            class="alert alert-danger">Required field.</div>
          <div *ngIf="formArray.at(i).get('language').hasError('pattern')" class="alert alert-danger">Pattern not
            matched</div>
        </div>
      </div>
      <div class="col-md-8" *ngIf="config && config.text.visible">
        <div class="form-group">
          <label class="app-biblio-title">Text</label>
          <textarea id="{{i}}-text" class="form-control form-input" type="text" formControlName="text"></textarea>
          <div *ngIf="formArray.at(i).get('text').hasError('required') && formArray.at(i).get('text').touched" class="alert alert-danger">Required
            field.</div>
        </div>
      </div>
      <div *ngIf="i>0" class="col-md-2">
        <div class="form-group align-text-bottom">
          <a (click)="deleteFormArray(i)" class="dx-icon-remove"></a>
        </div>
      </div>
      <div *ngIf="showTranslations && config && (config.translations.language.visible || config.translations.tr.visible)"
        formArrayName="translations" style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
        <div class="translation">Next Language
          <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addTranslation(item)"> Add
            Language</button>
        </div>
        <div *ngFor="let translation of translationsArray(i).controls; let j=index" [formGroupName]="j" class="row">
          <div class="col-md-2" *ngIf="config && config.translations.language.visible">
            <div class="form-group">
              <label class="app-biblio-title">Language</label>
              <input id="{{i}}-translations-{{j}}-language" class="form-control form-input" type="text" formControlName="language">
              <div *ngIf="formArray.at(i).get('translations').at(j).get('language').hasError('required') && formArray.at(i).get('translations').at(j).get('language').touched"
                class="alert alert-danger">Required field.</div>
              <div *ngIf="formArray.at(i).get('translations').at(j).get('language').hasError('pattern')" class="alert alert-danger">Pattern
                not matched</div>
            </div>
          </div>
          <div class="col-md-8" *ngIf="config && config.translations.tr.visible">
            <div class="form-group">
              <label class="app-biblio-title">Text</label>
              <textarea id="{{i}}-translations-{{j}}-tr" class="form-control form-input" type="text" formControlName="tr"></textarea>
              <div *ngIf="formArray.at(i).get('translations').at(j).get('tr').hasError('required') && formArray.at(i).get('translations').at(j).get('tr').touched"
                class="alert alert-danger">Required field.</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group align-text-bottom">
              <a (click)="deleteTranslate(i, j)" class="dx-icon-remove"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button *ngIf="formArray.length < 1" class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addFormArray()"> Add</button>
      <button *ngIf="config && (config.translations.language.visible || config.translations.tr.visible)" class="dx-icon-add btn btn-outline-secondary btn-sm"
        (click)="addTranslations()"> Add Language</button>
    </div>
  </div>
</accordion-group>