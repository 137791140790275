<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.number.visible && item.number">
      <label>(6x) {{headerText}}Number</label>
      <div class="value">{{item.number}}</div>
    </div>
    <div *ngIf="config.type.visible && item.type">
      <label>(6x) {{headerText}}Kind</label>
      <div class="value">{{item.type}}</div>
    </div>
    <div *ngIf="item.country && config.country.visible">
      <label>(6x) {{headerText}}Country</label>
      <div class="value">{{item.country}}</div>
    </div>
    <div *ngIf="config.date.visible && item.date">
      <label>(6x) {{headerText}}Date</label>
      <div class="value">{{item.date}}</div>
    </div>
    <div *ngIf="config.source.visible && item.source">
      <label>(6x) {{headerText}}INID Code</label>
      <div class="value">{{item.source}}</div>
    </div>
  </div>
</div>