<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data.licensor">
    <div *ngIf="config.licensor.name.visible && item.name">
      <label>License {{headerText}}Licensor Name</label>
      <div class="value">{{item.name}}</div>
    </div>
    <div *ngIf="config.licensor.country.visible && item.country">
      <label>License {{headerText}}Licensor Country</label>
      <div class="value">{{item.country}}</div>
    </div>
    <div *ngIf="config.licensor.language.visible && item.language">
      <label>License {{headerText}}Licensor Language</label>
      <div class="value">{{item.language}}</div>
    </div>
    <div *ngIf="config.licensor.address.visible && item.address">
      <label>License {{headerText}}Licensor Address</label>
      <div class="value">{{item.address}}</div>
    </div>

    <div *ngFor="let tr of item.translations">
      <div *ngIf="config.licensor.translations.language.visible && tr.language">
        <label>License {{headerText}}Licensor Language</label>
        <div class="value">{{tr.language}}</div>
      </div>
      <div *ngIf="config.licensor.translations.trName.visible && tr.trName">
        <label>License {{headerText}}Licensor Name</label>
        <div class="value">{{tr.trName}}</div>
      </div>
      <div *ngIf="config.licensor.translations.trAddress.visible && tr.trAddress">
        <label>License {{headerText}}Licensor Address</label>
        <div class="value">{{tr.trAddress}}</div>
      </div>
    </div>
  </div>

  <div *ngFor="let item of data.licensee">
    <div *ngIf="config.licensee.name.visible && item.name">
      <label>License {{headerText}}Licensee Name</label>
      <div class="value">{{item.name}}</div>
    </div>
    <div *ngIf="config.licensee.country.visible && item.country">
      <label>License {{headerText}}Licensee Country</label>
      <div class="value">{{item.country}}</div>
    </div>
    <div *ngIf="config.licensee.language.visible && item.language">
      <label>License {{headerText}}Licensee Language</label>
      <div class="value">{{item.language}}</div>
    </div>
    <div *ngIf="config.licensee.address.visible && item.address">
      <label>License {{headerText}}Licensee Address</label>
      <div class="value">{{item.address}}</div>
    </div>

    <div *ngFor="let tr of item.translations">
      <div *ngIf="config.licensee.translations.language.visible && tr.language">
        <label>License {{headerText}}Licensee Language</label>
        <div class="value">{{tr.language}}</div>
      </div>
      <div *ngIf="config.licensee.translations.trName.visible && tr.trName">
        <label>License {{headerText}}Licensee Name</label>
        <div class="value">{{tr.trName}}</div>
      </div>
      <div *ngIf="config.licensee.translations.trAddress.visible && tr.trAddress">
        <label>License {{headerText}}Licensee Address</label>
        <div class="value">{{tr.trAddress}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="config.number.visible"><label>License {{headerText}}Registration number</label>
    <div class="value">{{data.number}}</div>
  </div>
  <div *ngIf="config.period.visible"><label>License {{headerText}}Period of validity</label>
    <div class="value">{{data.period}}</div>
  </div>
</div>