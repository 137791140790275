import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import { StorageService } from '../../shared/services/storage.service';
import { PatentService } from '../patent.service';
import { ConfigDownloadComponent } from './config-download/config-download.component';

@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.scss']
})
export class ConfigListComponent implements OnInit {


  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('configDownload', { static: true }) configDownload: ConfigDownloadComponent;
  configsDataSource: any = {};
  categories: any[];
  constructor(private patentService: PatentService, private router: Router, private storage: StorageService) {
    this.configsDataSource.store = new CustomStore({
      load: async (loadOptions: any) => {
        let params = '?';
        params += 'skip=' + (loadOptions.skip || 0);
        params += '&take=' + (loadOptions.take || 12);
        params += loadOptions.filter ? '&filter=' + JSON.stringify(loadOptions.filter) : '';
        params += loadOptions.sort ? '&sort=' + JSON.stringify(loadOptions.sort) : '';
        params += '&requireTotalCount=true';
        return this.patentService.getConfigs(params).toPromise();

      }
    });
  }

  add() {
    this.router.navigate(['config']);
  }
  export() {
    this.configDownload.popupVisible = true;
  }
  edit(data: any) {
    this.router.navigate(['config', { id: data.id }]);
  }

  check(){
    this.patentService.configCheck({country: 'HK', subcode: '02'});
  }

  delete(key: number) {
    const _that = this;
    const dialog = confirm('Do you really want to delete this configuration? All data will be lost.', 'Confirm');
    dialog.then(dialogResult => {
      if (dialogResult) {
        _that.patentService.deleteConfiguration(key).subscribe(res => {
          _that.dataGrid.instance.getDataSource().reload();
        });
      }
    });

  }
  ngOnInit() {
    this.patentService.getCategories().subscribe(res => {
      this.categories = res;
    });
  }

}
