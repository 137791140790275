<div class="app-pdf container">
    <h4>PDFs</h4>
    <dx-toolbar>
        <dxi-item location="before">
            <dx-button icon="upload" text="Upload" (click)="upload()"></dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button icon="card" text="Assign" (click)="assign()"></dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button icon="download" text="Delivery Report" (click)="export()"></dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button icon="download" text="Data Export" (click)="data_export()"></dx-button>
        </dxi-item>
    </dx-toolbar>
    <dx-data-grid id="gridContainer" [showBorders]="true" [showRowLines]="true" [dataSource]="pdfsDataSource"
        [hoverStateEnabled]="true" [allowColumnResizing]="true">
        <dxi-column dataField="countryCode"></dxi-column>
        <dxi-column dataField="fileNmae" caption="File Name"></dxi-column>
        <dxi-column dataField="lastUpdateDate" dataType="date"></dxi-column>
        <dxi-column dataField="editorEmail"></dxi-column>
        <dxi-column dataField="editorFullName"></dxi-column>
        <dxi-column dataField="status"></dxi-column>
        <dxi-column caption="Actions" cellTemplate="actionTemplate">
        </dxi-column>
        <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="12"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
        <dxo-filter-row [visible]="true" applyFilter="auto"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-state-storing [enabled]="true" type="localStorage" savingTimeout="500" storageKey="pdfGridState">
        </dxo-state-storing>

        <div *dxTemplate="let info of 'actionTemplate'">
            <button *ngIf="show(info.data) " (click)="edit(info.data)" class="btn btn-link btn-success btn-sm">
                <span class="dx-icon-edit"></span>Edit
            </button>
            <button (click)="delete(info.data.id)" class="btn btn-link btn-success btn-sm" style="margin-left:10px;">
                <span class="dx-icon-remove"></span>Delete
            </button>
        </div>
    </dx-data-grid>
</div>

<app-delivery-report></app-delivery-report>
<app-data-export></app-data-export>