<accordion-group [formGroup]="formGroup" [isOpen]="openToggle" *ngIf="config &&  config.visible(stable)" (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
       {{headerText}}
      <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div formArrayName="formArray" class="well well-lg">
      <div *ngFor="let relat of formArray.controls; let i=index" [formArrayName]="i" class="row">
          <div class="col-md-3" *ngIf="config && config.number.visible">
              <div class="form-group">
                  <label class="app-biblio-title">Number</label>
                  <input id="{{i}}-number" class="form-control form-input" type="text" formControlName="number">
                  <div *ngIf="formArray.at(i).get('number').hasError('required') && formArray.at(i).get('number').touched" class="alert alert-danger">Required field.</div>
              </div>
          </div>
          <div class="col-md-1" *ngIf="config && config.type.visible">
              <div class="form-group">
                  <label class="app-biblio-title">Kind</label>
                  <input id="{{i}}-type" class="form-control form-input" type="text" formControlName="type">
                  <div *ngIf="formArray.at(i).get('type').hasError('required') && formArray.at(i).get('type').touched" class="alert alert-danger">Required field.</div>
              </div>
          </div>
          <div class="col-md-2" *ngIf="config && config.date.visible">
              <div class="form-group">
                  <label class="app-biblio-title">Date</label>
                  <input id="{{i}}-date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date">
                  <div *ngIf="formArray.at(i).get('date').hasError('required') && formArray.at(i).get('date').touched" class="alert alert-danger">Required field.</div>
                  <div *ngIf="formArray.at(i).get('date').hasError('error')" class="alert alert-danger">Date format not match</div>
              </div>
          </div>
          <div class="col-md-2" *ngIf="config && config.country && config.country.visible">
            <div class="form-group">
                <label class="app-biblio-title">Country</label>
                <input id="{{i}}-country" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="country">
                <div *ngIf="formArray.at(i).get('country').hasError('required') && formArray.at(i).get('country').touched" class="alert alert-danger">Required field.</div>
                <div *ngIf="formArray.at(i).get('country').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>
        </div>
          <div class="col-md-2" *ngIf="config && config.source.visible">
              <div class="form-group">
                  <label class="app-biblio-title">Inid Number</label>
                  <input id="{{i}}-source" class="form-control form-input" type="text" formControlName="source">
                  <div *ngIf="formArray.at(i).get('source').hasError('required') && formArray.at(i).get('source').touched" class="alert alert-danger">Required field.</div>
              </div>
          </div>
          <div class="col-md-2">
              <div class="form-group align-text-bottom">
                  <a (click)="deleteFormArray(i)" class="dx-icon-remove"></a>
              </div>
          </div>
      </div>
      <div class="row">
        <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addFormArray()"> Add</button>
    </div>
  </div>
</accordion-group>