import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EuropeanPatent } from '../../../shared/models/ibiblio.model';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-european-patents',
  templateUrl: './biblio-european-patents.component.html',
  styleUrls: ['./biblio-european-patents.component.scss']
})
export class BiblioEuropeanPatentsComponent extends BiblioFormArrayBaseComponent {
  private previousPubValue = '';
  private previousAppValue = '';
  @Input() biblioId: number;


  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  setFormArray(data: EuropeanPatent[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        spc92Number: item.spc92Number,
        spc92Date: item.spc92Date,
        spc92Country: item.spc92Country,
        number: item.number,
        date: item.date,
        country: item.country,
        expiredDate: item.expiredDate,
        patent: item.patent,
        appNumber: item.appNumber,
        appKind: item.appKind,
        appDate: item.appDate,
        appCountry: item.appCountry,
        pubNumber: item.pubNumber,
        pubKind: item.pubKind,
        pubDate: item.pubDate,
        pubCountry: item.pubCountry
      });

      itemG.get('pubNumber').valueChanges.subscribe((value) => {
        if (!this.biblioId && this.previousPubValue !== value) {
          this.previousPubValue = value;
          this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, epPubNumber: value });
        }
      });
  
      itemG.get('appNumber').valueChanges.subscribe((value) => {
        if (!this.biblioId && this.previousAppValue !== value) {
          this.previousAppValue = value;
          this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, epAppNumber: value });
        }
      });

      return itemG;
    });
    
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const itemG = this.formBuilder.group({
      spc92Number: '',
      spc92Date: '',
      spc92Country: this.config.spc92Country.default || '',
      number: '',
      date: '',
      country: this.config.country.default || '',
      expiredDate: '',
      patent: '',
      appNumber: '',
      appKind: '',
      appDate: '',
      appCountry: this.config.appCountry.default || '',
      pubNumber: '',
      pubKind: '',
      pubDate: '',
      pubCountry: this.config.pubCountry.default || ''
    });

    itemG.get('pubNumber').valueChanges.subscribe((value) => {
      if (!this.biblioId && this.previousPubValue !== value) {
        this.previousPubValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, epPubNumber: value });
      }
    });

    itemG.get('appNumber').valueChanges.subscribe((value) => {
      if (!this.biblioId && this.previousAppValue !== value) {
        this.previousAppValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, epAppNumber: value });
      }
    });

    this.formArray.push(itemG);
    this.setValidator(itemG, this.config, this.config.ConfigName);
  }
}
