
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecurityService } from './security.service';

@Injectable()
export class DataService {
  static patchValue(org: any, update: any): any {
    // const org = this as any;
    Object.keys(org).forEach(key => {
      const cvalue = update[key];
      if (cvalue === undefined || null === cvalue) {
        return org[key];
      }
      if (typeof (cvalue) !== 'object') {
        org[key] = cvalue;
      } else {
        this.patchValue(org[key], cvalue);
      }
    });

    return org;
  }
  constructor(private http: HttpClient, private securityService: SecurityService) { }

  get(url: string): Observable<any> {
    return this.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
      headerSub.subscribe(header => {
        this.http.get(url, { headers: header }).subscribe(data => {
          subscriber.next(data);
          subscriber.complete();
        });
      });
    }));
  }

  post(url: string, data: any): Observable<any> {
    return this.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
      headerSub.subscribe(header => {
        this.http.post(url, data, { headers: header }).subscribe(data => {
          subscriber.next(data);
        });
      });
    }), catchError((err) => this.handleError(err)));
  }

  patch(url: string, data: any): Observable<any> {
    return this.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
      headerSub.subscribe(header => {
        this.http.patch(url, data, { headers: header }).subscribe(data => {
          subscriber.next(data);
        });
      });
    }), catchError((err) => this.handleError(err)));
  }

  delete(url: string): Observable<any> {
    console.log('data.service deleting');
    return this.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
      headerSub.subscribe(header => {
        this.http.delete(url, { headers: header }).subscribe(data => {
          subscriber.next(data);
        });
      });
    }), catchError((err) => this.handleError(err)));
  }


  downloadExcel(url: string, filename: string) {
    return this.securityService.getDiamondReportHeaders().pipe(headersub => new Observable<boolean>(subscriber => {
      headersub.subscribe(header => {
        this.http.get(url, {headers: header, responseType: 'blob' }).subscribe(
          (d) => {
            var durl = window.URL.createObjectURL(d);
            var link = document.createElement('a');
            link.style.display = 'none';
            link.href = durl;
            link.setAttribute('download', `${filename}.xlsx`);
            document.body.appendChild(link);
            link.click();
            subscriber.next(true);
          });
      })
    }), catchError((err) => this.handleError(err)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      alert(error.error.message, 'ERROR');
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      alert(error.message, 'ERROR');
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  private getHeaders(): Observable<HttpHeaders> {
    return this.securityService.GetToken().pipe(tokensub => new Observable<HttpHeaders>(subscriber => {
      tokensub.subscribe(token => {
        subscriber.next(new HttpHeaders({
          'Authorization': token === null ? '' : 'Bearer ' + token,
        }));
      })
    }));
  }
}
