<div class="app-account-manager container">
  <h4>Account Managements</h4>
  <dx-toolbar>
      <dxi-item location="before">
          <dx-button icon="edit" text="Edit" (click)="edit()"></dx-button>
      </dxi-item>
  </dx-toolbar>
  <dx-data-grid [showBorders]="true" [showRowLines]="true" [dataSource]="accountManagersDataSource" [hoverStateEnabled]="true">
      <dxi-column dataField="sub"></dxi-column>
      <dxi-column dataField="name"></dxi-column>
      <dxi-column dataField="email"></dxi-column>
      <dxi-column dataField="rolesString" caption="Roles" [allowFiltering]="false"></dxi-column>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true"></dxo-remote-operations>
      <dxo-paging [pageSize]="12"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
      <dxo-filter-row [visible]="true" applyFilter="auto"></dxo-filter-row>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-state-storing [enabled]="true" type="localStorage" savingTimeout="500" storageKey="accountmanagersGridState"></dxo-state-storing>
  </dx-data-grid>
</div>

<app-account-edit (refresh)=refresh($event)></app-account-edit>