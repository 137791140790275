import { Input, OnChanges, OnInit, SimpleChanges, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { Translation } from '../../shared/models/ibiblio.model';
import { BiblioBaseComponent } from './biblio-base.component';

@Directive()
export class BiblioFormArrayBaseComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  showTranslations: boolean;
  @Input() nameType: string;

  constructor(protected formBuilder: FormBuilder) {
    super();
    this.formGroup = this.formBuilder.group({
      formArray: this.formBuilder.array([])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue && changes['data'].currentValue != changes['data'].previousValue) {
      this.showTranslations = false;
      this.setFormArray(this.data);
      this.formArray.controls.forEach(element => {
        this.setValidator(element, this.config, this.config.ConfigName);
      });
    } else if (changes['config'] && !changes['config'].firstChange && this.formArray.controls) {
      this.formArray.controls.forEach(element => {
        const transGroup = element.get('translations') as FormArray;
        if (transGroup && transGroup.length < 1 && this.config['translations'].required()) {
          this.addTranslation(element);
          this.showTranslations = true;
        }
        this.setValidator(element, this.config, this.config.ConfigName);
      });
    }
  }

  ngOnInit() {

  }

  setFormArray(data: any[]) {
    const itemFGs = data.map((item, index) => this.formBuilder.group({}));
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  get formArray(): FormArray {
    return this.formGroup.get('formArray') as FormArray;
  }

  addFormArray() {
    const control = this.formBuilder.group({});
    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }

  deleteFormArray(i: number) {
    this.formArray.removeAt(i);
  }

  get Value(): any {
    return this.formArray.value.map(item => this.formatObject(item));
  }

  get TranslationsValue(): any {
    const values: Translation[] = [];
    this.formArray.value.forEach(item => {
      item.translations.forEach(tr => {
        if (tr.tr && tr.tr != '' && tr.tr.trim().length > 0) {
          values.push(<Translation>{
            type: tr.type,
            language: tr.language,
            org: item.text,
            tr: tr.tr
          });
        }
      });
    });
    return values;
  }

  addTranslation(item: AbstractControl) {
    return null;
  }

  addTranslations() {
    this.formArray.controls.forEach((item) => this.addTranslation(item))
    this.showTranslations = true
  }

  translationsArray(i: number): FormArray {
    return this.formArray.at(i).get('translations') as FormArray;
  }

  deleteTranslate(i: number, j: number) {
    (this.formArray.at(i).get('translations') as FormArray).removeAt(j);
    const li = this.formArray.controls.filter(item => (item.get('translations') as FormArray).length > 0)

    if (li && li.length > 0) {
      this.showTranslations = true;
    }
    else {
      this.showTranslations = false;
    }
  }
}
