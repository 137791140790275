import { Component } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Claim, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-claims',
  templateUrl: './biblio-claims.component.html',
  styleUrls: ['./biblio-claims.component.scss']
})
export class BiblioClaimsComponent extends BiblioFormArrayBaseComponent {
  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super(formBuilder);
  }
  setFormArray(data: Claim[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        language: item.language,
        number: item.number,
        text: item.text,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      item.screenShots = item.screenShots ? item.screenShots: [];
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    var claimnumber = 1
    const cureentitems = this.formArray.length;
    if (cureentitems > 0) {
      claimnumber = Number.parseInt((this.formArray.at(cureentitems - 1) as FormControl).get("number").value) + 1;
    }
    const control = this.formBuilder.group({
      language: this.config.language.default || '',
      number: claimnumber,
      text: '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }

  setTranslations(itemG: FormGroup, data: Translation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required()) {
      data = [<Translation>{ type: this.nameType, language: this.config.translations.language.default }];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray;
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      tr: '',
    });

    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }

  deleteimg(uid: string) {
    this.formArray.controls.forEach((element, i) => {
      const value = element.get('text').value;
      element.get('text').setValue(value.replace('<img id="' + uid + '">', ''));
      const translations = element.get('translations') as FormArray;
      translations.controls.forEach(trelement => {
        const trvalue = trelement.get("tr").value;
        trelement.get('tr').setValue(trvalue.replace('<img id="' + uid + '">', ''));
      });
      const idx = (this.data[i] as Claim).screenShots.findIndex(e => e.id === uid);
      if (idx >= 0) {
        (this.data[i] as Claim).screenShots.splice(idx, 1);
      }
    });
  }

  private uuid() {
    return 'yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  paste(e: ClipboardEvent) {
    const _that = this;
    let clipboardData: DataTransfer, found;
    found = false;
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    clipboardData = e.clipboardData;

    return Array.prototype.forEach.call(clipboardData.types, function (type, i) {
      let file, reader;

      if (type.match(/text\/plain/) && target.id) {
        _that.setClipboardData(e.clipboardData.getData('text/plain'), target);
        e.preventDefault();
      }
      if (type.match(/image.*/) || clipboardData.items[i].type.match(/image.*/)) {
        file = clipboardData.items[i].getAsFile();
        reader = new FileReader();
        reader.onload = (evt) => {
          const uid = _that.uuid();
          const ids = target.id.split('-');
          (_that.Value[ids[0]] as Claim).screenShots.push({ id: uid, data: evt.target.result })
          const img = '<img id="' + uid + '">';
          _that.setClipboardData(img, target);
        };
        reader.readAsDataURL(file);
        e.preventDefault();
      }
    });
  }

  get Value(): any {
    this.data = (this.formArray.value as any[]).map((item, i) => {
      const value = this.formatObject(item);
      const shots = this.data && this.data[i] ? (this.data[i] as Claim).screenShots : [];
      Object.assign(value, { screenShots: shots });
      return value;
    }, this);
    return this.data;
  }

  ScreenShot(i: number) {
    return this.data && this.data[i] ? this.data[i].screenShots : null;
  }
}
