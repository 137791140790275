import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LegalEvent } from '../../shared/models/ibiblio.model';
import { Patent } from '../../shared/models/patent';
import { SearchCondition } from '../../shared/models/search-condition';
import { StorageService } from '../../shared/services/storage.service';
import { EventViewComponent } from '../event-view/event-view.component';
import { PatentService } from '../patent.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  condition = new SearchCondition;
  data: any[];
  @ViewChild(EventViewComponent, { static: true }) eventView: EventViewComponent;
  constructor(private service: PatentService, private storage: StorageService, private router: Router) { }

  ngOnInit() {
    if (this.storage.retrieve('searchCondition') && this.storage.retrieve('searchCondition') !== '') {
      this.condition = JSON.parse(this.storage.retrieve('searchCondition'));
    }

    if (this.storage.retrieve('searchResult') && this.storage.retrieve('searchResult') !== '') {
      this.data = JSON.parse(this.storage.retrieve('searchResult'));
    }
  }

  search($event: any) {
    const result = $event.validationGroup.validate();
    if (result.isValid) {
      this.storage.store('searchCondition', JSON.stringify(this.condition));
      this.service.search({ country: this.condition.country || '', number: this.condition.number })
        .subscribe(d => {
          this.data = [];
          d.forEach(item => {
            const biblio = JSON.parse(item.biblio);
            const legal: LegalEvent = item.legalEvent ? JSON.parse(item.legalEvent) : null;
            const european = biblio.europeanPatents;
            this.data.push({
              id: item.id,
              countryCode: item.countryCode,
              fileName: item.fileName,
              subCode: item.subCode,
              sectionHeaderEng: item.sectionHeaderEng,
              sectionCode: item.sectionCode,
              lastUpdate: item.lastUpdate,
              pubNumber: biblio.publication.number,
              appNumber: biblio.application.number,
              epPubNumber: european ? (Array.isArray(european) ? european.filter(x => x.pubNumber || false).map(x => x.pubNumber).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.pubNumber) : null,
              epAppNumber: european ? (Array.isArray(european) ? european.filter(x => x.appNumber || false).map(x => x.appNumber).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.appNumber) : null,
              legalNumber: legal ? legal.number : null,
              pdfId: item.pdfId
            });

            this.storage.store('searchResult', JSON.stringify(this.data));
          });
        });
    }
  }

  showView(id: number) {
    this.service.getPatentAndConfig(id).subscribe((data: any) => {
      const patent: Patent = { subCode: data.patent.subCode, biblio: JSON.parse(data.patent.biblio), newBiblio: JSON.parse(data.patent.newBiblio), legalEvent: JSON.parse(data.patent.legalEvent), pdf: data.patent.pdf };
      const config = JSON.parse(data.config.config);
      this.eventView.show(patent, config);
    });
  }

  onClick(item: any) {
    this.router.navigate(['patent', { pdfId: item.pdfId, biblioId: item.id }]);
    // alert(111);
  }
}
