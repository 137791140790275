<div class="app-patentlist popup">
    <dx-popup container=".popup" [width]="800" [height]="600" [showTitle]="true" titleTemplate="title"
        [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">
        <div *dxTemplate="let data of 'title'">
            <div (click)="showAllGroup()">Processed events list ({{patents? patents.length: 0}})</div>
            <button (click)="close()" class="btn btn-link btn-lg dx-icon-remove"
                style="float:right; padding:0em 2em; "></button>
        </div>
        <div *dxTemplate="let data of 'content'">
            <div>
                <dx-text-box mode="search" valueChangeEvent="keyup" (onValueChanged)="searchChange($event)">
                </dx-text-box>
            </div>
            <dx-list #list [dataSource]="patentsDatasource" [grouped]="true" [activeStateEnabled]="false"
                [hoverStateEnabled]="false" [focusStateEnabled]="false" selectionMode="none" [collapsibleGroups]="true"
                (onGroupRendered)="collapse($event)">
                <div style=" height: 100% " *dxTemplate="let groupObj of 'group'; let i=index"
                    (click)="changeGroupIndex(i, groupObj.key)">
                    <p style=" height: 100% " [ngClass]="{'Completed': groupObj.key.indexOf('Completed') !== -1}">
                        {{groupObj.key}} ({{groupObj.items.length}})</p>
                </div>
                <div *dxTemplate="let patent of 'item'; let i=index;">
                    <button class="btn btn-link btn-sm" (click)="loadBiblio(patent.id)">ID {{patent.id }} Keys{{
                        patent.pubNumber ? " Pub: " + patent.pubNumber: "" }}{{ patent.appNumber ? " App: " +
                        patent.appNumber: "" }}{{ patent.legalNumber ? " Legal: " + patent.legalNumber: "" }} {{
                        patent.epPubNumber ? " EP_Pub: " + patent.epPubNumber: "" }} {{ patent.epAppNumber ? " EP_App: "
                        +
                        patent.epAppNumber: "" }}</button>
                    <button (click)="showView(patent.id)" class="btn btn-sm"
                        style="float:right; margin: 0 0 0 1em; ">...</button>
                    <button (click)="deletePatent(patent, i)" class="btn btn-sm"
                        style="float:right; margin: 0 0 0 1em; ">X</button>
                </div>
            </dx-list>
        </div>
    </dx-popup>

    <app-event-view position="center"></app-event-view>
</div>