import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NonPatentCitation, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-non-patent-citations',
  templateUrl: './biblio-non-patent-citations.component.html',
  styleUrls: ['./biblio-non-patent-citations.component.scss']
})
export class BiblioNonPatentCitationsComponent extends BiblioFormArrayBaseComponent {
  constructor(protected formBuilder: FormBuilder, protected patentService: PatentService) {
    super(formBuilder);
  }
  setFormArray(data: NonPatentCitation[]) {
    const itemFGs = data.map((item, index) => {
      const itemG =this.formBuilder.group({
        text: item.text,
        language: item.language,
        xpNumber: item.xpNumber,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      text: '',
      xpNumber: '',
      language: '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }
    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }


  setTranslations(itemG: FormGroup, data: Translation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required())
    {
      data = [<Translation>{type: this.nameType, language: this.config.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      tr: '',
    });

    item.get('text').valueChanges.subscribe(value => this.getTranslate(translate.get('tr'), translate.get('language').value, value))
    this.getTranslate(translate.get('tr'), translate.get('language').value, item.get('text').value)
    translate.get('language').valueChanges.subscribe(value => this.getTranslate(translate.get('tr'), value, item.get('text').value))
    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }

  get Value(): any {
    return this.formArray.value.map((item, i) => Object.assign(this.formatObject(item), { sequence: i + 1 }));
  }
}
