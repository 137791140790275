<accordion-group [isOpen]="openToggle" *ngIf="config && config.legalEvent.visible(stable) " (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
    <div accordion-heading>
        {{headerText}}
        <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
    </div>
    <div class="row">
        <button *ngIf="config && (config.legalEvent.translations.language.visible || config.legalEvent.translations.tr.visible)" class="dx-icon-add btn btn-outline-secondary btn-sm"
            (click)="addTranslation()">Add Language</button>

    </div>
    <div [formGroup]="formGroup" class="row">
        <div class="col-md-3" *ngIf="config && config.legalEvent.number.visible">
            <div class="form-group">
                <label class="app-biblio-title">Patent Number</label>
                <input id="number" class="form-control form-input" type="text" formControlName="number" placeholder="{{config.legalEvent.number.pattern}}">
                <div *ngIf="formGroup.get('number').hasError('required')  && formGroup.get('number').touched" class="alert alert-danger">Required field.</div>
                <div *ngIf="formGroup.get('number').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>

        </div>
        <div class="col-md-3" *ngIf="config && config.legalEvent.date.visible">
            <div class="form-group">
                <label class="app-biblio-title">Event Date</label>
                <input id="date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                    formControlName="date">
                <div *ngIf="formGroup.get('date').hasError('required')  && formGroup.get('date').touched" class="alert alert-danger">Required
                    field.</div>
                <div *ngIf="formGroup.get('date').hasError('error')" class="alert alert-danger">Pattern not matched</div>
            </div>
        </div>
        <div class="col-md-2" *ngIf="config && config.legalEvent.language.visible">
            <div class="form-group">
                <label class="app-biblio-title">Language</label>
                <input id="language" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                    formControlName="language">
                <div *ngIf="formGroup.get('language').hasError('required')  && formGroup.get('language').touched" class="alert alert-danger">Required
                    field.</div>
                <div *ngIf="formGroup.get('language').hasError('pattern')" class="alert alert-danger">Pattern not
                    matched</div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="config && config.legalEvent.note.visible">
            <div class="form-group">
                <label class="app-biblio-title">Note</label>
                <textarea id="note" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="note"></textarea>
                <div *ngIf="formGroup.get('note').hasError('required')  && formGroup.get('note').touched" class="alert alert-danger">Required
                    field.</div>
                <div *ngIf="formGroup.get('note').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="config && config.legalEvent.note.visible && config.instructions">
            <div class="form-group">
                <label class="app-biblio-title">Comments</label>
                <textarea class="form-control" disabled="disabled" type="text">{{config.instructions}}</textarea>
            </div>
        </div>
        <div *ngIf="showTranslations && config && (config.legalEvent.translations.language.visible || config.legalEvent.translations.tr.visible)"
            formArrayName="translations" style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
            <div class="translation">
                Next Language
            </div>
            <div *ngFor="let translation of translations.controls; let j=index" [formGroupName]="j" class="row">
                <div class="col-md-2" *ngIf="config && config.legalEvent.translations.language.visible">
                    <div class="form-group">
                        <label class="app-biblio-title">Language</label>
                        <input id="translations-{{j}}-language" class="form-control form-input" type="text"
                            formControlName="language">
                        <div *ngIf="translations.at(j).get('language').hasError('required') && translations.at(j).get('language').touched"
                            class="alert alert-danger">Required field.</div>
                        <div *ngIf="translations.at(j).get('language').hasError('pattern')" class="alert alert-danger">Pattern
                            not matched</div>
                    </div>
                </div>
                <div class="col-md-8" *ngIf="config && config.legalEvent.translations.tr.visible">
                    <div class="form-group">
                        <label class="app-biblio-title">Note</label>
                        <textarea id="translations-{{j}}-tr" class="form-control form-input" type="text"
                            formControlName="tr"></textarea>
                        <div *ngIf="translations.at(j).get('tr').hasError('required') && translations.at(j).get('tr').touched"
                            class="alert alert-danger">Required field.</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group align-text-bottom">
                        <a (click)="deleteTranslate(j)" class="dx-icon-remove"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</accordion-group>