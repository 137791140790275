import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LicenseMember, LicenseTranslation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-license-member',
  templateUrl: './biblio-license-member.component.html',
  styleUrls: ['./biblio-license-member.component.scss']
})
export class BiblioLicenseMemberComponent extends BiblioFormArrayBaseComponent {
 @Input() configname: string;
  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  setFormArray(data: LicenseMember[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        language: item.language,
        name: item.name,
        address: item.address,
        country: item.country,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      language: this.config.language.default || '',
      name: '',
      address: '',
      country: this.config.country.default || '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.configname);
  }

  setTranslations(itemG: FormGroup, data: LicenseTranslation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required())
    {
      data = [<LicenseTranslation>{type: this.nameType, language: this.config.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        trName: item.trName,
        trAddress: item.trAddress,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      trName: '',
      trAddress: '',
    });

    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }
}
