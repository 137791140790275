import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-config-item',
  templateUrl: './config-item.component.html',
  styleUrls: ['./config-item.component.scss']
})
export class ConfigItemComponent implements OnInit {
  @Input() configuration: FormGroup;


  ngOnInit() {
  }

  get existDefault(): boolean {
    return this.configuration.contains('default');
  }


  get existPattern(): boolean {
    return this.configuration.contains('pattern');
  }

  get existOriginal(): boolean {
    return this.configuration.contains('original');
  }
}
