<accordion-group [isOpen]="openToggle" *ngIf="config && config.visible(stable)" (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
      (10) Publication Information {{!stable ? '(New)': ''}}
      <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div [formGroup]="formGroup" class="row">
      <div class="col-md-4" *ngIf="config && config.number.visible">
          <div class="form-group">
              <label class="app-biblio-title">(11) Publ. Number</label>
              <input id="number" class="form-control form-input" type="text" formControlName="number" placeholder="{{config.number.pattern}}">
              <div *ngIf="formGroup.get('number').hasError('required')  && formGroup.get('number').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('number').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>
      </div>
      <div class="col-md-4" *ngIf="config && config.languageDesignation.visible">
        <div class="form-group">
            <label class="app-biblio-title">(12) Plain language designation of the kind of document</label>
            <input id="languageDesignation" class="form-control form-input" type="text" formControlName="languageDesignation" placeholder="{{config.languageDesignation.pattern}}">
            <div *ngIf="formGroup.get('languageDesignation').hasError('required')  && formGroup.get('languageDesignation').touched" class="alert alert-danger">Required field.</div>
            <div *ngIf="formGroup.get('languageDesignation').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
        </div>
    </div>
      <div class="col-md-2" *ngIf="config && config.kind.visible">
          <div class="form-group">
              <label class="app-biblio-title">(13) Publ. Kind</label>
              <input id="kind" class="form-control form-input" type="text" placeholder="[A-Z][0-9]?" formControlName="kind">
              <div *ngIf="formGroup.get('kind').hasError('required')  && formGroup.get('kind').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('kind').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
          </div>
      </div>

      <div class="col-md-2" *ngIf="config && config.authority.visible">
          <div class="form-group">
              <label class="app-biblio-title">(26) Authority</label>
              <input id="authority" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="authority">
              <div *ngIf="formGroup.get('authority').hasError('required')  && formGroup.get('authority').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('authority').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
          </div>
      </div>
  </div>
</accordion-group>