import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
// tslint:disable-next-line:max-line-length
import { DxBoxModule, DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxFileUploaderModule, DxFormModule, DxListModule, DxLoadPanelModule, DxLookupModule, DxNumberBoxModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule, DxTileViewModule, DxToolbarModule } from 'devextreme-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AccountModule } from '../../account/account.module';
import { SafePipePipe } from '../shared/safe-pipe.pipe';
import { BiblioAbstractsComponent } from './biblio/biblio-abstracts/biblio-abstracts.component';
import { BiblioApplicationComponent } from './biblio/biblio-application/biblio-application.component';
import { BiblioDOfPublicationComponent } from './biblio/biblio-d-of-publication/biblio-d-of-publication.component';
import { BiblioEuropeanPatentsComponent } from './biblio/biblio-european-patents/biblio-european-patents.component';
import { BiblioIntConventionComponent } from './biblio/biblio-int-convention/biblio-int-convention.component';
import { BiblioIpcsComponent } from './biblio/biblio-ipcs/biblio-ipcs.component';
import { BiblioLegalEventComponent } from './biblio/biblio-legal-event/biblio-legal-event.component';
import { BiblioLicenseMemberComponent } from './biblio/biblio-license-member/biblio-license-member.component';
import { BiblioLicenseComponent } from './biblio/biblio-license/biblio-license.component';
import { BiblioNonPatentCitationsComponent } from './biblio/biblio-non-patent-citations/biblio-non-patent-citations.component';
import { BiblioPartiesComponent } from './biblio/biblio-parties/biblio-parties.component';
import { BiblioPatentCitationsComponent } from './biblio/biblio-patent-citations/biblio-patent-citations.component';
import { BiblioPrioritiesComponent } from './biblio/biblio-priorities/biblio-priorities.component';
import { BiblioPublicationComponent } from './biblio/biblio-publication/biblio-publication.component';
import { BiblioRelatedComponent } from './biblio/biblio-related/biblio-related.component';
import { BiblioSpcComponent } from './biblio/biblio-spc/biblio-spc.component';
import { BiblioTitlesComponent } from './biblio/biblio-titles/biblio-titles.component';
import { BiblioComponent } from './biblio/biblio.component';
import { ExistlistComponent } from './biblio/existlist/existlist.component';
import { PatentlistComponent } from './biblio/patentlist/patentlist.component';
import { ConfigDownloadComponent } from './config-list/config-download/config-download.component';
import { ConfigListComponent } from './config-list/config-list.component';
import { ConfigItemComponent } from './config/config-item/config-item.component';
import { ConfigComponent } from './config/config.component';
import { EventValidateComponent } from './event-validate/event-validate.component';
import { EventViewComponent } from './event-view/event-view.component';
import { ViewApplicationComponent } from './event-view/view-application/view-application.component';
import { ViewDOfPublicationComponent } from './event-view/view-d-of-publication/view-d-of-publication.component';
import { ViewEuropeanPatentsComponent } from './event-view/view-european-patents/view-european-patents.component';
import { ViewIntConventionComponent } from './event-view/view-int-convention/view-int-convention.component';
import { ViewIpcsComponent } from './event-view/view-ipcs/view-ipcs.component';
import { ViewLegalEventComponent } from './event-view/view-legal-event/view-legal-event.component';
import { ViewLicenseComponent } from './event-view/view-license/view-license.component';
import { ViewNonPatentCitationsComponent } from './event-view/view-non-patent-citations/view-non-patent-citations.component';
import { ViewPartiesComponent } from './event-view/view-parties/view-parties.component';
import { ViewPatentCitationsComponent } from './event-view/view-patent-citations/view-patent-citations.component';
import { ViewPrioritiesComponent } from './event-view/view-priorities/view-priorities.component';
import { ViewPublicationComponent } from './event-view/view-publication/view-publication.component';
import { ViewRelatedComponent } from './event-view/view-related/view-related.component';
import { ViewTitlesOrAbstractsComponent } from './event-view/view-titles-or-abstracts/view-titles-or-abstracts.component';
import { PatentComponent } from './patent.component';
import { PatentService } from './patent.service';
import { PdfUploadComponent } from './pdf-upload/pdf-upload.component';
import { DeliveryReportComponent } from './pdf/delivery-report/delivery-report.component';
import { PdfComponent } from './pdf/pdf.component';
import { SearchComponent } from './search/search.component';
import { SubcodeChangeComponent } from './subcode-change/subcode-change.component';
import { BiblioClaimsComponent } from './biblio/biblio-claims/biblio-claims.component';
import { BiblioPledgeComponent } from './biblio/biblio-pledge/biblio-pledge.component';
import { ViewPledgeComponent } from './event-view/view-pledge/view-pledge.component';
import { DataExportComponent } from './pdf/data-export/data-export.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxBoxModule,
    DxListModule,
    DxDataGridModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxFormModule,
    DxTextBoxModule,
    DxTileViewModule,
    DxToolbarModule,
    DxFileUploaderModule,
    DxScrollViewModule,
    DxLookupModule,
    DxPopupModule,
    AccordionModule.forRoot(),
    ButtonsModule.forRoot(),
    ReactiveFormsModule,
    FileUploadModule,
    AccountModule
  ],
  declarations: [PatentComponent, BiblioComponent,
    ConfigComponent, PdfComponent, PdfUploadComponent,
    ConfigListComponent,
    SafePipePipe,
    PatentlistComponent,
    SubcodeChangeComponent,
    BiblioPublicationComponent,
    BiblioApplicationComponent,
    BiblioPrioritiesComponent,
    BiblioIpcsComponent,
    BiblioPartiesComponent,
    BiblioTitlesComponent,
    BiblioAbstractsComponent,
    BiblioIntConventionComponent,
    BiblioSpcComponent,
    BiblioEuropeanPatentsComponent,
    BiblioPatentCitationsComponent,
    BiblioNonPatentCitationsComponent,
    BiblioRelatedComponent,
    BiblioLegalEventComponent,
    BiblioDOfPublicationComponent,
    SearchComponent,
    ExistlistComponent,
    ConfigItemComponent,
    EventViewComponent,
    ViewPartiesComponent,
    ViewTitlesOrAbstractsComponent,
    ViewDOfPublicationComponent,
    ViewIpcsComponent,
    ViewPrioritiesComponent,
    ViewApplicationComponent,
    ViewPublicationComponent,
    ViewIntConventionComponent,
    ViewEuropeanPatentsComponent,
    ViewPatentCitationsComponent,
    ViewNonPatentCitationsComponent,
    ViewRelatedComponent,
    ViewLegalEventComponent,
    ConfigDownloadComponent,
    BiblioLicenseMemberComponent,
    BiblioLicenseComponent,
    ViewLicenseComponent,
    DeliveryReportComponent,
    EventValidateComponent,
    BiblioClaimsComponent,
    BiblioPledgeComponent,
    ViewPledgeComponent,
    DataExportComponent],
  providers: [PatentService]
})
export class PatentModule { }
