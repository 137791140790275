<div class="app-account-edit">
    <dx-popup container=".app-account-edit" [width]="650" [height]="300" [showTitle]="true" title="Account Management"
        [dragEnabled]="false" [closeOnOutsideClick]="true" [visible]="visible" (onHidden)="close($event)">
        <div *dxTemplate="let data of 'content'" class="popup-template">
            <dx-form [formData]="account" validationGroup="accountEditGroup">
                <dxi-item itemType="group" [colCount]=2>
                    <dxi-item dataField="name" [editorOptions]="{ disabled: true}">
                        <dxi-validation-rule type="required" message="Required filed"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="email" [editorOptions]="{ disabled: true }"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [colCount]=2>
                    <dxi-item dataField="View" editorType="dxCheckBox"></dxi-item>
                    <dxi-item dataField="Admin" editorType="dxCheckBox"></dxi-item>
                </dxi-item>
            </dx-form>
            <div class="popup-bottom-toolbar">
                <dx-button *ngIf="visible" class="btn btn-primary" icon="save" text="Save"
                    validationGroup="accountEditGroup" (onClick)="save($event)"></dx-button>
                <dx-button class="btn btn-secondary" icon="close" text="Close" (click)="close($event)"></dx-button>
            </div>
        </div>
    </dx-popup>
</div>