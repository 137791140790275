import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-non-patent-citations',
  templateUrl: './view-non-patent-citations.component.html',
  styleUrls: ['./view-non-patent-citations.component.scss']
})
export class ViewNonPatentCitationsComponent implements OnInit {
  @Input() data: any;
  @Input() config: any;
  @Input() stable: boolean;
  @Input() headerText: string;
  constructor() { }

  ngOnInit() {
  }

}
