<div class="app-event-view">
  <dx-popup #popup container=".app-event-view" [(position)]="position" [shading]="false"
    shadingColor="rgba(0, 0, 0, 0.2)" title="Legal Event Overview" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'" class="h-100">
      <dx-scroll-view height="100%" direction="both" *ngIf="event">
        <div class="view-group view-group_inline"><label>File Name:</label>
          <div>{{ event.pdf.fileNmae }}</div>
        </div>
        <div class="view-group view-group_inline"><label>Sub Code:</label>
          <div>{{event.subCode}}</div>
        </div>

        <app-view-legal-event *ngIf="event && event.biblio" headerText="Legal Status Events" [data]="event.legalEvent"
          [config]="config.legalEvent" [stable]="true"></app-view-legal-event>

        <app-view-publication *ngIf="event && event.biblio" headerText="" [data]="event.biblio.publication"
          [config]="config.publication" [stable]="true"></app-view-publication>
        <app-view-publication *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.publication: null" [config]="config.publication" [stable]="false">
        </app-view-publication>

        <app-view-application *ngIf="event && event.biblio" headerText="" [data]="event.biblio.application"
          [config]="config.application" [stable]="true"></app-view-application>
        <app-view-application *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.application: null" [config]="config.application" [stable]="false">
        </app-view-application>

        <app-view-priorities *ngIf="event && event.biblio" headerText="" [data]="event.biblio.priorities"
          [config]="config.priorities" [stable]="true"></app-view-priorities>
        <app-view-priorities *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.priorities: null" [config]="config.priorities" [stable]="false">
        </app-view-priorities>

        <app-view-d-of-publication *ngIf="event && event.biblio" headerText="" [data]="event.biblio" [config]="config"
          [stable]="true"></app-view-d-of-publication>
        <app-view-d-of-publication *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio : null" [config]="config" [stable]="false">
        </app-view-d-of-publication>

        <app-view-ipcs *ngIf="event && event.biblio" headerText="" [data]="event.biblio.ipcs" [config]="config.ipcs"
          [stable]="true"></app-view-ipcs>
        <app-view-ipcs *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.ipcs: null" [config]="config.ipcs" [stable]="false"></app-view-ipcs>

        <app-view-parties *ngIf="event && event.biblio" headerText="(71)" [data]="event.biblio.applicants"
          [config]="config.applicants" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(71) (New)"
          [data]="event.newBiblio ? event.newBiblio.applicants: null" [config]="config.applicants" [stable]="false">
        </app-view-parties>

        <app-view-parties *ngIf="event && event.biblio" headerText="(72)" [data]="event.biblio.inventors"
          [config]="config.inventors" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(72) (New)"
          [data]="event.newBiblio ? event.newBiblio.inventors: null" [config]="config.inventors" [stable]="false">
        </app-view-parties>

        <app-view-parties *ngIf="event && event.biblio" headerText="(73)" [data]="event.biblio.assignees"
          [config]="config.assignees" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(73) (New)"
          [data]="event.newBiblio ? event.newBiblio.assignees: null" [config]="config.assignees" [stable]="false">
        </app-view-parties>

        <app-view-parties *ngIf="event && event.biblio" headerText="(74)" [data]="event.biblio.agents"
          [config]="config.agents" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(74) (New)"
          [data]="event.newBiblio ? event.newBiblio.agents: null" [config]="config.agents" [stable]="false">
        </app-view-parties>

        <app-view-parties *ngIf="event && event.biblio" headerText="(75)" [data]="event.biblio.invOrApps"
          [config]="config.invOrApps" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(75) (New)"
          [data]="event.newBiblio ? event.newBiblio.invOrApps: null" [config]="config.invOrApps" [stable]="false">
        </app-view-parties>

        <app-view-parties *ngIf="event && event.biblio" headerText="(76)" [data]="event.biblio.invAppGrants"
          [config]="config.invAppGrants" [stable]="true"></app-view-parties>
        <app-view-parties *ngIf="event && event.newBiblio" headerText="(76) (New)"
          [data]="event.newBiblio ? event.newBiblio.invAppGrants: null" [config]="config.invAppGrants" [stable]="false">
        </app-view-parties>

        <app-view-int-convention *ngIf="event && event.biblio" headerText="" [data]="event.biblio.intConvention"
          [config]="config.intConvention" [stable]="true"></app-view-int-convention>
        <app-view-int-convention *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.intConvention: null" [config]="config.intConvention"
          [stable]="false"></app-view-int-convention>

        <app-view-european-patents *ngIf="event && event.biblio" headerText="" [data]="event.biblio.europeanPatents"
          [config]="config.europeanPatents" [stable]="true"></app-view-european-patents>
        <app-view-european-patents *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.europeanPatents: null" [config]="config.europeanPatents"
          [stable]="false"></app-view-european-patents>

        <app-view-patent-citations *ngIf="event && event.biblio" headerText="" [data]="event.biblio.patentCitations"
          [config]="config.patentCitations" [stable]="true"></app-view-patent-citations>
        <app-view-patent-citations *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.patentCitations: null" [config]="config.patentCitations"
          [stable]="false"></app-view-patent-citations>

        <app-view-non-patent-citations *ngIf="event && event.biblio" headerText=""
          [data]="event.biblio.nonPatentCitations" [config]="config.nonPatentCitations" [stable]="true">
        </app-view-non-patent-citations>
        <app-view-non-patent-citations *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.nonPatentCitations: null" [config]="config.nonPatentCitations"
          [stable]="false"></app-view-non-patent-citations>

        <app-view-related *ngIf="event && event.biblio" headerText="" [data]="event.biblio.related"
          [config]="config.related" [stable]="true"></app-view-related>
        <app-view-related *ngIf="event && event.newBiblio" headerText="(New)"
          [data]="event.newBiblio ? event.newBiblio.related: null" [config]="config.related" [stable]="false">
        </app-view-related>

        <app-view-titles-or-abstracts *ngIf="event && event.biblio" headerText="(54)" [data]="event.biblio.titles"
          [config]="config.titles" [stable]="true"></app-view-titles-or-abstracts>
        <app-view-titles-or-abstracts *ngIf="event && event.newBiblio" headerText="(54) (New)"
          [data]="event.newBiblio ? event.newBiblio.titles: null" [config]="config.titles" [stable]="false">
        </app-view-titles-or-abstracts>

        <app-view-titles-or-abstracts *ngIf="event && event.biblio" headerText="(57)" [data]="event.biblio.abstracts"
          [config]="config.abstracts" [stable]="true"></app-view-titles-or-abstracts>
        <app-view-titles-or-abstracts *ngIf="event && event.newBiblio" headerText="(57) (New)"
          [data]="event.newBiblio ? event.newBiblio.abstracts: null" [config]="config.abstracts" [stable]="false">
        </app-view-titles-or-abstracts>

        <app-view-license *ngIf="event && event.biblio" headerText="" [data]="event.biblio.license"
          [config]="config.license" [stable]="true"></app-view-license>
        <app-view-license *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.license: null" [config]="config.license" [stable]="false">
        </app-view-license>

        <app-view-pledge *ngIf="event && event.biblio" headerText="" [data]="event.biblio.pledge"
          [config]="config.pledge" [stable]="true"></app-view-pledge>
        <app-view-pledge *ngIf="event && event.newBiblio" headerText="(New) "
          [data]="event.newBiblio ? event.newBiblio.pledge: null" [config]="config.pledge" [stable]="false">
        </app-view-pledge>

      </dx-scroll-view>
    </div>
  </dx-popup>
</div>