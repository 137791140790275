<!-- <nav class="navbar navbar-expand bg-light align-items-end">
    
</nav> -->
<div class="container-fluid" style="margin-bottom: 20px;">
    <article class="row container-fluid">
        <section class="col-2">
            <a class="navbar-brand" routerLink="">
                Lens Keying System
            </a>
        </section>
        <section class="col-10">
            <app-identity></app-identity>
        </section>
    </article>
</div>
<router-outlet *ngIf="Authenticated"></router-outlet>