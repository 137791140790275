<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngIf="config.number.visible">
    <label>(11) {{headerText}}</label>
    <div class="value">{{data.number}}</div>
  </div>
  <div *ngIf="config.kind.visible">
    <label>(13) {{headerText}}</label>
    <div class="value">{{data.kind}}</div>
  </div>
  <div *ngIf="config.authority.visible">
      <label>(26) {{headerText}}</label>
      <div class="value">{{data.authority}}</div>
    </div>
</div>