export class AccountManager {
    public sub: string;
    public email: string;
    public name: string;
    public roles: number;

    get View(): boolean {
        return ((this.roles & 1) === 1)
    }
    set View(value: boolean) {
        if (value) {
            this.roles = this.roles | 1;
        } else {
            this.roles = this.roles ^ 1;
        }
    }

    get Admin(): boolean {
        return ((this.roles & 2) === 2)
    }
    set Admin(value: boolean) {
        if (value) {
            this.roles = this.roles | 2;
        } else {
            this.roles = this.roles ^ 2;
        }
    }

    get rolesString(): string {
        let value = ''
        value += this.View ? 'View | ' : '';
        value += this.Admin ? 'Admin | ' : '';

        return value.replace(/\s\|\s$/, '');
    }
}
