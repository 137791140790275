import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { alert } from 'devextreme/ui/dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { SecurityService } from '../../shared/services/security.service';
import { PatentService } from '../patent.service';
@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss'],
})
export class PdfUploadComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  @ViewChild('form', { static: true }) form: NgForm;
  files: any[] = [];
  uploadForm: FormGroup;
  constructor(private fb: FormBuilder, private patentService: PatentService,
    private securitryService: SecurityService, private router: Router) {
    this.securitryService.GetToken().subscribe(token => {
      this.uploader = new FileUploader({
        url: patentService.api_base_url + 'api/patentpdf/upload',
        authToken: "Bearer " + token
      });
      
      this.uploader.onErrorItem = function (item: FileItem, res: string) {
        alert(JSON.parse(res).messages, 'error');
      };
    })

  }

  ngOnInit() {
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
