import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AccountManager } from '../../../../account/models/account-manager';
import { environment } from '../../../../environments/environment';
import { IMenuItem } from '../../models/imenu-item';
import { SecurityService } from '../../services/security.service';
import { StorageService } from '../../services/storage.service';


@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
    styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit, OnDestroy {
    authenticated: boolean = false;
    private subscription: Subscription;
    userName: string;
    account: AccountManager;
    navBarData: IMenuItem[];
    menuItems: IMenuItem[];
    constructor(private service: SecurityService, private _http: HttpClient, private router: Router, private storage: StorageService) {
        const account = this.service.msalInstance.getAccount();
        if (account) {
            this.userName = account.name;
            this.service.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
                headerSub.subscribe(header => {
                    this._http.get(`${environment.api_base_url}api/account/get`, { headers: header }).subscribe(a => {
                        subscriber.next(a);
                    });
                })
            })).subscribe((d)  => {
                const a = new AccountManager();
                this.account = Object.assign(a, d);
                this.authenticated = true;
                this.userName = account.name;
                this.resetRight();
                this.storage.store('account', this.account);
            });
        }
    }

    ngOnInit() {
        this.menuItems = [
            <IMenuItem>{ right: 2, text: 'Accounts', icon: 'card', route: 'account' },
            <IMenuItem>{ right: 1, text: 'Search', icon: 'search', route: 'search' },
            <IMenuItem>{ right: 1, text: 'Validations', icon: 'bookmark', route: 'validations' },
            <IMenuItem>{ right: 2, text: 'Configuration', icon: 'preferences', route: 'configlist' },
            <IMenuItem>{ right: 1, text: 'PDF', icon: 'bookmark', route: 'pdf' }
        ];
    }

    resetRight() {
        this.navBarData = this.menuItems.map((s) => this.filterRight(s)).filter(i => i !== null, this);
    }

    filterRight(item: IMenuItem): IMenuItem {
        let rightItem: IMenuItem = item;
        // this.service.getHeaders()
        if (item.right && this.account && this.account.roles && ((item.right & this.account.roles) !== item.right)) {
            rightItem = null;
        }
        return rightItem;
    }

    logoutClicked(event: any) {
        event.preventDefault();
        console.log('Logout clicked');
        this.logout();
    }

    login() {
        this.service.Authorize();
    }

    logout() {
        this.service.Logoff();
    }

    selectionChanged(e: any) {
        this.router.navigate([e.itemData.route]);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
