<div class="app-patent" style="height: 95% !important;">
    <div class="app-patent_description">
        <div class="row">
            <div class="col-2">
                <div class="app-patent_gazette" [ngSwitch]="config?.kind">
                    <div *ngSwitchCase="1" class="app-patent_gazette-value">Patent for invention</div>
                    <div *ngSwitchCase="2" class="app-patent_gazette-value">Utility model</div>
                    <div *ngSwitchCase="3" class="app-patent_gazette-value">Industrial design </div>
                </div>
            </div>
            <div class="col-10">
                <div class="row" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <div class="col-2">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Gazette number</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.gazetteNumber}}</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Date</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.date}}</div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Sub Code</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.subCode || ''}}</div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Country</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.countryCode || ''}}</div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Category</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.legalEventCategory || ''}}</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="row">
                            <div class="app-patent_gazette">
                                <div class="app-patent_gazette-label">St. 17 code</div>
                                <div class="app-patent_gazette-value">{{gazetteInfo?.st17Code || ''}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="app-patent_gazette" [ngSwitch]="config?.wipoCompliant">
                                <div *ngSwitchCase="true" class="app-patent_gazette-value" style="margin: 0">WIPO/ST.17
                                    original</div>
                                <div *ngSwitchCase="false" class="app-patent_gazette-value" style="margin: 0">Internal
                                    classification</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div #visiblediv class="row" style="display: none;">
                    <div class="col-12">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Section heading original language</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.sectionHeaderOrg || ''}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="app-patent_gazette">
                            <div class="app-patent_gazette-label">Section heading text english</div>
                            <div class="app-patent_gazette-value">{{gazetteInfo?.sectionHeaderEng || ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="height: 85% !important;">
        <div class="col-6 h-100 w-100 pdfcontainer">
            <iframe class="h-100 w-100" [src]="pdfUrl | safePipe" title=""></iframe>
        </div>
        <app-biblio #biblioComponent class="col-6 h-100 w-100" [stable]="true" [biblio]="biblioData" [newBiblio]="newBiblioData"></app-biblio>
    </div>
    <div class="buttons">
        <button #submitnext (click)="submit($event, 2)" class="btn btn-primary align-bottom">Submit & Next</button>
        <button #submitcomplete (click)="submit($event, 8)" class="btn btn-primary align-bottom">Submit & Complete Section</button>
        <button #submitcomplete (click)="submit($event, 4)" class="btn btn-primary align-bottom">Submit & Complete</button>
        <button (click)="showList()" class="btn btn-primary align-bottom">Processed Events</button>
        <button (click)="showSubCodeSelection()" class="btn btn-primary align-bottom">Change Sub Code</button>
        <button (click)="toggleVisible()" class="btn btn-primary align-bottom" [ngClass]="{'buttons-expand': !visibleToggle}">{{visibleToggle
            ? 'Expand All': 'Collapse All'}}</button>

        <button (click)="showEventView()" class="btn btn-primary align-bottom">Data View</button>
    </div>


</div>

<app-patentlist [(patents)]="patents" [(patentsDatasource)]="patentsDataSource" (refresh)="patentDeleted()"></app-patentlist>

<app-existlist [(patents)]="existList" (createEvent)="createEventReturn($event)" (exitEvent)="exitEventReturn($event)"></app-existlist>
<app-subcode-change [(configs)]="configs" [(subCodesDataSource)]="subCodesDataSource"></app-subcode-change>
<app-event-view position="right"></app-event-view>
