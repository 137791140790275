import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PartyMember, PartyTranslation, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-parties',
  templateUrl: './biblio-parties.component.html',
  styleUrls: ['./biblio-parties.component.scss']
})
export class BiblioPartiesComponent extends BiblioFormArrayBaseComponent {
  @Input() headerText: string;
  constructor(protected formBuilder: FormBuilder, protected patentService: PatentService) {
    super(formBuilder);
  }
  setFormArray(data: PartyMember[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        language: item.language,
        name: item.name,
        address1: item.address1,
        postCode: item.postCode,
        city: item.city,
        country: item.country,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      language: this.config.language.default || '',
      name: '',
      address1: '',
      postCode: '',
      city: '',
      country: this.config.country.default || '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }

  setTranslations(itemG: FormGroup, data: PartyTranslation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required()) {
      data = [<PartyTranslation>{ type: this.nameType, language: this.config.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        trName: item.trName,
        trAddress1: item.trAddress1,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      trName: '',
      trAddress1: '',
    });

    item.get('name').valueChanges.subscribe(value => this.getTranslate(translate.get('trName'), translate.get('language').value, value))
    item.get('address1').valueChanges.subscribe(value => this.getTranslate(translate.get('trAddress1'), translate.get('language').value, value))
    this.getTranslate(translate.get('trName'), translate.get('language').value, item.get('name').value)
    this.getTranslate(translate.get('trAddress1'), translate.get('language').value, item.get('address1').value)

    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }

  get TranslationsValue(): Translation[] {
    const values: Translation[] = [];
    this.formArray.value.forEach(item => {
      item.translations.forEach(tr => {
        if (tr.trName && tr.trName != '' && tr.trName.trim().length > 0) {
          values.push(<Translation>{
            type: tr.type,
            language: tr.language,
            org: item.name,
            tr: tr.trName
          });
        }

        if (tr.trAddress1 && tr.trAddress1 != '' && tr.trAddress1.trim().length > 0) {
          values.push(<Translation>{
            type: tr.type,
            language: tr.language,
            org: item.address1,
            tr: tr.trAddress1
          });
        }
      });
    });
    return values;
  }

}
