import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityService } from '../app/shared/services/security.service';
import { environment } from '../environments/environment';
import { AccountManager } from './models/account-manager';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private securityService: SecurityService, private http: HttpClient) {
  }

  getAllAccounts(params: any): Observable<any> {
   return this.securityService.getHeaders().pipe(headerSub => new Observable<any>(subscriber => {
      headerSub.subscribe(header => {
        this.http.get(`${environment.api_base_url}api/account/all` + params, { headers: header }).subscribe((d: any) => {
          const ds = d.data.map((item, _) => {
            const a = new AccountManager;
            return Object.assign(a, item);
          });
          subscriber.next({
            data: ds,
            totalCount: d.totalCount
          });
          subscriber.complete();
        });
      });
    }));
  }

  post(data: any) {
    return this.securityService.getHeaders().pipe(sub => new Observable<any>(subscriber => {
      sub.subscribe(d => {
        this.http.post(`${environment.api_base_url}api/account/post`, data, {headers: d}).subscribe(r => {
          subscriber.next(r)
        });
      });
    }));
  }
}
