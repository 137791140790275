import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from '../../../../node_modules/rxjs';
import { IBiblio } from '../../shared/models/ibiblio.model';
import { Config } from '../../shared/models/iconfig.model';
import { DataService } from '../../shared/services/data.service';
import { PatentService } from '../patent.service';
import { BiblioAbstractsComponent } from './biblio-abstracts/biblio-abstracts.component';
import { BiblioApplicationComponent } from './biblio-application/biblio-application.component';
import { BiblioClaimsComponent } from './biblio-claims/biblio-claims.component';
import { BiblioDOfPublicationComponent } from './biblio-d-of-publication/biblio-d-of-publication.component';
import { BiblioEuropeanPatentsComponent } from './biblio-european-patents/biblio-european-patents.component';
import { BiblioIntConventionComponent } from './biblio-int-convention/biblio-int-convention.component';
import { BiblioIpcsComponent } from './biblio-ipcs/biblio-ipcs.component';
import { BiblioLegalEventComponent } from './biblio-legal-event/biblio-legal-event.component';
import { BiblioLicenseComponent } from './biblio-license/biblio-license.component';
import { BiblioNonPatentCitationsComponent } from './biblio-non-patent-citations/biblio-non-patent-citations.component';
import { BiblioPartiesComponent } from './biblio-parties/biblio-parties.component';
import { BiblioPatentCitationsComponent } from './biblio-patent-citations/biblio-patent-citations.component';
import { BiblioPledgeComponent } from './biblio-pledge/biblio-pledge.component';
import { BiblioPrioritiesComponent } from './biblio-priorities/biblio-priorities.component';
import { BiblioPublicationComponent } from './biblio-publication/biblio-publication.component';
import { BiblioRelatedComponent } from './biblio-related/biblio-related.component';
import { BiblioSpcComponent } from './biblio-spc/biblio-spc.component';
import { BiblioTitlesComponent } from './biblio-titles/biblio-titles.component';


@Component({
  selector: 'app-biblio',
  templateUrl: './biblio.component.html',
  styleUrls: ['./biblio.component.scss']
})
export class BiblioComponent implements OnInit, OnChanges, OnDestroy {
  private _stable: boolean;
  private configSubscribe: Subscription;
  id?: number;
  @Input() biblio = new IBiblio;
  @Input() newBiblio = new IBiblio;
  config = new Config();
  private currentConfig: Config = new Config();
  // screenShots = [];
  @ViewChild('scrollview', { static: true }) ScrollView: ElementRef;
  @ViewChild('Publication', { static: true }) Publication: BiblioPublicationComponent;
  @ViewChild('Application', { static: true }) Application: BiblioApplicationComponent;
  @ViewChild('Priorities', { static: true }) Priorities: BiblioPrioritiesComponent;
  @ViewChild('DOfPublication', { static: true }) DOfPublication: BiblioDOfPublicationComponent;
  @ViewChild('Ipcs', { static: true }) Ipcs: BiblioIpcsComponent;
  @ViewChild('Applicants', { static: true }) Applicants: BiblioPartiesComponent;
  @ViewChild('Inventors', { static: true }) Inventors: BiblioPartiesComponent;
  @ViewChild('Assignees', { static: true }) Assignees: BiblioPartiesComponent;
  @ViewChild('Agents', { static: true }) Agents: BiblioPartiesComponent;
  @ViewChild('InvOrApps', { static: true }) InvOrApps: BiblioPartiesComponent;
  @ViewChild('InvAppGrants', { static: true }) InvAppGrants: BiblioPartiesComponent;
  @ViewChild('Titles', { static: true }) Titles: BiblioTitlesComponent;
  @ViewChild('Abstracts', { static: true }) Abstracts: BiblioAbstractsComponent;
  @ViewChild('IntConvention', { static: true }) IntConvention: BiblioIntConventionComponent;
  @ViewChild('Spc', { static: true }) Spc: BiblioSpcComponent;
  @ViewChild('EuropeanPatents', { static: true }) EuropeanPatents: BiblioEuropeanPatentsComponent;
  @ViewChild('PatentCitations', { static: true }) PatentCitations: BiblioPatentCitationsComponent;
  @ViewChild('NonPatentCitations', { static: true }) NonPatentCitations: BiblioNonPatentCitationsComponent;
  @ViewChild('Related', { static: true }) Related: BiblioRelatedComponent;
  @ViewChild('License', { static: true }) License: BiblioLicenseComponent;
  @ViewChild('Pledge', { static: true }) Pledge: BiblioPledgeComponent;
  @ViewChild('LegalEvent', { static: true }) LegalEvent: BiblioLegalEventComponent;
  @ViewChild('PublicationNew', { static: true }) PublicationNew: BiblioPublicationComponent;
  @ViewChild('ApplicationNew', { static: true }) ApplicationNew: BiblioApplicationComponent;
  @ViewChild('PrioritiesNew', { static: true }) PrioritiesNew: BiblioPrioritiesComponent;
  @ViewChild('DOfPublicationNew', { static: true }) DOfPublicationNew: BiblioDOfPublicationComponent;
  @ViewChild('IpcsNew', { static: true }) IpcsNew: BiblioIpcsComponent;
  @ViewChild('ApplicantsNew', { static: true }) ApplicantsNew: BiblioPartiesComponent;
  @ViewChild('InventorsNew', { static: true }) InventorsNew: BiblioPartiesComponent;
  @ViewChild('AssigneesNew', { static: true }) AssigneesNew: BiblioPartiesComponent;
  @ViewChild('AgentsNew', { static: true }) AgentsNew: BiblioPartiesComponent;
  @ViewChild('InvOrAppsNew', { static: true }) InvOrAppsNew: BiblioPartiesComponent;
  @ViewChild('InvAppGrantsNew', { static: true }) InvAppGrantsNew: BiblioPartiesComponent;
  @ViewChild('TitlesNew', { static: true }) TitlesNew: BiblioTitlesComponent;
  @ViewChild('AbstractsNew', { static: true }) AbstractsNew: BiblioAbstractsComponent;
  @ViewChild('IntConventionNew', { static: true }) IntConventionNew: BiblioIntConventionComponent;
  @ViewChild('SpcNew', { static: true }) SpcNew: BiblioSpcComponent;
  @ViewChild('EuropeanPatentsNew', { static: true }) EuropeanPatentsNew: BiblioEuropeanPatentsComponent;
  @ViewChild('PatentCitationsNew', { static: true }) PatentCitationsNew: BiblioPatentCitationsComponent;
  @ViewChild('NonPatentCitationsNew', { static: true }) NonPatentCitationsNew: BiblioNonPatentCitationsComponent;
  @ViewChild('RelatedNew', { static: true }) RelatedNew: BiblioRelatedComponent;
  @ViewChild('LicenseNew', { static: true }) LicenseNew: BiblioLicenseComponent;
  @ViewChild('PledgeNew', { static: true }) PledgeNew: BiblioPledgeComponent;
  @ViewChild('Claims', { static: true }) Claims: BiblioClaimsComponent;
  @ViewChild('ClaimsNew', { static: true }) ClaimsNew: BiblioClaimsComponent;
  // @ViewChild(BiblioBaseComponent) groups;
  constructor(private service: PatentService) {
  }


  ngOnInit(): void {
    const _that = this;
    this.configSubscribe = this.service.config$.subscribe((conf: Config) => {
      let cfg = new Config();
      DataService.patchValue(cfg, conf);
      Object.assign(cfg.dOfPublication, { date: cfg.publication.date });
      _that.config = cfg;
    });
  }

  ngOnChanges(): void {
    if (this.biblio) {
      this.ScrollView.nativeElement.scrollTop = 0;
    }
  }

  @Input()
  set stable(value: boolean) {
    this._stable = value;
  }

  get stable() {
    return this._stable;
  }
  get Valid(): boolean {
    return this.Publication.Valid && this.Application.Valid && this.Priorities.Valid
      && this.DOfPublication.Valid && this.Ipcs.Valid && this.Applicants.Valid
      && this.Inventors.Valid && this.Agents.Valid && this.Assignees.Valid
      && this.InvAppGrants.Valid && this.InvOrApps.Valid && this.Titles.Valid
      && this.Abstracts.Valid && this.IntConvention.Valid
      && this.EuropeanPatents.Valid && this.PatentCitations.Valid && this.NonPatentCitations.Valid
      && this.Related.Valid && this.License.Valid && this.Pledge.Valid && this.LegalEvent.Valid
      && this.PublicationNew.Valid && this.ApplicationNew.Valid && this.PrioritiesNew.Valid
      && this.DOfPublicationNew.Valid && this.IpcsNew.Valid && this.ApplicantsNew.Valid
      && this.InventorsNew.Valid && this.AgentsNew.Valid && this.AssigneesNew.Valid
      && this.InvAppGrantsNew.Valid && this.InvOrAppsNew.Valid && this.TitlesNew.Valid
      && this.AbstractsNew.Valid && this.IntConventionNew.Valid
      && this.EuropeanPatentsNew.Valid && this.PatentCitationsNew.Valid && this.NonPatentCitationsNew.Valid
      && this.RelatedNew.Valid && this.LicenseNew.Valid && this.PledgeNew.Valid;
  }
  get value(): { id: number, orignal: any, new: any, legalEvent: any, translations: any } {
    const data: any = { id: this.biblio.id, orignal: {}, new: {}, legalEvent: {}, translations: [] };
    Object.assign(data.orignal, { publication: Object.assign(this.Publication.Value, { date: this.DOfPublication.Value.date }) });
    Object.assign(data.orignal, { application: this.Application.Value });
    Object.assign(data.orignal, { priorities: this.Priorities.Value });
    Object.assign(data.orignal, { dOfPublication: this.DOfPublication.Value });
    Object.assign(data.orignal, { ipcs: this.Ipcs.Value });
    Object.assign(data.orignal, { applicants: this.Applicants.Value });
    Object.assign(data.orignal, { inventors: this.Inventors.Value });
    Object.assign(data.orignal, { assignees: this.Assignees.Value });
    Object.assign(data.orignal, { agents: this.Agents.Value });
    Object.assign(data.orignal, { invOrApps: this.InvOrApps.Value });
    Object.assign(data.orignal, { invAppGrants: this.InvAppGrants.Value });
    Object.assign(data.orignal, { titles: this.Titles.Value });
    Object.assign(data.orignal, { abstracts: this.Abstracts.Value });
    Object.assign(data.orignal, { screenShots: this.Abstracts.screenShots });
    Object.assign(data.orignal, { intConvention: this.IntConvention.Value });
    Object.assign(data.orignal, { license: this.License.Value });
    Object.assign(data.orignal, { pledge: this.Pledge.Value });
    Object.assign(data.orignal, {
      spc: {
        number: this.EuropeanPatents.Value.number,
        date: this.EuropeanPatents.Value.date,
        expiredDate: this.EuropeanPatents.Value.expiredDate,
        patent: this.EuropeanPatents.Value.patent,
      }
    });
    Object.assign(data.orignal, { europeanPatents: this.EuropeanPatents.Value });
    Object.assign(data.orignal, { patentCitations: this.PatentCitations.Value });
    Object.assign(data.orignal, { nonPatentCitations: this.NonPatentCitations.Value });
    Object.assign(data.orignal, { related: this.Related.Value });
    Object.assign(data.legalEvent, this.LegalEvent.Value);
    Object.assign(data.orignal, {claims: this.Claims.Value });

    data.translations = data.translations.concat(this.LegalEvent.TranslationsValue,
      // this.Abstracts.TranslationsValue,
      this.Titles.TranslationsValue,
      this.PatentCitations.TranslationsValue,
      this.NonPatentCitations.TranslationsValue,
      this.Applicants.TranslationsValue,
      this.Inventors.TranslationsValue,
      this.Assignees.TranslationsValue,
      this.Agents.TranslationsValue,
      this.InvOrApps.TranslationsValue,
      this.InvAppGrants.TranslationsValue);

    Object.assign(data.new, { publication: Object.assign(this.PublicationNew.Value, { date: this.DOfPublicationNew.Value.date }) });
    Object.assign(data.new, { application: this.ApplicationNew.Value });
    Object.assign(data.new, { priorities: this.PrioritiesNew.Value });
    Object.assign(data.new, { dOfPublication: this.DOfPublicationNew.Value });
    Object.assign(data.new, { ipcs: this.IpcsNew.Value });
    Object.assign(data.new, { applicants: this.ApplicantsNew.Value });
    Object.assign(data.new, { inventors: this.InventorsNew.Value });
    Object.assign(data.new, { assignees: this.AssigneesNew.Value });
    Object.assign(data.new, { agents: this.AgentsNew.Value });
    Object.assign(data.new, { invOrApps: this.InvOrAppsNew.Value });
    Object.assign(data.new, { invAppGrants: this.InvAppGrantsNew.Value });
    Object.assign(data.new, { titles: this.TitlesNew.Value });
    Object.assign(data.new, { abstracts: this.AbstractsNew.Value });
    Object.assign(data.new, { screenShots: this.AbstractsNew.screenShots });
    Object.assign(data.new, { intConvention: this.IntConventionNew.Value });
    Object.assign(data.new, { license: this.LicenseNew.Value });
    Object.assign(data.new, { pledge: this.PledgeNew.Value });
    Object.assign(data.new, {
      spc: {
        number: this.EuropeanPatentsNew.Value.number,
        date: this.EuropeanPatentsNew.Value.date,
        expiredDate: this.EuropeanPatentsNew.Value.expiredDate,
        patent: this.EuropeanPatentsNew.Value.patent,
      }
    });
    Object.assign(data.new, { europeanPatents: this.EuropeanPatentsNew.Value });
    Object.assign(data.new, { patentCitations: this.PatentCitationsNew.Value });
    Object.assign(data.new, { nonPatentCitations: this.NonPatentCitationsNew.Value });
    Object.assign(data.new, { related: this.RelatedNew.Value });
    Object.assign(data.new, { claims: this.ClaimsNew.Value });
    data.translations = data.translations.concat(
      // this.AbstractsNew.TranslationsValue,
      this.TitlesNew.TranslationsValue,
      this.PatentCitationsNew.TranslationsValue,
      this.NonPatentCitationsNew.TranslationsValue,
      this.ApplicantsNew.TranslationsValue,
      this.InventorsNew.TranslationsValue,
      this.AssigneesNew.TranslationsValue,
      this.AgentsNew.TranslationsValue,
      this.InvOrAppsNew.TranslationsValue,
      this.InvAppGrantsNew.TranslationsValue);

    return data;
  }

  ngOnDestroy(): void {
    this.configSubscribe.unsubscribe();
  }

  private visibleRecursive(attr: any, value: boolean) {
    if (attr && typeof (attr) == 'object') {
      Object.keys(attr).forEach(key => {
        if (key == 'visible') {
          attr[key] = value;
        }
        this.visibleRecursive(attr[key], value);
      });
    }
  }

  private openToggle(obj: any, value: boolean) {

    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        Object.keys(obj[key]).forEach(o => {
          if (o == 'openToggle') {
            obj[key][o] = value
          }
        });
      }
    })
  }

  expandAll() {
    // this.visibleRecursive(this.config, true);
    this.openToggle(this, true);

  }

  collapseAll() {
    // this.config = DataService.patchValue(this.config, this.currentConfig);
    this.openToggle(this, false);
  }
}
