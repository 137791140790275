export class GazetteModel {
    countryCode: string;
    subCode: string;
    description: string;
    legalEventCategory: string;
    st17Code: string;
    sectionHeaderOrg: string;
    sectionHeaderEng: string;
    gazetteNumber: string;
    date: string;
}
