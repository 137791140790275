import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';
import { BiblioBaseComponent } from '../biblio-base.component';

@Component({
  selector: 'app-biblio-publication',
  templateUrl: './biblio-publication.component.html',
  styleUrls: ['./biblio-publication.component.scss']
})
export class BiblioPublicationComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  private previousValue = '';
  @Input() biblioId?: number;

  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      (this.formGroup as AbstractControl).patchValue(this.data);
    }
    if (this.data && changes.config && this.config
      && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      this.setValidator(this.formGroup, this.config, this.config.ConfigName);
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      authority: '',
      number: '',
      kind: '',
      date: '',
      languageDesignation: '',
    });

    this.formGroup.get('number').valueChanges.subscribe((value) => {

      if (!this.biblioId && this.previousValue !== value) {
        this.previousValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, pubNumber: value });
      }
    });
  }

  get Valid(): boolean {
    if (((this.config.authority.visible && !this.formGroup.get('authority').valid)
      || (this.config.number.visible && !this.formGroup.get('number').valid)
      || (this.config.kind.visible && !this.formGroup.get('kind').valid))
      &&
      this.config.visible(this.stable)) {
        alert(`Not all the fields in <b>${this.headerText}</b> have a valid value, please have a check!`, 'VALIDATION ERROR!');
      return false;
    } else {
      return true;
    }
  }
}
