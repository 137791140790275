<div class="app-data-export">
    <dx-popup container=".app-data-export" [width]="500" [height]="300" [showTitle]="true" [dragEnabled]="false"
        [closeOnOutsideClick]="true" [visible]="popupVisible" (onHidden)="Cancel($event)">
        <div *dxTemplate="let data of 'title'">
            <h3>Data export</h3>
        </div>
        <div id="selection" *dxTemplate="let data of 'content'" class="popup-template">
            <dx-form [formData]="status" [colCount]=2 validationGroup="dataGroup">
                <dxi-item dataField="authority" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Required filed"></dxi-validation-rule>
                    <dxo-label text="Authority"></dxo-label>
                </dxi-item>
                <dxi-item type="emtpy"></dxi-item>
                <dxi-item dataField="from" editorType="dxDateBox"
                    [editorOptions]="{dateSerializationFormat: 'yyyy-MM-dd'}">
                    <dxo-label text="From"></dxo-label>
                </dxi-item>
                <dxi-item dataField="to" editorType="dxDateBox"
                    [editorOptions]="{dateSerializationFormat: 'yyyy-MM-dd'}">
                    <dxo-label text="To"></dxo-label>
                </dxi-item>
            </dx-form>
            <div class="popup-bottom-toolbar">
                <dx-button class="btn btn-primary" icon="save" text="Export" validationGroup="dataGroup"
                    (onClick)="export($event)"></dx-button>
                <dx-button class="btn btn-primary" icon="cancel" text="Cancel" (onClick)="Cancel($event)"></dx-button>
            </div>
            <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#selection' }"
                [(visible)]="loadingVisible" [showIndicator]="true" [shading]="true" [closeOnOutsideClick]="false">
            </dx-load-panel>
        </div>

    </dx-popup>
</div>