import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PatentService } from '../../patent.service';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {
  loadingVisible = false
  status: { authority: null,  start?: Date, end?: Date }
  popupVisible: boolean = false;
  constructor(private service: PatentService, private client: HttpClient) {
    this.status = {authority: null, start: null, end: null }
  }

  ngOnInit() {
  }

  export($event: any) {
    const result = $event.validationGroup.validate();
    if (!result.isValid) {
      return;
    }

    this.loadingVisible = true;
    const url = `${this.service.reportUrl}search?authority=${this.status.authority}&from=${this.status.start ?? ''}&to=${this.status.end ?? ''}`;
    this.client.get(url, { responseType: 'blob' }).subscribe({
      next: (d) => {
        var durl = window.URL.createObjectURL(d);
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = durl;
        link.setAttribute('download', `data export_${this.status.authority}.xlsx`);
        document.body.appendChild(link);
        link.click();
      },
      complete: () => {
        this.loadingVisible = false;
        this.popupVisible = false;
      },
      error: (e: HttpErrorResponse) => {
        alert(e.statusText);
        this.loadingVisible = false;
      }
    });
  }

  Cancel($event) {
    this.popupVisible = false;
  }

}