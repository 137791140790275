<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngIf="config.number.visible && data.number">
    <label>Patent number</label>
    <div class="value">{{data.number}}</div>
  </div>
  <div *ngIf="config.date.visible && data.date">
    <label>Event date</label>
    <div class="value">{{data.date}}</div>
  </div>
  <div *ngIf="config.language.visible && data.language">
    <label>Language</label>
    <div class="value">{{data.language}}</div>
  </div>
  <div *ngIf="config.note.visible && data.note">
    <label>Note</label>
    <div class="value">{{data.note}}</div>
  </div>


  <div *ngFor="let tr of data.translations">
    <div *ngIf="config.translations.language.visible && tr.language">
      <label>Language</label>
      <div class="value">{{tr.language}}</div>
    </div>
    <div *ngIf="config.translations.tr.visible && tr.tr">
      <label>Note</label>
      <div class="value">{{tr.tr}}</div>
    </div>

  </div>
</div>