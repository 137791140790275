<div class="app-config-item row" [formGroup]="configuration">
  <div class="col-12">
    <div class="form-group">
      <label class="app-config-item_label">Required
        <input class="custom-checkbox" type="checkbox" [formControl]="configuration.get('required')">
      </label>
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label class="app-config-item_label">Visible
        <input class="custom-checkbox" type="checkbox" [formControl]="configuration.get('visible')">
      </label>
    </div>
  </div>
  <div class="col-12" *ngIf="existOriginal">
    <div class="form-group">
      <label class="app-config-item_label">Original INID
        <input class="custom-checkbox" type="checkbox" [formControl]="configuration.get('original')">
      </label>
    </div>
  </div>
  <div class="col-12" *ngIf="existPattern">
    <div class="form-group">
      <label class="app-config-item_label">Pattern
        <input type="text" [formControl]="configuration.get('pattern')">
      </label>
    </div>
  </div>
  <div class="col-12" *ngIf="existDefault">
    <div class="form-group">
      <label class="app-config-item_label">Default
        <input type="text" [formControl]="configuration.get('default')">
      </label>
    </div>
  </div>
</div>