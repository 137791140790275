import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Ipc } from '../../../shared/models/ibiblio.model';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-ipcs',
  templateUrl: './biblio-ipcs.component.html',
  styleUrls: ['./biblio-ipcs.component.scss']
})
export class BiblioIpcsComponent extends BiblioFormArrayBaseComponent {


  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  setFormArray(data: Ipc[]) {
    const itemFGs = data.map((item, index) => this.formBuilder.group({
      edition: item.edition,
      class: item.class,
      date: item.date,
      ipcDate: item.ipcDate,
      domestic: item.domestic,
    }));
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      edition: this.config.edition.default || '',
      class: '',
      date: this.config.date.default || '',
      ipcDate: this.config.date.default || '',
      domestic: '',
    });
    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }
}
