<div class="app-search container">
  <h4></h4>
  <dx-toolbar class="app-search_toolbar">
    <dxi-item location="before">
      <dx-form [formData]="condition" [colCount]=3 validationGroup="conditionGroup">
        <dxi-item dataField="country">
          <dxo-label text="Country Code"></dxo-label>
        </dxi-item>
        <dxi-item dataField="number">
          <dxi-validation-rule type="required" message="Number is reqired"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [template]="'buttonTemplate'"></dxi-item>
        <div *dxTemplate="let data of 'buttonTemplate'">
          <dx-button class="btn-sm btn-primary" icon="search" text="Search" validationGroup="conditionGroup" (onClick)="search($event)"></dx-button>
        </div>
      </dx-form>
    </dxi-item>
  </dx-toolbar>
  <h4>Search results</h4>
  <div>
    <div *ngFor="let item of data; let i=index" class="result">
      <a (click)="onClick(item)">ID {{item.id }} Keys{{ item.pubNumber ? " Pub: " + item.pubNumber: "" }}{{ item.appNumber ? " App: " + item.appNumber: "" }}{{ item.legalNumber ? " Legal: " + item.legalNumber: "" }} {{ item.epPubNumber ? " EP_Pub.: " + item.epPubNumber: "" }} {{ item.epAppNumber ? " EP_App.: " + item.epAppNumber: "" }}</a>
      <div>Country: {{item.countryCode}} File Name: {{item.fileName}}</div>
      <div>Sub code: {{item.subCode}} St. 17 code: {{item.sectionCode}}</div>
      <div>Header: {{item.sectionHeaderEng}}</div>
      <div>Date: {{item.lastUpdate | date:'fullDate'}}</div>
      <a (click)="showView(item.id)">... View</a>
    </div>
  </div>
</div>

<app-event-view position="center"></app-event-view>