import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DxTabsModule } from 'devextreme-angular';
import { AccountModule } from '../../account/account.module';
import { HeaderComponent } from './components/header/header.component';
import { IdentityComponent } from './components/identity/identity.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DataService } from './services/data.service';
import { SecurityService } from './services/security.service';
import { StorageService } from './services/storage.service';
@NgModule({
  imports: [
    CommonModule,
    AccountModule,
    DxTabsModule
  ],
  declarations: [PageNotFoundComponent, HeaderComponent, IdentityComponent],
  exports: [
    PageNotFoundComponent, IdentityComponent,
    HeaderComponent,
  ],
  providers: [DataService, SecurityService, StorageService]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Providers
      ]
    };
  }
}
