<div class="app-indentity">
    <section class="app-identity-section" *ngIf="!authenticated">
        <div class="app-identity-item" (click)="login()">

            <div class="app-identity-name app-identity-name-upper">Login</div>
        </div>
    </section>

    <section class="app-identity-section row" *ngIf="authenticated">
        <dx-tabs class="col-8" style="border: none;" [dataSource]="navBarData" (onItemClick)="selectionChanged($event)" [showNavButtons]="false" itemTemplate="menu-item">
            <div *dxTemplate="let content of 'menu-item'">
                <div class="dx-item-content dx-tab-content">
                   <div><em class="dx-icon dx-icon-{{content.icon}}"></em></div>
                   <div>{{content.text}}</div>
                </div>
            </div>
        </dx-tabs>
        <div class="app-identity-name col-2">{{userName}}</div>
        <div class="app-identity-item col-2" (click)="logoutClicked($event)">
            <div class="app-identity-name app-identity-name-upper">Log Out</div>
        </div>
    </section>
</div>