<div class="container-fluid app-config">

    <form [formGroup]="configForm">
        <section>
            <div class="col-12">
                <h1>Patent Legal Events Status Configuration</h1>
            </div>
            <!-- {{this.configForm.value | json}} -->
            <div class="row" style="margin-left: 0">
                <label class="app-config_kind-label">Patent for invention
                    <input class="custom-checkbox" type="radio" value="1" [formControl]="configForm.get('kind')">
                </label>
                <label class="app-config_kind-label">Utility model
                    <input class="custom-checkbox" type="radio" value="2" [formControl]="configForm.get('kind')">
                </label>
                <label class="app-config_kind-label">Industrial design
                    <input class="custom-checkbox" type="radio" value="3" [formControl]="configForm.get('kind')">
                </label>
                <div *ngIf="configForm.get('kind').hasError('required') && configForm.get('kind').touched" class="alert alert-danger">Required
                    field.</div>
            </div>
            <div class="row" style="margin-left:0">
                <div class="col-3">
                    <div class="form-group">
                        <label class="app-config_title">Country</label>
                        <div class="app-config_title-content">
                            <input class="" type="text" [formControl]="configForm.get('country')">
                            <div *ngIf="configForm.get('country').hasError('required') && configForm.get('country').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label class="app-config_title">Sub Code</label>
                        <div class="app-config_title-content">
                            <input class="" type="text" [formControl]="configForm.get('subCode')">
                            <div *ngIf="configForm.get('subCode').hasError('required') && configForm.get('subCode').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label class="app-config_title">Category</label>
                        <div class="app-config_title-content">
                            <select [formControl]="configForm.get('legalEventCategory')">
                                <option *ngFor="let category of categories" [ngValue]="category.key">
                                    {{ category.value }}
                                </option>
                            </select>
                            <div *ngIf="configForm.get('legalEventCategory').hasError('required') && configForm.get('legalEventCategory').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label class="app-config_title">St. 17 code</label>
                        <div class="app-config_title-content">
                            <input class="" type="text" [formControl]="configForm.get('st17Code')">
                            <div *ngIf="configForm.get('st17Code').hasError('required') && configForm.get('st17Code').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-4">
                    <div class="form-group">
                        <label class="app-config_title">Description</label>
                        <div class="app-config_title-content">
                            <textarea class="" type="text" [formControl]="configForm.get('description')"> </textarea>
                            <div *ngIf="configForm.get('description').hasError('required') && configForm.get('description').touched" class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-4">
                    <div class="form-group">
                        <label class="app-config_title">Section heading original language</label>
                        <div class="app-config_title-content">
                            <textarea class="" type="text" [formControl]="configForm.get('sectionHeaderOrg')">







 </textarea>
                            <div *ngIf="configForm.get('sectionHeaderOrg').hasError('required') && configForm.get('sectionHeaderOrg').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group">
                        <label class="app-config_title">Section heading text English</label>
                        <div class="app-config_title-content">
                            <textarea class="" type="text" [formControl]="configForm.get('sectionHeaderEng')">







 </textarea>
                            <div *ngIf="configForm.get('sectionHeaderEng').hasError('required') && configForm.get('sectionHeaderEng').touched"
                                class="alert alert-danger">Required field.</div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label class="app-config_title">WIPO/ST.17 original
                            <input class="custom-checkbox" type="checkbox" [formControl]="configForm.get('wipoCompliant')">
                        </label>
                    </div>
                </div>
            </div>
        </section>
        <accordion>
            <accordion-group #group1 [isOpen]="true">
                <div accordion-heading>
                    <h3>INID codes</h3>
                    <em class="float-right" [ngClass]="{'dx-icon-spindown': !group1.isOpen, 'dx-icon-spinup': group1.isOpen}"></em>
                </div>
                <section>
                    <h4 class="app-config_section-header">(11) Publication Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('publication.new')">
                        </label>
                    </h4>

                    <div class="row">
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(11) Publ. Number</div>
                            <app-config-item [configuration]="configForm.get('publication.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(12) Plain language designation of the kind of document</div>
                            <app-config-item [configuration]="configForm.get('publication.languageDesignation')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(13) Publ. Kind</div>
                            <app-config-item [configuration]="configForm.get('publication.kind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(26) Authority</div>
                            <app-config-item [configuration]="configForm.get('publication.authority')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(21) Application Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('application.new')">
                        </label>
                    </h4>
                    <div class="row">

                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(21) Appl. Number</div>
                            <app-config-item [configuration]="configForm.get('application.number')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(22) Appl. Date</div>
                            <app-config-item [configuration]="configForm.get('application.date')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(23) Other. Date</div>
                            <app-config-item [configuration]="configForm.get('application.otherDate')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(24) Effective Date</div>
                            <app-config-item [configuration]="configForm.get('application.effectiveDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(25) Language</div>
                            <app-config-item [configuration]="configForm.get('application.language')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(30) Priority Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('priorities.new')">
                        </label>
                    </h4>
                    <div class="row">

                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(31) Prio. Number</div>
                            <app-config-item [configuration]="configForm.get('priorities.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(32) Prio. Date</div>
                            <app-config-item [configuration]="configForm.get('priorities.date')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(33) Prio. CC</div>
                            <app-config-item [configuration]="configForm.get('priorities.country')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(40) Dates of making available to the public
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('dOfPublication.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-3">
                            <div class="app-config_item-label">(41) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_41')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(42) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_42')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(43) Publ. Date</div>
                            <app-config-item [configuration]="configForm.get('publication.date')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(44) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_44')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(45) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_45')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(46) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_46')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(47) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_47')"></app-config-item>
                        </div>
                        <div class="col-3">
                            <div class="app-config_item-label">(48) Date</div>
                            <app-config-item [configuration]="configForm.get('dOfPublication.date_48')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(51) Classification Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('ipcs.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-4">
                            <div class="app-config_item-label">(51) IPC Edition</div>
                            <app-config-item [configuration]="configForm.get('ipcs.edition')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(51) Classification</div>
                            <app-config-item [configuration]="configForm.get('ipcs.class')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-4">
                            <div class="app-config_item-label">(51) IPC Version</div>
                            <app-config-item [configuration]="configForm.get('ipcs.date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-4">
                            <div class="app-config_item-label">(51) IPC of the UI</div>
                            <app-config-item [configuration]="configForm.get('ipcs.ipcDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-4">
                            <div class="app-config_item-label">(52) Domestic or national classification</div>
                            <app-config-item [configuration]="configForm.get('ipcs.domestic')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(71) Applicant Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('applicants.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('applicants.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('applicants.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('applicants.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('applicants.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('applicants.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('applicants.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('applicants.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('applicants.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('applicants.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(72) Inventor Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('inventors.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('inventors.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('inventors.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('inventors.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('inventors.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('inventors.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('inventors.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('inventors.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('inventors.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('inventors.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(73) Grantee/Assignee/Owner Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('assignees.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('assignees.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('assignees.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('assignees.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('assignees.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('assignees.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('assignees.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('assignees.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('assignees.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('assignees.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(74) Agent Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('agents.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('agents.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('agents.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('agents.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('agents.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('agents.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('agents.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('agents.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('agents.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('agents.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(75) Inventor/Applicant Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('invOrApps.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('invOrApps.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('invOrApps.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('invOrApps.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('invOrApps.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(76) Inventor/Applicant/Grantee Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('invAppGrants.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.address1')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Post Code</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.postCode')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">City</div>
                            <app-config-item [configuration]="configForm.get('invAppGrants.city')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('invAppGrants.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('invAppGrants.translations.trName')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Address</div>
                                <app-config-item [configuration]="configForm.get('invAppGrants.translations.trAddress1')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(54) Title
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('titles.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="app-config_inner_item col-4">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('titles.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Text</div>
                            <app-config-item [configuration]="configForm.get('titles.text')"></app-config-item>
                        </div>
                       
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('titles.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Text</div>
                                <app-config-item [configuration]="configForm.get('titles.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(57) Abstract
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('abstracts.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="app-config_inner_item col-4">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('abstracts.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Text</div>
                            <app-config-item [configuration]="configForm.get('abstracts.text')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('abstracts.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Text</div>
                                <app-config-item [configuration]="configForm.get('abstracts.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <h4 class="app-config_section-header">(84) (85) (86) (87) PCT National Phase Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('intConvention.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">(86) PCT App. Number</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctApplNumber')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(86) PCT App. Kind</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctApplKind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(86) PCT App. Date</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctApplDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(86) PCT App. Country</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctApplCountry')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(87) PCT Pub. Number</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctPublNumber')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(87) PCT Pub. Kind</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctPublKind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(87) PCT Pub. Date</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctPublDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(87) PCT Pub. Country</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctPublCountry')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(88) Date of deferred publication of the search report</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctSearchDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(85) PCT Nat. Phase Date</div>
                            <app-config-item [configuration]="configForm.get('intConvention.pctNationalDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(84) Designated State</div>
                            <app-config-item [configuration]="configForm.get('intConvention.designatedStates.state')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(92) (93) (94) (95) (96) (97) European Patents
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('europeanPatents.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">(92) SPC Number</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.spc92Number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(92) SPC Date</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.spc92Date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(92) SPC Country</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.spc92Country')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(93) SPC Number</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(93) SPC Date</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(93) SPC Country</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(94) SPC Expired Date</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.expiredDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(95) SPC Protected by the basic patent</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.patent')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(96) Application Number</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.appNumber')"></app-config-item>
                        </div>
                        <div class="col-2">
                            <div class="app-config_item-label">(96) Application Kind</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.appKind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(96) Application Date</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.appDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(96) Application Country</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.appCountry')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(97) Publication Number</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.pubNumber')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(97) Publication Kind</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.pubKind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(97) Publication Date</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.pubDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">(97) Publication Country</div>
                            <app-config-item [configuration]="configForm.get('europeanPatents.pubCountry')"></app-config-item>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(56) Patent Citation Details
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('patentCitations.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">CC</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.authority')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Number</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Kind</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.kind')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Date</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('patentCitations.applicant')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('patentCitations.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Name</div>
                                <app-config-item [configuration]="configForm.get('patentCitations.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(56) Non Patent Citation Details
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('nonPatentCitations.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('nonPatentCitations.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Text</div>
                            <app-config-item [configuration]="configForm.get('nonPatentCitations.text')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">XpNumber</div>
                            <app-config-item [configuration]="configForm.get('nonPatentCitations.xpNumber')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                        <div class="translation">Next Language</div>
                        <div class="row">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('nonPatentCitations.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Text</div>
                                <app-config-item [configuration]="configForm.get('nonPatentCitations.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h4 class="app-config_section-header">(6x) Related Publication Information
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('related.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Number</div>
                            <app-config-item [configuration]="configForm.get('related.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Kind</div>
                            <app-config-item [configuration]="configForm.get('related.type')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('related.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Date</div>
                            <app-config-item [configuration]="configForm.get('related.date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">INID code</div>
                            <app-config-item [configuration]="configForm.get('related.source')"></app-config-item>
                        </div>
                    </div>
                </section>
            </accordion-group>
            <accordion-group #group2 [isOpen]="true">
                <div accordion-heading>
                    <h3>Legal Events Status Fields</h3>
                    <em class="float-right" [ngClass]="{'dx-icon-spindown': !group2.isOpen, 'dx-icon-spinup': group2.isOpen}"></em>
                </div>
                <section>
                    <div class="row">
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Patent number</div>
                            <app-config-item [configuration]="configForm.get('legalEvent.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Event date</div>
                            <app-config-item [configuration]="configForm.get('legalEvent.date')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('legalEvent.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Note</div>
                            <app-config-item [configuration]="configForm.get('legalEvent.note')"></app-config-item>
                        </div>
                        <div *ngIf="configForm.get('legalEvent.note.visible').value" class="app-config_inner_item col-4">
                            <div class="app-config_item-label">Text</div>
                            <textarea type="text" [formControl]="configForm.get('instructions')"></textarea>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="row">
                        <div class="translation col-12">Next Language</div>
                        <div class="row col-12">
                            <div class="app-config_inner_item col-4">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('legalEvent.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Note</div>
                                <app-config-item [configuration]="configForm.get('legalEvent.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
            </accordion-group>
            <accordion-group #group3 [isOpen]="true">
                <div accordion-heading>
                    <h3>License Information</h3>
                    <em class="float-right" [ngClass]="{'dx-icon-spindown': !group3.isOpen, 'dx-icon-spinup': group3.isOpen}"></em>
                </div>
                <section>
                    <h4 class="app-config_section-header h4-noborder">  
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('license.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <h4 class="col-12 h4-noborder">Licensor Information</h4>
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('license.licensor.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('license.licensor.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('license.licensor.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('license.licensor.address')"></app-config-item>
                        </div>
                        <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                            <div class="translation">Next Language</div>
                            <div class="row">
                                <div class="app-config_inner_item col-4">
                                    <div class="app-config_item-label">Language</div>
                                    <app-config-item [configuration]="configForm.get('license.licensor.translations.language')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Name</div>
                                    <app-config-item [configuration]="configForm.get('license.licensor.translations.trName')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Address</div>
                                    <app-config-item [configuration]="configForm.get('license.licensor.translations.trAddress')"></app-config-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-12 h4-noborder">Licensee Information</h4>
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('license.licensee.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('license.licensee.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('license.licensee.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('license.licensee.address')"></app-config-item>
                        </div>
                        <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                            <div class="translation">Next Language</div>
                            <div class="row">
                                <div class="app-config_inner_item col-4">
                                    <div class="app-config_item-label">Language</div>
                                    <app-config-item [configuration]="configForm.get('license.licensee.translations.language')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Name</div>
                                    <app-config-item [configuration]="configForm.get('license.licensee.translations.trName')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Address</div>
                                    <app-config-item [configuration]="configForm.get('license.licensee.translations.trAddress')"></app-config-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Registration number</div>
                            <app-config-item [configuration]="configForm.get('license.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Period of validity</div>
                            <app-config-item [configuration]="configForm.get('license.period')"></app-config-item>
                        </div>
                    </div>
                </section>
            </accordion-group>
            <accordion-group #group4 [isOpen]="true">
                <div accordion-heading>
                    <h3>Pledge Information</h3>
                    <em class="float-right" [ngClass]="{'dx-icon-spindown': !group3.isOpen, 'dx-icon-spinup': group3.isOpen}"></em>
                </div>
                <section>
                    <h4 class="app-config_section-header h4-noborder">  
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('pledge.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <h4 class="col-12 h4-noborder">Pledgor Information</h4>
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgor.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgor.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgor.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgor.address')"></app-config-item>
                        </div>
                        <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                            <div class="translation">Next Language</div>
                            <div class="row">
                                <div class="app-config_inner_item col-4">
                                    <div class="app-config_item-label">Language</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgor.translations.language')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Name</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgor.translations.trName')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Address</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgor.translations.trAddress')"></app-config-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-12 h4-noborder">Pledgee Information</h4>
                        <div class="col-2">
                            <div class="app-config_item-label">Name</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgee.name')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Country</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgee.country')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgee.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Address</div>
                            <app-config-item [configuration]="configForm.get('pledge.pledgee.address')"></app-config-item>
                        </div>
                        <div style="border: dashed 3px #007700; border-radius: 10px; " class="col-12">
                            <div class="translation">Next Language</div>
                            <div class="row">
                                <div class="app-config_inner_item col-4">
                                    <div class="app-config_item-label">Language</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgee.translations.language')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Name</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgee.translations.trName')"></app-config-item>
                                </div>
                                <div class="app-config_inner_item col-2">
                                    <div class="app-config_item-label">Address</div>
                                    <app-config-item [configuration]="configForm.get('pledge.pledgee.translations.trAddress')"></app-config-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <div class="app-config_item-label">Effective Date</div>
                            <app-config-item [configuration]="configForm.get('pledge.effectiveDate')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Expiration Date</div>
                            <app-config-item [configuration]="configForm.get('pledge.expirationDate')"></app-config-item>
                        </div>
                    </div>
                </section>
            </accordion-group>
            <accordion-group #group5 [isOpen]="true">
                <div accordion-heading>
                    <h3>Claims Fields</h3>
                    <em class="float-right" [ngClass]="{'dx-icon-spindown': !group4.isOpen, 'dx-icon-spinup': group4.isOpen}"></em>
                </div>
                <section>
                    <h4 class="app-config_section-header h4-noborder">  
                        <label>New
                            <input type="checkbox" [formControl]="configForm.get('claims.new')">
                        </label>
                    </h4>
                    <div class="row">
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Language</div>
                            <app-config-item [configuration]="configForm.get('claims.language')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Number</div>
                            <app-config-item [configuration]="configForm.get('claims.number')"></app-config-item>
                        </div>
                        <div class="app-config_inner_item col-2">
                            <div class="app-config_item-label">Text</div>
                            <app-config-item [configuration]="configForm.get('claims.text')"></app-config-item>
                        </div>
                    </div>
                    <div style="border: dashed 3px #007700; border-radius: 10px; " class="row">
                        <div class="translation col-12">Next Language</div>
                        <div class="row col-12">
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Language</div>
                                <app-config-item [configuration]="configForm.get('claims.translations.language')"></app-config-item>
                            </div>
                            <div class="app-config_inner_item col-2">
                                <div class="app-config_item-label">Text</div>
                                <app-config-item [configuration]="configForm.get('claims.translations.tr')"></app-config-item>
                            </div>
                        </div>
                    </div>
                </section>
            </accordion-group>
        </accordion>
    </form>
    <div class="container" style="margin: 20px auto; ">
        <button (click)="save()" class="btn btn-primary align-bottom">Submit</button>
        <button (click)="disSelectAll()" class="btn btn-primary align-bottom" style="margin-left: 30px;">Deselect</button>
    </div>
</div>