import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { AccountEditComponent } from '../account-edit/account-edit.component';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss']
})
export class AccountManagerComponent {
  accountManagersDataSource: any = {};
  @ViewChild(DxDataGridComponent, { static: true }) dxGrid: DxDataGridComponent;
  @ViewChild(AccountEditComponent, { static: true }) accountEdit: AccountEditComponent;
  constructor(private accountService: AccountService) {
    this.accountManagersDataSource.store = new CustomStore({
      load: async (loadOptions: any) => {
        let params = '?';
        params += 'skip=' + (loadOptions.skip || 0);
        params += '&take=' + (loadOptions.take || 12);
        params += loadOptions.filter ? '&filter=' + JSON.stringify(loadOptions.filter) : '';
        params += loadOptions.sort ? '&sort=' + JSON.stringify(loadOptions.sort) : '';
        params += '&requireTotalCount=true';
        return this.accountService.getAllAccounts(params).toPromise();
      }
    });
  }

  edit() {
    const selRows = this.dxGrid.instance.getSelectedRowsData();
    if (selRows[0]) {
      this.accountEdit.show(selRows[0]);
    } else {
      alert('Please select the user account to edit!');
    }
  }

  refresh($event) {
    if ($event) {
      this.dxGrid.instance.refresh();
    }
  }
}
