<div class="app-delivery-report">
  <dx-popup container=".app-delivery-report" [width]="500" [height]="300" [showTitle]="true" [dragEnabled]="false"
    [closeOnOutsideClick]="true" [visible]="popupVisible" (onHidden)="Cancel($event)">
    <div *dxTemplate="let data of 'title'">
      <h3>Delivery Report</h3>
    </div>
    <div id="selection" *dxTemplate="let data of 'content'" class="popup-template">
      <dx-form [formData]="status" [colCount]=2 validationGroup="deliveryGroup">
        <dxi-item dataField="start" editorType="dxDateBox" [editorOptions]="{dateSerializationFormat: 'yyyy-MM-dd'}">
          <dxi-validation-rule type="required" message="Required filed"></dxi-validation-rule>
          <dxo-label text="Start"></dxo-label>
        </dxi-item>
        <dxi-item dataField="end" editorType="dxDateBox" [editorOptions]="{dateSerializationFormat: 'yyyy-MM-dd'}">
          <dxi-validation-rule type="required" message="Required filed"></dxi-validation-rule>
          <dxo-label text="End"></dxo-label>
        </dxi-item>
      </dx-form>
      <div class="popup-bottom-toolbar">
        <dx-button class="btn btn-primary" icon="save" text="Export" validationGroup="deliveryGroup"
          (onClick)="export($event)"></dx-button>
        <dx-button class="btn btn-primary" icon="cancel" text="Cancel" (onClick)="Cancel($event)"></dx-button>
      </div>
      <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#selection' }"
        [(visible)]="loadingVisible" [showIndicator]="true" [shading]="true" [closeOnOutsideClick]="false">
      </dx-load-panel>
    </div>

  </dx-popup>
</div>