
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
import { Config } from '../shared/models/iconfig.model';
import { PatentId } from '../shared/models/patent-id';
import { PdfModel } from '../shared/models/pdf-model';
import { DataService } from '../shared/services/data.service';

@Injectable()
export class PatentService {
  api_base_url = environment.api_base_url;
  reportUrl = environment.reportUrl;
  config = new Subject<Config>();
  config$ = this.config.asObservable();
  pdfItem = new Subject<PdfModel>();
  pdfItem$ = this.pdfItem.asObservable();
  patent = new Subject<any>();
  patent$ = this.patent.asObservable();
  existCheck = new Subject<PatentId>();
  existCheck$ = this.existCheck.asObservable();
  ws: WebSocketSubject<any>;
  constructor(private service: DataService) {
    this.ws = webSocket({
      url: `${environment.ws_conn_str}/ws/configcheck`,
      closeObserver: {
        next: (close) => {
           this.wsOpen();
        }
      },
    });
    this.wsOpen();

  }

  private wsOpen() {
    return this.ws.pipe(delay(1000)).subscribe(
      value => {
        if (value && value.message) {
          notify(value.message, 'success', 2000);
        }
      },
      err => {
      }
    );
  }

  configCheck(data: any) {
    this.ws.next(data);
  }

  getPdfs(data: string): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patentpdf/pdfs' + data);
  }


  getPdf(key: number): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patentpdf/' + key);
  }


  getCategories(): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patent/GetCategories');
  }


  getPatentAndConfig(key: number): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patent/patentandconfig/' + key);
  }

  getProcessedPatents(key: number): Observable<Array<any>> {
    return this.service.get(this.api_base_url + 'api/patentpdf/patents/' + key);
  }

  pdfAssign(data: any): Observable<any> {
    return this.service.post(this.api_base_url + 'api/patentpdf/Get', data);
  }

  getConfigs(data: string): Observable<any> {
    return this.service.get(this.api_base_url + 'api/config/all' + data);
  }

  getValidations(data: string): Observable<any> {
    return this.service.get(this.api_base_url + 'api/checkdetails/all' + data);
  }


  getAllCountryCodes(): Observable<any> {
    return this.service.get(this.api_base_url + 'api/config/allcountrycodes');
  }

  getConfig(key: number): Observable<any> {
    return this.service.get(this.api_base_url + 'api/config/' + key);
  }

  getSubCodes(countryCode: string): Observable<Array<any>> {
    return this.service.get(this.api_base_url + 'api/config/subcodes/' + countryCode);
  }

  getBiblio(key: number): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patent/' + key);
  }

  deleteBiblio(key: number): Observable<any> {
    return this.service.delete(this.api_base_url + 'api/patent/' + key);
  }

  deleteValidate(key: number): Observable<any> {
    return this.service.delete(this.api_base_url + 'api/checkdetails/' + key);
  }

  deleteConfiguration(key: number): Observable<any> {
    return this.service.delete(this.api_base_url + 'api/config/' + key);
  }

  getTranslate(lang: string, org: string): Observable<any> {
    return this.service.get(this.api_base_url + 'api/name/get?lang=' + lang + '&org=' + org)
  }

  deletePdf(key: number): Observable<any> {
    return this.service.delete(this.api_base_url + 'api/patentpdf/' + key);
  }

  postBibilio(item): Observable<boolean> {
    return this.service.post(this.api_base_url + 'api/patent/createOrUpdate', item);
  }


  search(item): Observable<Array<any>> {
    return this.service.post(this.api_base_url + 'api/patent/search', item);
  }

  postConfig(item): Observable<boolean> {
    return this.service.post(this.api_base_url + 'api/config/createOrUpdate', item);
  }

  postPdf(data: FormData): Observable<boolean> {
    return this.service.post(this.api_base_url + 'api/patentpdf/upload', data);
  }

  pdfStatus(): Observable<any> {
    return this.service.get(this.api_base_url + 'api/patentpdf/status');
  }
}
