export interface IConfig {
  id?: number;
  country?: string;
  subCode?: string;
  wipoCompliant?: boolean;
  kind?: number;
  legalEventCategory?: number;
  st17Code?: string;
  sectionHeaderOrg?: string;
  sectionHeaderEng?: string;
  instructions?: string;
  legalEvent: LegalEventConfig;
  license: LicenseConfig;
  pledge: PledgeConfig;
  description?: string;
  publication: PublicationConfig;
  application: ApplicationConfig;
  priorities: PriorityConfig;
  dOfPublication: DOfPublicationConfig;
  titles: TitleConfig;
  abstracts: AbstractConfig;
  applicants: PartyMemberConfig;
  inventors: PartyMemberConfig;
  assignees: PartyMemberConfig;
  invOrApps: PartyMemberConfig;
  invAppGrants: PartyMemberConfig;
  agents: PartyMemberConfig;
  ipcs: IpcConfig;
  ipcrs: IpcrConfig;
  cpcs: CpcConfig;
  intConvention: IntConventionConfig;
  // designatedStates: Configuration;
  patentCitations: PatentCitationConfig;
  nonPatentCitations: NonPatentCitationConfig;
  related: RelatedDocumentConfig;
  europeanPatents: EuropeanPatentConfig;
  claims: ClaimConfig;
  visible(stable: boolean): boolean;
}
export class Configuration {
  required: boolean;
  visible: boolean;
  original: boolean;
  default: any;
  pattern: string;

  constructor() {
    this.required = false;
    this.original = false;
    this.visible = false;
    this.default = null;
    this.pattern = null;
  }
}


export class BaseConfig {
  new = false;
  visible(stable: boolean): boolean {
    const result = Object.keys(this).find((key) => key !== 'new' && key !== 'translations' &&
      (
        (stable && this[key].visible) ||
        (this['new'] && this[key].visible)
      )
      , this);

    return result ? true : false;
  }

  required(stable: boolean): boolean {
    const result = Object.keys(this).find((key) => key !== 'new' && key !== 'translations' &&
      (
        (stable && this[key].required) ||
        (this['new'] && this[key].required)
      )
      , this);

    return result ? true : false;
  }

  get ConfigName(): string {
    return '';
  }
}

export class TransBaseConfig {
  required() : boolean {
    return Object.keys(this).find(key => this[key].required, this) ? true: false;
  }
}

export class LicenseConfig extends BaseConfig {
  number: Configuration;
  period: Configuration;
  licensor: LicenseMemberConfig;
  licensee: LicenseMemberConfig;

  constructor() {
    super()
    this.number = new Configuration;
    this.period = new Configuration;
    this.licensee = new LicenseMemberConfig;
    this.licensor = new LicenseMemberConfig;
  }

  get ConfigName(): string {
    return 'license';
  }

  visible(stable: boolean): boolean {
    this.licensee.new = this.new;
    this.licensor.new = this.new;
    return stable ? (this.period.visible || this.number.visible || this.licensee.visible(stable) || this.licensor.visible(stable)) :
      this.new && (this.period.visible || this.number.visible || this.licensee.visible(stable) || this.licensor.visible(stable));
  }
}

export class PledgeConfig extends BaseConfig {
  effectiveDate : Configuration;
  expirationDate: Configuration;
  pledgor: LicenseMemberConfig;
  pledgee: LicenseMemberConfig;

  constructor() {
    super()
    this.effectiveDate = new Configuration;
    this.expirationDate = new Configuration;
    this.pledgee = new LicenseMemberConfig;
    this.pledgor = new LicenseMemberConfig;
  }

  get ConfigName(): string {
    return 'pledge';
  }

  visible(stable: boolean): boolean {
    this.pledgee.new = this.new;
    this.pledgor.new = this.new;
    return stable ? (this.effectiveDate.visible || this.expirationDate.visible || this.pledgee.visible(stable) || this.pledgor.visible(stable)) :
      this.new && (this.effectiveDate.visible || this.expirationDate.visible || this.pledgee.visible(stable) || this.pledgor.visible(stable));
  }
}

export class LicenseMemberTranslationConfig extends TransBaseConfig {
  language: Configuration
  trName: Configuration;
  trAddress: Configuration;

  constructor(){
    super();
    this.language = new Configuration();
    this.trName = new Configuration();
    this.trAddress = new Configuration();
  }
}

export class LicenseMemberConfig extends BaseConfig {
  name: Configuration;
  address: Configuration;
  country: Configuration;
  language: Configuration;
  translations: LicenseMemberTranslationConfig;


  get ConfigName(): string {
    return '';
  }


  constructor() {
    super();
    this.name = new Configuration();
    this.address = new Configuration();
    this.country = new Configuration();
    this.language = new Configuration();
    this.translations = new LicenseMemberTranslationConfig()
  }
}

export class LegalEventConfig extends BaseConfig {
  number: Configuration;
  date: Configuration;
  note: Configuration;
  language: Configuration;
  translations: TranslationConfig;

  constructor() {
    super();
    this.number = new Configuration;
    this.date = new Configuration;
    this.note = new Configuration;
    this.language = new Configuration;
    this.translations = new TranslationConfig;
  }

  get ConfigName(): string {
    return 'legalEvent';
  }

}

export class DOfPublicationConfig extends BaseConfig {
  date_41: Configuration;
  date_42: Configuration;
  date_44: Configuration;
  date_45: Configuration;
  date_46: Configuration;
  date_47: Configuration;
  date_48: Configuration;

  constructor() {
    super();
    this.date_41 = new Configuration;
    this.date_42 = new Configuration;
    this.date_44 = new Configuration;
    this.date_45 = new Configuration;
    this.date_46 = new Configuration;
    this.date_47 = new Configuration;
    this.date_48 = new Configuration;
  }
}

export class TranslationConfig extends TransBaseConfig {
  language: Configuration;
  tr: Configuration;

  constructor() {
    super();
    this.language = new Configuration();
    this.tr = new Configuration();
  }
}

export class TitleConfig extends BaseConfig {
  language: Configuration;
  text: Configuration;
  translations: TranslationConfig;
  constructor() {
    super();
    this.language = new Configuration;
    this.text = new Configuration;
    this.translations = new TranslationConfig;
  }

  get ConfigName(): string {
    return 'titles';
  }
}
export class PublicationConfig extends BaseConfig {
  authority: Configuration;
  number: Configuration;
  kind: Configuration;
  date: Configuration;
  languageDesignation: Configuration;
  constructor() {
    super();
    this.authority = new Configuration();
    this.number = new Configuration();
    this.kind = new Configuration();
    this.date = new Configuration();
    this.languageDesignation = new Configuration();
  }

  visible(stable: boolean): boolean {
    return stable ? (this.authority.visible || this.number.visible || this.kind.visible || this.languageDesignation.visible) :
      this.new && (this.authority.visible || this.number.visible || this.kind.visible || this.languageDesignation.visible);
  }
}
export class ApplicationConfig extends BaseConfig {
  number: Configuration;
  date: Configuration;
  otherDate: Configuration;
  language: Configuration;
  effectiveDate: Configuration;
  constructor() {
    super();
    this.number = new Configuration();
    this.language = new Configuration();
    this.otherDate = new Configuration();
    this.date = new Configuration();
    this.effectiveDate = new Configuration();
  }
}
export class PriorityConfig extends BaseConfig {
  country: Configuration;
  number: Configuration;
  date: Configuration;
  constructor() {
    super();
    this.number = new Configuration();
    this.country = new Configuration();
    this.date = new Configuration();
  }

  get ConfigName(): string {
    return 'priorities';
  }
}
export class AbstractConfig extends BaseConfig {
  language: Configuration;
  text: Configuration;
  translations: TranslationConfig;

  constructor() {
    super();
    this.language = new Configuration();
    this.text = new Configuration();
    this.translations = new TranslationConfig;
  }
}

export class ClaimConfig extends BaseConfig {
  language: Configuration;
  number: Configuration;
  text: Configuration;
  translations: TranslationConfig;

  constructor() {
    super();
    this.language = new Configuration();
    this.number = new Configuration();
    this.text = new Configuration();
    this.translations = new TranslationConfig;
  }
}

export class PartyTranslationConfig extends TransBaseConfig {
    language: Configuration
    trName: Configuration;
    trAddress1: Configuration;

    constructor() {
      super();
      this.language = new Configuration();
      this.trName = new Configuration();
      this.trAddress1 = new Configuration();
    }
}

export class PartyMemberConfig extends BaseConfig {
  language: Configuration;
  name: Configuration;
  address1: Configuration;
  postCode: Configuration;
  city: Configuration;
  country: Configuration;
  translations: PartyTranslationConfig

  constructor() {
    super();
    this.language = new Configuration();
    this.name = new Configuration();
    this.language = new Configuration();
    this.address1 = new Configuration();
    this.postCode = new Configuration();
    this.city = new Configuration();
    this.country = new Configuration();
    this.translations = new PartyTranslationConfig();
  }
}
export class IpcConfig extends BaseConfig {
  edition: Configuration;
  class: Configuration;
  date: Configuration;
  ipcDate: Configuration;
  domestic: Configuration;

  constructor() {
    super();
    this.edition = new Configuration();
    this.class = new Configuration();
    this.date = new Configuration();
    this.ipcDate = new Configuration();
    this.domestic = new Configuration();
  }

  get ConfigName(): string {
    return 'ipcs';
  }
}
export class IpcrConfig extends BaseConfig {
  classRaw: Configuration;
  section: Configuration;
  subclass: Configuration;
  maingroup: Configuration;
  subgroup: Configuration;
  classscheme: Configuration;
  classValue: Configuration;
  actionDate: Configuration;
  classStatus: Configuration;
  classDataSource: Configuration;
  office: Configuration;

  constructor() {
    super();
    this.classRaw = new Configuration();
    this.section = new Configuration();
    this.subclass = new Configuration();
    this.maingroup = new Configuration();
    this.subgroup = new Configuration();
    this.classscheme = new Configuration();
    this.classValue = new Configuration();
    this.actionDate = new Configuration();
    this.classStatus = new Configuration();
    this.classDataSource = new Configuration();
    this.office = new Configuration();
  }
}
export class CpcConfig extends BaseConfig {
  group: Configuration;
  rank: Configuration;
  office: Configuration;
  schema: Configuration;
  schemaDate: Configuration;
  class: Configuration;
  position: Configuration;
  value: Configuration;
  status: Configuration;
  source: Configuration;
  generatingOffice: Configuration;
  actionDate: Configuration;
  constructor() {
    super();
    this.group = new Configuration();
    this.rank = new Configuration();
    this.office = new Configuration();
    this.schema = new Configuration();
    this.schemaDate = new Configuration();
    this.class = new Configuration();
    this.position = new Configuration();
    this.value = new Configuration();
    this.status = new Configuration();
    this.source = new Configuration();
    this.generatingOffice = new Configuration();
    this.actionDate = new Configuration();

  }
}

export class IntConventionConfig extends BaseConfig {
  pctNationalDate: Configuration;
  pctApplNumber: Configuration;
  pctApplKind: Configuration;
  pctApplDate: Configuration;
  pctApplCountry: Configuration;
  pctPublNumber: Configuration;
  pctPublKind: Configuration;
  pctPublDate: Configuration;
  pctPublCountry: Configuration;
  pctSearchDate: Configuration;
  designatedStates: DesignatedStateConfig;

  constructor() {
    super();
    this.pctNationalDate = new Configuration();
    this.pctApplNumber = new Configuration();
    this.pctApplKind = new Configuration();
    this.pctApplDate = new Configuration();
    this.pctPublNumber = new Configuration();
    this.pctPublKind = new Configuration();
    this.pctPublDate = new Configuration();
    this.pctApplCountry = new Configuration();
    this.pctPublCountry = new Configuration();
    this.pctSearchDate = new Configuration();
    this.designatedStates = new DesignatedStateConfig();
  }

  visible(stable: boolean) {
    return stable ? (this.pctNationalDate.visible || this.pctApplNumber.visible || this.pctApplKind.visible || this.pctApplDate.visible || this.pctPublNumber.visible || this.pctPublKind.visible || this.pctPublDate.visible || this.pctApplCountry.visible || this.pctPublCountry.visible || this.pctSearchDate.visible ||
      this.designatedStates.state.visible) :
      this.new && (this.pctNationalDate.visible || this.pctApplNumber.visible || this.pctApplKind.visible || this.pctApplDate.visible || this.pctPublNumber.visible || this.pctPublKind.visible || this.pctPublDate.visible || this.pctApplCountry.visible || this.pctPublCountry.visible || this.pctSearchDate.visible ||
        this.designatedStates.state.visible);
  }
}

export class DesignatedStateConfig extends BaseConfig {
  state: Configuration;
  constructor() {
    super();
    this.state = new Configuration();
  }
}

export class PatentCitationConfig extends BaseConfig {
  authority: Configuration;
  number: Configuration;
  kind: Configuration;
  date: Configuration;
  applicant: Configuration;
  language: Configuration;
  translations: TranslationConfig;

  constructor() {
    super();
    this.authority = new Configuration();
    this.number = new Configuration();
    this.kind = new Configuration();
    this.date = new Configuration();
    this.applicant = new Configuration();
    this.language = new Configuration;
    this.translations = new TranslationConfig;
  }
}

export class NonPatentCitationConfig extends BaseConfig {
  text: Configuration;
  xpNumber: Configuration;
  language: Configuration;

  translations: TranslationConfig;

  constructor() {
    super();
    this.text = new Configuration();
    this.language = new Configuration;
    this.xpNumber = new Configuration();
    this.translations = new TranslationConfig;
  }
}

export class RelatedDocumentConfig extends BaseConfig {
  source: Configuration;
  type: Configuration;
  number: Configuration;
  date: Configuration;
  country: Configuration;

  constructor() {
    super();
    this.source = new Configuration();
    this.type = new Configuration();
    this.number = new Configuration();
    this.date = new Configuration();
    this.country = new Configuration();
  }
}

export class EuropeanPatentConfig extends BaseConfig {
  spc92Number: Configuration;
  spc92Date: Configuration;
  spc92Country: Configuration;
  number: Configuration;
  date: Configuration;
  country: Configuration;
  expiredDate: Configuration;
  patent: Configuration;
  appNumber: Configuration;
  appKind: Configuration;
  appDate: Configuration;
  appCountry: Configuration;
  pubNumber: Configuration;
  pubKind: Configuration;
  pubDate: Configuration;
  pubCountry: Configuration;

  constructor() {
    super();
    this.spc92Number = new Configuration();
    this.spc92Date = new Configuration();
    this.spc92Country = new Configuration();
    this.number = new Configuration();
    this.date = new Configuration();
    this.country = new Configuration();
    this.expiredDate = new Configuration();
    this.patent = new Configuration();
    this.appNumber = new Configuration();
    this.appKind = new Configuration();
    this.appDate = new Configuration();
    this.pubNumber = new Configuration();
    this.pubKind = new Configuration();
    this.pubDate = new Configuration();
    this.appCountry = new Configuration();
    this.pubCountry = new Configuration();
  }

  get ConfigName(): string {
    return 'europeanPatents';
  }
}

export class Config implements IConfig {
  id?: number = null;
  private _stable: boolean;
  legalEventCategory?: number = null;
  st17Code?= '';
  sectionHeaderOrg?= '';
  sectionHeaderEng?= '';
  instructions?= '';
  kind?: number;
  wipoCompliant?: boolean;
  legalEvent: LegalEventConfig;
  license: LicenseConfig;
  pledge: PledgeConfig;
  dOfPublication: DOfPublicationConfig;
  assignees: PartyMemberConfig;
  invOrApps: PartyMemberConfig;
  invAppGrants: PartyMemberConfig;
  europeanPatents: EuropeanPatentConfig;
  country?= '';
  subCode?= '';
  description?= '';
  publication: PublicationConfig;
  application: ApplicationConfig;
  priorities: PriorityConfig;
  titles: TitleConfig;
  abstracts: AbstractConfig;
  applicants: PartyMemberConfig;
  inventors: PartyMemberConfig;
  agents: PartyMemberConfig;
  ipcs: IpcConfig;
  ipcrs: IpcrConfig;
  cpcs: CpcConfig;
  intConvention: IntConventionConfig;
  // designatedStates: Configuration;
  patentCitations: PatentCitationConfig;
  nonPatentCitations: NonPatentCitationConfig;
  related: RelatedDocumentConfig;
  claims: ClaimConfig;

  constructor() {
    this.country = '';
    this.kind = null;
    this.wipoCompliant = null;
    this.publication = new PublicationConfig();
    this.legalEvent = new LegalEventConfig();
    this.application = new ApplicationConfig();
    this.priorities = new PriorityConfig();
    this.dOfPublication = new DOfPublicationConfig();
    this.titles = new TitleConfig();
    this.abstracts = new AbstractConfig();
    this.applicants = new PartyMemberConfig();
    this.inventors = new PartyMemberConfig();
    this.agents = new PartyMemberConfig();
    this.ipcs = new IpcConfig();
    this.ipcrs = new IpcrConfig();
    this.intConvention = new IntConventionConfig();
    // this.designatedStates = new Configuration();
    this.patentCitations = new PatentCitationConfig();
    this.nonPatentCitations = new NonPatentCitationConfig();
    this.related = new RelatedDocumentConfig();
    this.europeanPatents = new EuropeanPatentConfig();
    this.assignees = new PartyMemberConfig();
    this.invOrApps = new PartyMemberConfig();
    this.invAppGrants = new PartyMemberConfig();
    this.license = new LicenseConfig();
    this.pledge = new PledgeConfig();
    this.claims = new ClaimConfig();
  }

  visible(stable: boolean): boolean {
    return stable ? true :
      this.publication.visible(stable) ||
      this.legalEvent.visible(stable) ||
      this.application.visible(stable) ||
      this.priorities.visible(stable) ||
      this.dOfPublication.visible(stable) ||
      this.titles.visible(stable) ||
      this.abstracts.visible(stable) ||
      this.applicants.visible(stable) ||
      this.inventors.visible(stable) ||
      this.agents.visible(stable) ||
      this.ipcs.visible(stable) ||
      this.intConvention.visible(stable) ||
      this.patentCitations.visible(stable) ||
      this.nonPatentCitations.visible(stable) ||
      this.related.visible(stable) ||
      this.europeanPatents.visible(stable) ||
      this.assignees.visible(stable) ||
      this.invOrApps.visible(stable) ||
      this.invAppGrants.visible(stable) ||
      this.license.visible(stable) ||
      this.pledge.visible(stable) ||
      this.claims.visible(stable);
  }
}
