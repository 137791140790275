import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Priority } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-priorities',
  templateUrl: './biblio-priorities.component.html',
  styleUrls: ['./biblio-priorities.component.scss']
})
export class BiblioPrioritiesComponent extends BiblioFormArrayBaseComponent {
  constructor(protected formBuilder: FormBuilder, protected patentService: PatentService) {
    super(formBuilder);
  }
  setFormArray(data: Priority[]) {
    const itemFGs = data.map((item, index) => this.formBuilder.group({
      country: item.country,
      number: item.number,
      date: item.date,
    }));
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      country: this.config.country.default || '',
      number: '',
      date: '',
    });
    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }

  get Value(): any {
    return this.formArray.value.map((item, i) => Object.assign(this.formatObject(item), { sequence: i + 1 }));
  }
}
