import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PatentCitation, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-patent-citations',
  templateUrl: './biblio-patent-citations.component.html',
  styleUrls: ['./biblio-patent-citations.component.scss']
})
export class BiblioPatentCitationsComponent extends BiblioFormArrayBaseComponent {
  constructor(protected formBuilder: FormBuilder, protected patentService: PatentService) {
    super(formBuilder);
  }
  setFormArray(data: PatentCitation[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        authority: item.authority,
        number: item.number,
        kind: item.kind,
        date: item.date,
        language: item.language,
        applicant: item.applicant,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      authority: '',
      number: '',
      kind: '',
      date: '',
      language: this.config.language.default || '',
      applicant: '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }


  setTranslations(itemG: FormGroup, data: Translation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required()) {
      data = [<Translation>{ type: this.nameType, language: this.config.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      tr: '',
    });

    item.get('applicant').valueChanges.subscribe(value => this.getTranslate(translate.get('tr'), translate.get('language').value, value))
    this.getTranslate(translate.get('tr'), translate.get('language').value, item.get('applicant').value)
    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }


  get Value(): any {
    return this.formArray.value.map((item, i) => Object.assign(this.formatObject(item), { sequence: i + 1 }));
  }

  get TranslationsValue(): any {
    const values: Translation[] = [];
    this.formArray.value.forEach(item => {
      item.translations.forEach(tr => {
        if (tr.tr && tr.tr != '' && tr.tr.trim().length > 0) {
          values.push(<Translation>{
            type: tr.type,
            language: tr.language,
            org: item.applicant,
            tr: tr.tr
          });
        }
      });
    });
    return values;
  }
}
