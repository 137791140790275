import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RelatedDocument } from '../../../shared/models/ibiblio.model';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-related',
  templateUrl: './biblio-related.component.html',
  styleUrls: ['./biblio-related.component.scss']
})
export class BiblioRelatedComponent extends BiblioFormArrayBaseComponent {

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }
  setFormArray(data: RelatedDocument[]) {
    const itemFGs = data.map((item, index) => this.formBuilder.group({
      date: item.date,
      number: item.number,
      country: item.country,
      source: item.source,
      type: item.type,
    }));
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      date: '',
      number: '',
      source: '',
      type: '',
      country: this.config.country.default
    });
    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }
}
