import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Abstract, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-abstracts',
  templateUrl: './biblio-abstracts.component.html',
  styleUrls: ['./biblio-abstracts.component.scss']
})
export class BiblioAbstractsComponent extends BiblioFormArrayBaseComponent {
  _screenShots: any[] = [];
  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super(formBuilder);
  }
  setFormArray(data: Abstract[]) {
    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        language: item.language,
        text: item.text,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      language: this.config.language.default || '',
      text: '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }

  setTranslations(itemG: FormGroup, data: Translation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required())
    {
      data = [<Translation>{type: this.nameType, language: this.config.translations.language.default }];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      tr: '',
    });

    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');

  }

  deleteimg(uid: string) {
    this.formArray.controls.forEach(element => {
      const value = element.get('text').value;
      element.get('text').setValue(value.replace('<img id="' + uid + '">', ''));
      const translations = element.get('translations') as FormArray;
      translations.controls.forEach(trelement => {
        const trvalue = trelement.get("tr").value;
        trelement.get('tr').setValue(trvalue.replace('<img id="' + uid + '">', ''));
      });
      const idx = this.screenShots.findIndex(e => e.id === uid);
      if (idx >= 0) {
        this.screenShots.splice(idx, 1);
      }
    });
  }

  private uuid() {
    return 'yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  paste(e: ClipboardEvent) {
    const _that = this;
    let clipboardData: DataTransfer, found;
    found = false;
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    clipboardData = e.clipboardData;

    return Array.prototype.forEach.call(clipboardData.types, function (type, i) {
      let file, reader;

      if (type.match(/text\/plain/) && target.id) {
        _that.setClipboardData(e.clipboardData.getData('text/plain'), target);
        e.preventDefault();
      }
      if (type.match(/image.*/) || clipboardData.items[i].type.match(/image.*/)) {
        file = clipboardData.items[i].getAsFile();
        reader = new FileReader();
        reader.onload = (evt) => {
          const uid = _that.uuid();
          _that.screenShots.push({ id: uid, data: evt.target.result });
          const img = '<img id="' + uid + '">';
          _that.setClipboardData(img, target);
        };
        reader.readAsDataURL(file);
        e.preventDefault();
      }
    });
  }

  @Input()
  set screenShots(value: any[]) {
    if (value) {
      this._screenShots = value;
    } else {
      this._screenShots = [];
    }
  }

  get screenShots() {
    return this._screenShots;
  }
}
