import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { PatentService } from '../../patent.service';
import { BiblioBaseComponent } from '../biblio-base.component';

@Component({
  selector: 'app-biblio-d-of-publication',
  templateUrl: './biblio-d-of-publication.component.html',
  styleUrls: ['./biblio-d-of-publication.component.scss']
})
export class BiblioDOfPublicationComponent extends BiblioBaseComponent implements OnInit, OnChanges {

  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      (this.formGroup as AbstractControl).patchValue(Object.assign(this.data.dOfPublication, { date: this.data.publication.date}));
    }
    if (this.data && this.config && changes.config && this.config.dOfPublication && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      this.setValidator(this.formGroup,
        Object.assign(this.config.dOfPublication, { date: this.config.publication.date }), 'dOfPublication');
    }
  }

  get visible() {
    return this.config && (
      this.config.dOfPublication.visible(this.stable) || (
        this.stable ? this.config.publication.date.visible : (
          this.config.dOfPublication.new && this.config.publication.date.visible
        )
      )
    );
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      date_41: '',
      date_42: '',
      date: '',
      date_44: '',
      date_45: '',
      date_46: '',
      date_47: '',
      date_48: ''
    });
  }

  get Valid(): boolean {
    if ((!(this.formGroup.get('date_41').valid &&
      this.formGroup.get('date_42').valid &&
      this.formGroup.get('date_44').valid &&
      this.formGroup.get('date_45').valid &&
      this.formGroup.get('date_46').valid &&
      this.formGroup.get('date_47').valid &&
      this.formGroup.get('date_48').valid) &&
      this.config.dOfPublication.visible(this.stable)) ||
      (!this.formGroup.get('date').valid &&
        (this.stable ? this.config.publication.date.visible : this.config.dOfPublication.new && this.config.publication.date.visible))) {
      alert(`Not all the fields in <b>${this.headerText}</b> have a valid value, please have a check!`, 'VALIDATION ERROR!');
      return false;
    } else {
      return true;
    }
  }
}
