import { Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { Translation } from '../../shared/models/ibiblio.model';
import { PatentService } from '../patent.service';

@Directive()
export class BiblioBaseComponent {
  @Input() data: any;
  @Input() config: any;
  @Input() stable: boolean;
  @Input() headerText: string;
  formGroup: FormGroup;
  openToggle: boolean;
  protected patentService: PatentService;
  protected formBuilder: FormBuilder;

  static validateDate(str: string, ignoreRange: boolean, minYear: number = 1450): boolean {
    const arrays = str.split(/[\.\/\-\ ]/);
    const year_str = arrays[0];
    const month_str = arrays[1];
    const date_str = arrays[2];

    return BiblioBaseComponent.validateDay(+year_str, +month_str, +date_str, ignoreRange, minYear);
  }

  static isLeapYear(year_num: number) {
    return (year_num % 4 === 0 && (year_num % 100 !== 0)) || year_num % 400 === 0;
  }

  static validateDay(year_num: number, month_num: number, day_num: number, ignoreRange: boolean, minYear: number) {
    const now = new Date();
    const input = new Date(year_num, month_num - 1, day_num);

    if (day_num < 1) {
      return false;
    } else if ((month_num === 1 || month_num === 3 || month_num === 5 || month_num === 7
      || month_num === 8 || month_num === 10 || month_num === 12) && day_num > 31) {
      return false;
    } else if ((month_num === 4 || month_num === 6 || month_num === 9 || month_num === 11)
      && day_num > 30) {
      return false;
    } else if (month_num === 2) {
      if (BiblioBaseComponent.isLeapYear(year_num) && day_num > 29) {
        return false;
      } else if (!BiblioBaseComponent.isLeapYear(year_num) && day_num > 28) {
        return false;
      }
    }

    if ((!ignoreRange && (input > now)) || (year_num < minYear)) {
      return false;
    }

    return true;
  }



  constructor() {
    // this.patentService = this.service;
    // this.formBuilder = this.fb;
    this.openToggle = false;
  }

  paste(e: ClipboardEvent) {
    const _that = this;
    let clipboardData: DataTransfer, found;
    found = false;
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    clipboardData = e.clipboardData;

    return Array.prototype.forEach.call(clipboardData.types, function (type, i) {
      if (type.match(/text\/plain/) && target.id) {
        _that.setClipboardData(e.clipboardData.getData('text/plain'), target);
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  protected setClipboardData(data?: string, target?: HTMLInputElement | HTMLTextAreaElement) {
    const ids = target.id.split('-');
    const startPos = target.selectionStart;
    const endPos = target.selectionEnd;
    data = data.replace(/^[ \t]/g, '');
    data = data.replace(/[-][\s]?(\r?\n|\r)/g, '');
    data = data.replace(/(\r?\n|\r)/g, ' ');
    data = data.replace(/[\s]{2,}/g, ' ');

    const value = target.value.substring(0, startPos) + data + target.value.substring(endPos, target.value.length);
    if (ids.length === 4) {
      const trans = (this.formGroup.controls['formArray'] as FormArray).at(Number.parseInt(ids[0])).get(ids[1]) as FormArray;
      trans.at(Number.parseInt(ids[2])).get(ids[3]).setValue(value);
    } else if (ids.length === 3) {
      (this.formGroup.get(ids[0]) as FormArray).at(Number.parseInt(ids[1])).get(ids[2]).setValue(value);
    } else if (ids.length === 2) {
      (this.formGroup.controls['formArray'] as FormArray).at(Number.parseInt(ids[0])).get(ids[1]).setValue(value);
    } else if (ids.length === 1) {
      this.formGroup.get(ids[0]).setValue(value);
    }
  }

  setValidator(control: any, config: any, parentname: string) {
    Object.keys(config).forEach(name => {
      const item = control.get(name);

      if (!item) {
        return;
      }
      item.clearValidators();
      item.markAsTouched({ onlySelf: true });
      item.updateValueAndValidity();
      const namematch = name.match(/([dD]ate)/);

      if (item.controls && Array.isArray(item.controls)) {
        item.controls.forEach(arrayitem => {
          this.setValidator(arrayitem, config[name], name);
        });
      } else if (item && namematch) {
        if (parentname === 'ipcs') {
          if (config[name].required) {
            item.setValidators([Validators.required,
            this.yearMonthValidate(2006)]);
          } else {
            item.setValidators([this.yearMonthValidate(2006)]);
          }
        } else {
          if (config[name].required) {
            item.setValidators([
              Validators.required, this.dateValidate((parentname === 'legalEvent') || (name === 'expirationDate') ||
                (parentname === 'europeanPatents' && name === 'expiredDate') || (name === 'effectiveDate'),
                parentname === 'europeanPatents' ? 1950 : 1450)
            ]); // Validators.pattern(this.dateRegex)
          } else {
            item.setValidators([this.dateValidate((parentname === 'legalEvent') || (name === 'expirationDate') ||
              (parentname === 'europeanPatents' && name === 'expiredDate') || (name === 'effectiveDate'),
              parentname === 'europeanPatents' ? 1950 : 1450)]);
          }
        }
        item.markAsTouched({ onlySelf: true });
        item.updateValueAndValidity();
      } else if (item && config[name].required && config[name].pattern) {
        item.setValidators([Validators.required, Validators.pattern(new RegExp(config[name].pattern))]);
        item.markAsTouched({ onlySelf: true });
        item.updateValueAndValidity();
      } else if (item && config[name].required) {
        item.setValidators(Validators.required);
        item.markAsTouched({ onlySelf: true });
        item.updateValueAndValidity();
      } else if (item && config[name].pattern) {
        item.setValidators(Validators.pattern(new RegExp(config[name].pattern)));
        item.markAsTouched({ onlySelf: true });
        item.updateValueAndValidity();
      } else if (item) {
        this.setValidator(item, config[name], name);
      }
    });
  }

  yearMonthValidate(minYear?: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value === null || c.value === '') {
        return null;
      }

      const regex: RegExp = /^[1-9]\d{3}[\.\/\-\ ](0[1-9]|1[0-2])[\.\/\-\ ](0[1-9]|[1-2][0-9]|3[0-1])$/;
      if (c.value.length !== 10 && c.value.length !== 7) {
        return { 'error': true };
      } else {
        let value = c.value.replace(/[\.\/\-\ ]/g, '/');
        if (value.length === 7) {
          value = value + '/01';
        }

        if (regex.test(value) && BiblioBaseComponent.validateDate(value, false, minYear)) {
          return null;
        } else {
          return { 'error': true };
        }
      }
    }
  }

  dateValidate(ignoreRange: boolean, minYear?: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value === null || c.value === '') {
        return null;
      }

      const regex: RegExp = /^[1-9]\d{3}[\.\/\-\ ](0[1-9]|1[0-2])[\.\/\-\ ](0[1-9]|[1-2][0-9]|3[0-1])$/;
      if (c.value.length !== 10) {
        return { 'error': true };
      } else {
        const value = c.value.replace(/[\.\/\-\ ]/g, '/');
        if (regex.test(value) && BiblioBaseComponent.validateDate(value, ignoreRange, minYear)) {
          return null;
        } else {
          return { 'error': true };
        }
      }
    };
  }

  get Valid(): boolean {
    if (!this.formGroup.valid && this.config.visible(this.stable)) {
      alert(`Not all the fields in <b>${this.headerText}</b> have a valid value, please have a check!`, 'VALIDATION ERROR!');
      return false;
    } else {
      return true;
    }
  }

  get Value(): any {
    return this.formatObject(this.formGroup.value);
  }

  formatObject(simpleValue: any): any {
    Object.keys(simpleValue).forEach(key => {
      if (key.match(/[dD]ate/)) {
        simpleValue[key] = this.dateFormat(simpleValue[key]);
      }
    });
    return simpleValue;
  }

  private dateFormat(value: string): string {
    if (value === null) {
      return null;
    }
    if (value.length === 10) {
      return value ? value.replace(/[\.\/\-\ ]/g, '/') : null;
    }
    if (value.length === 7) {
      return value.replace(/[\.\/\-\ ]/g, '/') + '/01';
    }
    return null;
  }

  getTranslate(trControl: AbstractControl, lang: string, value: string) {
    if (lang && value) {
      this.patentService.getTranslate(lang, value).subscribe((data: Translation) => {
        if (data && data.tr) {
          trControl.setValue(data.tr)
        }
      })
    }
    // trControl.setValue('456');
  }

}
