<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.spc92Number.visible">
      <label>(92) {{headerText}}SPC Number</label>
      <div class="value"> {{item.spc92Number}}</div>
    </div>
    <div *ngIf="config.spc92Date.visible">
      <label>(92) {{headerText}}SPC Date</label>
      <div class="value">{{item.spc92Date}} </div>
    </div>
    <div *ngIf="config.spc92Country.visible">
      <label>(92) {{headerText}}SPC Country</label>
      <div class="value"> {{item.spc92Country}}</div>
    </div>
    <div *ngIf="config.number.visible">
      <label>(93) {{headerText}}SPC Number</label>
      <div class="value"> {{item.number}}</div>
    </div>
    <div *ngIf="config.date.visible">
      <label>(93) {{headerText}}SPC Date</label>
      <div class="value"> {{item.date}}</div>
    </div>
    <div *ngIf="config.country.visible">
      <label>(93) {{headerText}}SPC Country</label>
      <div class="value"> {{item.country}}</div>
    </div>
    <div *ngIf="config.expiredDate.visible">
      <label>(94) {{headerText}}SPC Expired Date</label>
      <div class="value"> {{item.expiredDate}}</div>
    </div>

    <div *ngIf="config.patent.visible">
      <label>(95) {{headerText}}SPC Protected by the basic patent</label>
      <div class="value"> {{item.patent}}</div>
    </div>

    <div *ngIf="config.appNumber.visible">
      <label>(96) {{headerText}}Application Number</label>
      <div class="value"> {{item.appNumber}}</div>
    </div>
    <div *ngIf="config.appKind.visible">
      <label>(96) {{headerText}}Application Kind</label>
      <div class="value"> {{item.appKind}}</div>
    </div>
    <div *ngIf="config.appDate.visible">
      <label>(96) {{headerText}}Application Date</label>
      <div class="value"> {{item.appDate}}</div>
    </div>
    <div *ngIf="config.appCountry.visible">
      <label>(96) {{headerText}}Application Country</label>
      <div class="value"> {{item.appCountry}}</div>
    </div>
    <div *ngIf="config.pubNumber.visible">
      <label>(97) {{headerText}}Publication Number</label>
      <div class="value"> {{item.pubNumber}}</div>
    </div>
    <div *ngIf="config.pubKind.visible">
      <label>(97) {{headerText}}Publication Kind</label>
      <div class="value"> {{item.pubKind}}</div>
    </div>
    <div *ngIf="config.pubDate.visible">
      <label>(97) {{headerText}}Publication Date</label>
      <div class="value"> {{item.pubDate}}</div>
    </div>
    <div *ngIf="config.pubCountry.visible">
      <label>(97) {{headerText}}Publication Country</label>
      <div class="value"> {{item.pubCountry}}</div>
    </div>
  </div>
</div>