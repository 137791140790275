<div class="popup">
    <dx-popup container=".popup" [width]="600" [height]="500" [showTitle]="true" [resizeEnabled]=true
        [closeOnOutsideClick]=false [showCloseButton]=false [shading]=true title="Sub Code Select" [dragEnabled]="false"
        [closeOnOutsideClick]="true" [(visible)]="popupVisible">
        <div *dxTemplate="let data of 'content'" style="overflow-x: auto">
            <!-- <div *ngFor="let patent of patents; let i=index">
      <button class="btn btn-link btn-sm" (click)="loadBiblio(patent.id)">{{patent.id + "  " + patent.key}}</button>
  </div> -->
            <div class="dx-fieldset">
                <div class="dx-field">
                    <div class="dx-field-label">Sub Code</div>
                    <div class="dx-field-value">
                        <!-- <dx-select-box [dataSource]="subCodesDataSource" displayExpr="subCode" valueExpr="subCode"
                        [value]="selectedConfig.subCode" [grouped]="true" (valueChange)="valueChanged($event)">
                    </dx-select-box> -->
                        <dx-lookup [dataSource]="subCodesDataSource" [grouped]="true" [showCancelButton]="false"
                            [showPopupTitle]="false" displayExpr="subCode" valueExpr="subCode"
                            (valueChange)="valueChanged($event)" [(value)]="selectedConfig.subCode">
                        </dx-lookup>
                    </div>
                </div>
            </div>
            <div class="popup-description">{{ "Legal Event Category: " + (selectedConfig.legalEventCategory || "")}}
            </div>
            <div class="popup-description">{{ "St.17 Code: " + (selectedConfig.st17Code || "")}}</div>
            <div class="popup-description">{{ "Section Header Orginal: " + (selectedConfig.sectionHeaderOrg || "")}}
            </div>
            <div class="popup-description">{{ "Section Header English: " + (selectedConfig.sectionHeaderEng || "")}}
            </div>
            <button class="btn btn-primary align-bottom"
                style="margin: auto auto 20px auto; display: block; width: 120px;"
                (click)="ok_click(selectedConfig.id)">OK</button>
        </div>
    </dx-popup>
</div>