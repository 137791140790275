<div class="view-group" *ngIf="data && config.visible(stable)">
    <div *ngFor="let item of data.pledgor">
      <div *ngIf="config.pledgor.name.visible && item.name">
        <label>Pledge {{headerText}}Pledgor Name</label>
        <div class="value">{{item.name}}</div>
      </div>
      <div *ngIf="config.pledgor.country.visible && item.country">
        <label>Pledge {{headerText}}Pledgor Country</label>
        <div class="value">{{item.country}}</div>
      </div>
      <div *ngIf="config.pledgor.language.visible && item.language">
        <label>Pledge {{headerText}}Pledgor Language</label>
        <div class="value">{{item.language}}</div>
      </div>
      <div *ngIf="config.pledgor.address.visible && item.address">
        <label>Pledge {{headerText}}Pledgor Address</label>
        <div class="value">{{item.address}}</div>
      </div>
  
      <div *ngFor="let tr of item.translations">
        <div *ngIf="config.pledgor.translations.language.visible && tr.language">
          <label>Pledge {{headerText}}Pledgor Language</label>
          <div class="value">{{tr.language}}</div>
        </div>
        <div *ngIf="config.pledgor.translations.trName.visible && tr.trName">
          <label>Pledge {{headerText}}Pledgor Name</label>
          <div class="value">{{tr.trName}}</div>
        </div>
        <div *ngIf="config.pledgor.translations.trAddress.visible && tr.trAddress">
          <label>Pledge {{headerText}}Pledgor Address</label>
          <div class="value">{{tr.trAddress}}</div>
        </div>
      </div>
    </div>
  
    <div *ngFor="let item of data.pledgee">
      <div *ngIf="config.pledgee.name.visible && item.name">
        <label>Pledge {{headerText}}Pledgee Name</label>
        <div class="value">{{item.name}}</div>
      </div>
      <div *ngIf="config.pledgee.country.visible && item.country">
        <label>Pledge {{headerText}}Pledgee Country</label>
        <div class="value">{{item.country}}</div>
      </div>
      <div *ngIf="config.pledgee.language.visible && item.language">
        <label>Pledge {{headerText}}Pledgee Language</label>
        <div class="value">{{item.language}}</div>
      </div>
      <div *ngIf="config.pledgee.address.visible && item.address">
        <label>Pledge {{headerText}}Pledgee Address</label>
        <div class="value">{{item.address}}</div>
      </div>
  
      <div *ngFor="let tr of item.translations">
        <div *ngIf="config.pledgee.translations.language.visible && tr.language">
          <label>Pledge {{headerText}}Pledgee Language</label>
          <div class="value">{{tr.language}}</div>
        </div>
        <div *ngIf="config.pledgee.translations.trName.visible && tr.trName">
          <label>Pledge {{headerText}}Pledgee Name</label>
          <div class="value">{{tr.trName}}</div>
        </div>
        <div *ngIf="config.pledgee.translations.trAddress.visible && tr.trAddress">
          <label>Pledge {{headerText}}Pledgee Address</label>
          <div class="value">{{tr.trAddress}}</div>
        </div>
      </div>
    </div>
  
    <div *ngIf="config.effectiveDate.visible"><label>Pledge {{headerText}}Effective Date</label>
      <div class="value">{{data.effectiveDate}}</div>
    </div>
    <div *ngIf="config.expirationDate.visible"><label>Pledge {{headerText}}Expiration Date</label>
      <div class="value">{{data.expirationDate}}</div>
    </div>
  </div>