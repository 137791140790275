import { RouterModule, Routes } from '@angular/router';
import { AccountManagerComponent } from '../account/account-manager/account-manager.component';
import { ConfigListComponent } from './patent/config-list/config-list.component';
import { ConfigComponent } from './patent/config/config.component';
import { EventValidateComponent } from './patent/event-validate/event-validate.component';
import { PatentComponent } from './patent/patent.component';
import { PdfUploadComponent } from './patent/pdf-upload/pdf-upload.component';
import { PdfComponent } from './patent/pdf/pdf.component';
import { SearchComponent } from './patent/search/search.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';


export const routes: Routes = [
    { path: 'patent', component: PatentComponent },
    { path: 'configlist', component: ConfigListComponent},
    { path: 'search', component: SearchComponent},
    { path: 'config', component: ConfigComponent},
    { path: 'pdf', component: PdfComponent},
    { path: 'pdfupload', component: PdfUploadComponent},
    { path: 'account', component: AccountManagerComponent},
    { path: 'validations', component: EventValidateComponent },
    { path: 'pagenotfound', component: PageNotFoundComponent },
    { path: '', redirectTo: 'pdf', pathMatch: 'full' },
  ];

export const routing = RouterModule.forRoot(routes, { enableTracing: true, relativeLinkResolution: 'legacy' });
