
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { Config, IConfig } from '../../shared/models/iconfig.model';
import { DataService } from '../../shared/services/data.service';
import { SecurityService } from '../../shared/services/security.service';
import { PatentService } from '../patent.service';
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  configForm: FormGroup;
  private config = new Subject<IConfig>();
  config$ = this.config.asObservable();
  id: number;
  categories: any[];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private patentService: PatentService,
    private securityService: SecurityService, private router: Router) {
    const _that = this;
    _that.prepareForm();
    _that.config$.subscribe(config => {
      if (config.description && !config.sectionHeaderEng) {
        config.sectionHeaderEng = config.description;
      }
      if (!config.kind && config.kind === 0) {
        config.kind = 1;
      }
      if (!config.instructions && config.instructions === '') {
        config.instructions = `Text display field for special data Section.
Use the note field to enter a specific note for this record (like: page not readable, bad scan)
Use the note field to enter special data for that section.
These are the necessary Data Unit Names with separators. Copy and paste them into the note field and then copy and paste the values from the PDF.`;
      }
      var _config = new Config();

      const updatePattern = <IConfig>{
        publication: {
          authority: { pattern: '^[A-Z]{2}$' },
          kind: { pattern: '^[A-Z][0-9]?$' },
          languageDesignation: { pattern: '^[A-Z][0-9]?$' }
        },
        application: {
          language: { pattern: '^[A-Z]{2}$' }
        },
        priorities: {
          country: { pattern: '^[A-Z]{2}$' }
        },
        titles: {
          language: { pattern: '^[A-Z]{2}$' }
        },
        abstracts: {
          language: { pattern: '^[A-Z]{2}$' }
        },
        applicants: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        inventors: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        assignees: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        agents: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        invOrApps: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        invAppGrants: {
          language: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        ipcs: {
          edition: { pattern: '^[1-8]$' }
        },
        intConvention: {
          designatedStates: {
            state: { pattern: '^[A-Z]{2}$' }
          }
        },
        patentCitations: {
          authority: { pattern: '^[A-Z]{2}$' },
          kind: { pattern: '^[A-Z][0-9]?$' }
        },
        europeanPatents: {
          spc92Country: { pattern: '^[A-Z]{2}$' },
          country: { pattern: '^[A-Z]{2}$' }
        },
        claims: {
          language: { pattern: '^[A-Z]{2}$' },
          number: { pattern: '^[0-9]+$' },
        },
        pledge: {
          pledgee: {
            country: {pattern: '^[A-Z]{2}$'},
            language: {pattern: '^[A-Z]{2}$'}
          },
          pledgor: {
            country: {pattern: '^[A-Z]{2}$'},
            language: {pattern: '^[A-Z]{2}$'}
          }
        }
      };

      DataService.patchValue(_config, updatePattern);
      DataService.patchValue(_config, config);
      _that.configForm.patchValue(_config);

      // this.configForm.patchValue(updatePattern);
    });

    _that.route.paramMap.subscribe((params: ParamMap) => {
      if ((params !== null) && (params.get('id') !== null)) {
        _that.id = Number.parseInt(params.get('id'));
        _that.patentService.getConfig(Number.parseInt(params.get('id'))).subscribe(data => _that.config.next(JSON.parse(data.config)));
      } else {
        _that.id = 0;
      }
    });

    this.controlTraverse(this.configForm, true);
  }

  controlTraverse(control: FormGroup, value: boolean) {
    if (control.controls) {
      Object.keys(control.controls).forEach(name => {
        if (name === 'new') {
          control.get(name).setValue(value);
        } else if (name === 'visible') {
          control.get(name).setValue(value);
          control.get(name).valueChanges.subscribe((e) => {
            if (e === false) {
              control.get('required').setValue(false);
              if (control.contains('original'))
                control.get('original').setValue(false);
            }
          });
        } else if (name === 'required') {
          control.get(name).valueChanges.subscribe((e) => {
            if (e === true) {
              control.get('visible').setValue(true);
            }
          });
        } else if (name === 'original') {
          control.get(name).setValue(value);
          control.get(name).valueChanges.subscribe((e) => {
            if (e === true) {
              control.get('visible').setValue(true);
            }
          });
        } else {
          this.controlTraverse(control.get(name) as FormGroup, value);
        }
      });
    }
  }

  disSelectAll() {
    this.controlTraverse(this.configForm, false);
  }

  // translationEnable(fg: FormGroup) {
  //   Object.keys(fg.controls).forEach((c) => {
  //     if(fg.contains('translations')) {

  //     }
  //   }, this)
  // }

  prepareForm() {
    this.configForm = this.fb.group({
      country: ['', Validators.required],
      subCode: ['', Validators.required],
      // description: ['', Validators.required],
      legalEventCategory: ['', Validators.required],
      st17Code: ['', Validators.required],
      sectionHeaderOrg: ['', Validators.required],
      sectionHeaderEng: ['', Validators.required],
      wipoCompliant: false,
      kind: [, Validators.required],
      instructions: `Text display field for special data Section.
Use the note field to enter a specific note for this record (like: page not readable, bad scan)
Use the note field to enter special data for that section.
These are the necessary Data Unit Names with separators. Copy and paste them into the note field and then copy and paste the values from the PDF.`,
      legalEvent: this.fb.group({
        new: false,
        number: this.fb.group({ required: false, visible: true, original: true, pattern: '' }),
        date: this.fb.group({ required: false, visible: true, original: true }),
        note: this.fb.group({ required: false, visible: true, original: true }),
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: true }),
        })
      }),
      publication: this.fb.group({
        new: false,
        authority: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: true, original: true, pattern: '' }),
        kind: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z][0-9]?$' }),
        date: this.fb.group({ required: false, visible: true, original: true }),
        languageDesignation: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z][0-9]?$' }),
      }),
      application: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: true, original: true, pattern: '' }),
        otherDate: this.fb.group({ required: false, visible: true, original: true }),
        date: this.fb.group({ required: false, visible: true, original: true }),
        effectiveDate: this.fb.group({ required: false, visible: true, original: true }),
      }),
      priorities: this.fb.group({
        new: false,
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: true, original: true }),
        date: this.fb.group({ required: false, visible: true, original: true }),
      }),
      dOfPublication: this.fb.group({
        new: false,
        date_41: this.fb.group({ required: false, visible: true, original: true }),
        date_42: this.fb.group({ required: false, visible: true, original: true }),
        date_44: this.fb.group({ required: false, visible: true, original: true }),
        date_45: this.fb.group({ required: false, visible: true, original: true }),
        date_46: this.fb.group({ required: false, visible: true, original: true }),
        date_47: this.fb.group({ required: false, visible: true, original: true }),
        date_48: this.fb.group({ required: false, visible: true, original: true }),
      }),
      titles: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        text: this.fb.group({ required: false, visible: true, original: true }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: true }),
        })
      }),
      abstracts: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        text: this.fb.group({ required: false, visible: true, original: true }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: true }),
        })
      }),
      applicants: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      inventors: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      assignees: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      agents: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      invOrApps: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      invAppGrants: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        name: this.fb.group({ required: false, visible: true, original: true }),
        firstName: this.fb.group({ required: false, visible: true, original: true }),
        lastName: this.fb.group({ required: false, visible: true, original: true }),
        address1: this.fb.group({ required: false, visible: true, original: true }),
        postCode: this.fb.group({ required: false, visible: true, original: true }),
        city: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          trName: this.fb.group({ required: false, visible: true }),
          trAddress1: this.fb.group({ required: false, visible: true }),
        })
      }),
      ipcs: this.fb.group({
        new: false,
        edition: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[1-8]$' }),
        class: this.fb.group({ required: false, visible: true, original: true, pattern: '' }),
        date: this.fb.group({ required: false, visible: true, original: true, default: '' }),
        ipcDate: this.fb.group({ required: false, visible: true, original: true, default: '' }),
        domestic: this.fb.group({ required: false, visible: true, original: true, pattern: '', default: '' }),
      }),
      intConvention: this.fb.group({
        new: false,
        pctSearchDate: this.fb.group({ required: false, visible: true, original: true }),
        pctNationalDate: this.fb.group({ required: false, visible: true, original: true }),
        pctApplNumber: this.fb.group({ required: false, visible: true, original: true }),
        pctApplKind: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z][0-9]?$' }),
        pctApplDate: this.fb.group({ required: false, visible: true, original: true }),
        pctApplCountry: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        pctPublNumber: this.fb.group({ required: false, visible: true, original: true }),
        pctPublKind: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z][0-9]?$' }),
        pctPublDate: this.fb.group({ required: false, visible: true, original: true }),
        pctPublCountry: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        designatedStates: this.fb.group({
          state: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z]{2}$' })
        }),
      }),
      patentCitations: this.fb.group({
        new: false,
        authority: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: true, original: true }),
        kind: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z][0-9]?$' }),
        date: this.fb.group({ required: false, visible: true, original: true }),
        applicant: this.fb.group({ required: false, visible: true, original: true }),
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: true }),
        })
      }),
      nonPatentCitations: this.fb.group({
        new: false,
        text: this.fb.group({ required: false, visible: true, original: true }),
        xpNumber: this.fb.group({ required: false, visible: true, original: true }),
        language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: true }),
        })
      }),
      related: this.fb.group({
        new: false,
        source: this.fb.group({ required: false, visible: true, original: true }),
        format: this.fb.group({ required: false, visible: true, original: true }),
        type: this.fb.group({ required: false, visible: true, original: true }),
        number: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        date: this.fb.group({ required: false, visible: true, original: true }),
      }),
      europeanPatents: this.fb.group({
        new: false,
        spc92Number: this.fb.group({ required: false, visible: true, original: true }),
        spc92Date: this.fb.group({ required: false, visible: true, original: true }),
        spc92Country: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: true, original: true }),
        date: this.fb.group({ required: false, visible: true, original: true }),
        country: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
        expiredDate: this.fb.group({ required: false, visible: true, original: true }),
        patent: this.fb.group({ required: false, visible: true, original: true }),
        appNumber: this.fb.group({ required: false, visible: true, original: true }),
        appKind: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z][0-9]?$' }),
        appDate: this.fb.group({ required: false, visible: true, original: true }),
        appCountry: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
        pubNumber: this.fb.group({ required: false, visible: true, original: true }),
        pubKind: this.fb.group({ required: false, visible: true, original: true, pattern: '^[A-Z][0-9]?$' }),
        pubDate: this.fb.group({ required: false, visible: true, original: true }),
        pubCountry: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
      }),
      license: this.fb.group({
        new: false,
        period: this.fb.group({ required: false, visible: true, original: true }),
        number: this.fb.group({ required: false, visible: true, original: true }),
        licensor: this.fb.group({
          language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          name: this.fb.group({ required: false, visible: true, original: true }),
          address: this.fb.group({ required: false, visible: true, original: true }),
          country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          translations: this.fb.group({
            language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
            trName: this.fb.group({ required: false, visible: true }),
            trAddress: this.fb.group({ required: false, visible: true }),
          })
        }),
        licensee: this.fb.group({
          language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          name: this.fb.group({ required: false, visible: true, original: true }),
          address: this.fb.group({ required: false, visible: true, original: true }),
          country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          translations: this.fb.group({
            language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
            trName: this.fb.group({ required: false, visible: true }),
            trAddress: this.fb.group({ required: false, visible: true }),
          })
        })
      }),
      pledge: this.fb.group({
        new: false,
        effectiveDate: this.fb.group({ required: false, visible: true, original: true }),
        expirationDate: this.fb.group({ required: false, visible: true, original: true }),
        pledgor: this.fb.group({
          language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          name: this.fb.group({ required: false, visible: true, original: true }),
          address: this.fb.group({ required: false, visible: true, original: true }),
          country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          translations: this.fb.group({
            language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
            trName: this.fb.group({ required: false, visible: true }),
            trAddress: this.fb.group({ required: false, visible: true }),
          })
        }),
        pledgee: this.fb.group({
          language: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          name: this.fb.group({ required: false, visible: true, original: true }),
          address: this.fb.group({ required: false, visible: true, original: true }),
          country: this.fb.group({ required: false, visible: true, original: true, default: '', pattern: '^[A-Z]{2}$' }),
          translations: this.fb.group({
            language: this.fb.group({ required: false, visible: true, default: '', pattern: '^[A-Z]{2}$' }),
            trName: this.fb.group({ required: false, visible: true }),
            trAddress: this.fb.group({ required: false, visible: true }),
          })
        })
      }),
      claims: this.fb.group({
        new: false,
        language: this.fb.group({ required: false, visible: false, default: '', pattern: '^[A-Z]{2}$' }),
        number: this.fb.group({ required: false, visible: false, default: '', pattern: '^[\\d]+$' }),
        text: this.fb.group({ required: false, visible: false, }),
        translations: this.fb.group({
          language: this.fb.group({ required: false, visible: false, default: '', pattern: '^[A-Z]{2}$' }),
          tr: this.fb.group({ required: false, visible: false }),
        })
      })
    });

    this.translationEnable(this.configForm.get('applicants.name.visible'), this.configForm.get('applicants.translations.trName.visible'));
    this.translationEnable(this.configForm.get('applicants.address1.visible'), this.configForm.get('applicants.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('applicants.language.visible'), this.configForm.get('applicants.translations.language.visible'));
    this.translationEnable(this.configForm.get('inventors.name.visible'), this.configForm.get('inventors.translations.trName.visible'));
    this.translationEnable(this.configForm.get('inventors.address1.visible'), this.configForm.get('inventors.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('inventors.language.visible'), this.configForm.get('inventors.translations.language.visible'));
    this.translationEnable(this.configForm.get('assignees.name.visible'), this.configForm.get('assignees.translations.trName.visible'));
    this.translationEnable(this.configForm.get('assignees.address1.visible'), this.configForm.get('assignees.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('assignees.language.visible'), this.configForm.get('assignees.translations.language.visible'));
    this.translationEnable(this.configForm.get('agents.name.visible'), this.configForm.get('agents.translations.trName.visible'));
    this.translationEnable(this.configForm.get('agents.address1.visible'), this.configForm.get('agents.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('agents.language.visible'), this.configForm.get('agents.translations.language.visible'));
    this.translationEnable(this.configForm.get('invOrApps.name.visible'), this.configForm.get('invOrApps.translations.trName.visible'));
    this.translationEnable(this.configForm.get('invOrApps.address1.visible'), this.configForm.get('invOrApps.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('invOrApps.language.visible'), this.configForm.get('invOrApps.translations.language.visible'));
    this.translationEnable(this.configForm.get('invAppGrants.name.visible'), this.configForm.get('invAppGrants.translations.trName.visible'));
    this.translationEnable(this.configForm.get('invAppGrants.address1.visible'), this.configForm.get('invAppGrants.translations.trAddress1.visible'));
    this.translationEnable(this.configForm.get('invAppGrants.language.visible'), this.configForm.get('invAppGrants.translations.language.visible'));
    this.translationEnable(this.configForm.get('nonPatentCitations.text.visible'), this.configForm.get('nonPatentCitations.translations.tr.visible'));
    this.translationEnable(this.configForm.get('nonPatentCitations.language.visible'), this.configForm.get('nonPatentCitations.translations.language.visible'));
    this.translationEnable(this.configForm.get('patentCitations.applicant.visible'), this.configForm.get('patentCitations.translations.tr.visible'));
    this.translationEnable(this.configForm.get('patentCitations.language.visible'), this.configForm.get('patentCitations.translations.language.visible'));
    this.translationEnable(this.configForm.get('titles.text.visible'), this.configForm.get('titles.translations.tr.visible'));
    this.translationEnable(this.configForm.get('titles.language.visible'), this.configForm.get('titles.translations.language.visible'));
    this.translationEnable(this.configForm.get('abstracts.text.visible'), this.configForm.get('abstracts.translations.tr.visible'));
    this.translationEnable(this.configForm.get('abstracts.language.visible'), this.configForm.get('abstracts.translations.language.visible'));
    this.translationEnable(this.configForm.get('legalEvent.note.visible'), this.configForm.get('legalEvent.translations.tr.visible'));
    this.translationEnable(this.configForm.get('legalEvent.language.visible'), this.configForm.get('legalEvent.translations.language.visible'));
    this.translationEnable(this.configForm.get('license.licensor.name.visible'), this.configForm.get('license.licensor.translations.trName.visible'));
    this.translationEnable(this.configForm.get('license.licensor.address.visible'), this.configForm.get('license.licensor.translations.trAddress.visible'));
    this.translationEnable(this.configForm.get('license.licensor.language.visible'), this.configForm.get('license.licensor.translations.language.visible'));
    this.translationEnable(this.configForm.get('license.licensee.name.visible'), this.configForm.get('license.licensee.translations.trName.visible'));
    this.translationEnable(this.configForm.get('license.licensee.address.visible'), this.configForm.get('license.licensee.translations.trAddress.visible'));
    this.translationEnable(this.configForm.get('license.licensee.language.visible'), this.configForm.get('license.licensee.translations.language.visible'));
    this.translationEnable(this.configForm.get('claims.text.visible'), this.configForm.get('claims.translations.tr.visible'));
    this.translationEnable(this.configForm.get('claims.language.visible'), this.configForm.get('claims.translations.language.visible'));
  }

  translationEnable(o: AbstractControl, t: AbstractControl) {
    o.valueChanges.subscribe(v => {
      if (!v) {
        t.setValue(v);
      }
    });
    t.valueChanges.subscribe(v => {
      if (v) {
        o.setValue(v);
      }
    });
  }

  ngOnInit() {
    // this.configForm.patchValue(this.config);
    this.patentService.getCategories().subscribe(res => {
      this.categories = res;
    });
  }

  save() {
    if (!this.configForm.valid) {
      alert('Not all the fields have have the valid value, please have a check!', 'validation error!');
      this.configForm.get('kind').markAsTouched({ onlySelf: true });
      this.configForm.get('country').markAsTouched({ onlySelf: true });
      this.configForm.get('subCode').markAsTouched({ onlySelf: true });
      this.configForm.get('legalEventCategory').markAsTouched({ onlySelf: true });
      this.configForm.get('st17Code').markAsTouched({ onlySelf: true });
      this.configForm.get('sectionHeaderOrg').markAsTouched({ onlySelf: true });
      this.configForm.get('sectionHeaderEng').markAsTouched({ onlySelf: true });
      return;
    }
    const value = {
      Id: this.id,
      CountryCode: this.configForm.get('country').value,
      SubCode: this.configForm.get('subCode').value,
      LegalEventCategoryId: this.configForm.get('legalEventCategory').value,
      St17Code: this.configForm.get('st17Code').value,
      SectionHeaderOrg: this.configForm.get('sectionHeaderOrg').value,
      SectionHeaderEng: this.configForm.get('sectionHeaderEng').value,
      Config: JSON.stringify(this.configForm.value),
      Kind: this.configForm.get('kind').value,
      WipoCompliant: this.configForm.get('wipoCompliant').value,
    };
    this.patentService.postConfig(value).pipe(sub => new Observable<any>(subscriber => {
      sub.subscribe(d => {
        if (value.Id) {
          this.patentService.ws.next({ country: value.CountryCode, subcode: value.SubCode });
        }
        subscriber.next(d)
      })
    }))
      .subscribe(
        res => {
          this.router.navigate(['configlist']);
        },
        error => {
          notify(error, 'error', 600);
          return observableThrowError(error);
        }
      );
  }
}
