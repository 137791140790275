import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { BiblioBaseComponent } from '../biblio-base.component';
import { BiblioLicenseMemberComponent } from '../biblio-license-member/biblio-license-member.component';
@Component({
  selector: 'app-biblio-license',
  templateUrl: './biblio-license.component.html',
  styleUrls: ['./biblio-license.component.scss']
})
export class BiblioLicenseComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  @ViewChild('licensor', { static: false }) licensor: BiblioLicenseMemberComponent;
  @ViewChild('licensee', { static: false }) licensee: BiblioLicenseMemberComponent;
  constructor(protected formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      (this.formGroup as AbstractControl).patchValue(this.data);
    }
    if (this.data && this.config && changes.config && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      this.setValidator(this.formGroup, this.config, this.config.ConfigName);
    }
    // window.alert(this.licensee)
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      number: '',
      period: ''
    });
  }

  get Value(): any {
    let value = this.formatObject(this.formGroup.value)
    return Object.assign(value, { licensor: (this.licensor ? this.licensor.Value : null), licensee: (this.licensee ? this.licensee.Value : null) });
  }

  get Valid(): boolean {
    if (this.config.visible(this.stable) && ((!this.formGroup.valid) || (!this.licensee.Valid) || (!this.licensor.Valid))) {
      alert(`Not all the fields in <b>${this.headerText}</b> have a valid value, please have a check!`, 'VALIDATION ERROR!');
      return false;
    } else {
      return true;
    }
  }
}
