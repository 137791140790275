<div class="popup">
  <dx-popup container=".popup" [width]="500" [height]="250" [showTitle]="true" [resizeEnabled]=true
    [closeOnOutsideClick]=false [showCloseButton]=false [shading]=true title="Download Configurations"
    [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">
    <div id="selection" *dxTemplate="let data of 'content'" style="overflow-x: auto">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Country Code</div>
          <div class="dx-field-value">
            <dx-select-box [dataSource]="countrycodes" [(value)]="selectedCountry"></dx-select-box>
          </div>
        </div>
      </div>
      <button class="btn btn-primary align-bottom" style="margin: auto auto 20px auto; display: block; width: 120px;"
        (click)="download()">Download</button>
      <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#selection' }"
        [(visible)]="loadingVisible" [showIndicator]="true" [shading]="true" [closeOnOutsideClick]="false">
      </dx-load-panel>
    </div>

  </dx-popup>
</div>