<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngIf="config.number.visible"><label>(21) {{headerText}}</label>
    <div class="value">{{data.number}}</div>
  </div>
  <div *ngIf="config.date.visible"><label>(22) {{headerText}}</label>
    <div class="value">{{data.date}}</div>
  </div>
  <div *ngIf="config.otherDate.visible"><label>(23) {{headerText}}</label>
    <div class="value">{{data.otherDate}}</div>
  </div>
  <div *ngIf="config.effectiveDate.visible"><label>(24) {{headerText}}</label>
    <div class="value">{{data.effectiveDate}}</div>
  </div>
  <div *ngIf="config.language.visible"><label>(25) {{headerText}}</label>
    <div class="value">{{data.language}}</div>
  </div>
</div>