import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';
import { Observable } from 'rxjs';
import { ConfigItem } from '../../shared/models/config-item';
import { Config } from '../../shared/models/iconfig.model';
import { DataService } from '../../shared/services/data.service';
import { PatentService } from '../patent.service';
@Component({
  selector: 'app-subcode-change',
  templateUrl: './subcode-change.component.html',
  styleUrls: ['./subcode-change.component.scss']
})
export class SubcodeChangeComponent implements OnInit, OnDestroy {
  @Input() subCodesDataSource: DataSource;
  @Input() configs: Array<ConfigItem>;
  selectedConfig = new ConfigItem();
  popupVisible: boolean;
  subcode: string;
  constructor(private patentService: PatentService) {
  }

  ngOnInit() {
  }

  show(subCode: string) {
    if (subCode) {
      this.selectedConfig = this.configs.find(e => e.subCode === subCode);
    }
    this.popupVisible = true;
  }

  valueChanged($event: string) {
    this.selectedConfig = this.configs.find(e => e.subCode === $event);
  }

  changeSubCode(subCode: string) {
    const conf = this.configs.find(e => e.subCode === subCode);
    if (conf) {
      this.loadConfig(conf.id).subscribe(cfg => {
        this.patentService.config.next(cfg);
      });
    } else {
      alert(`Can not find the configuration file for the sub code of ${subCode} plesae choose one for it!`, 'error')
        .then(() => this.show(subCode));
    }
  }

  ok_click(key: number) {
    this.loadConfig(key).subscribe(cfg => {
      this.popupVisible = false;
      this.patentService.config.next(cfg);
    });
  }

  loadConfig(key: number) {
    return new Observable<Config>(subscribe => {
      this.patentService.getConfig(key).subscribe(data => {
        const cfg = new Config()
        DataService.patchValue(cfg, JSON.parse(data.config));
        cfg.id = data.id;
        subscribe.next(cfg);
      });
    });
  }

  ngOnDestroy(): void {
    // this.pdfSubscribe.unsubscribe();
  }
}
