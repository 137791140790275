<accordion-group *ngIf="visible" (paste)="paste($event)" [isOpen]="openToggle" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
    {{headerText}}
    <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div [formGroup]="formGroup" class="row">
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_41.visible">
      <div class="form-group">
        <label class="app-biblio-title">(41) Date</label>
        <input id="date_41" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_41">
        <div *ngIf="formGroup.get('date_41').hasError('required')  && formGroup.get('date_41').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_41').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_42.visible">
      <div class="form-group">
        <label class="app-biblio-title">(42) Date</label>
        <input id="date_42" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_42">
        <div *ngIf="formGroup.get('date_42').hasError('required')  && formGroup.get('date_42').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_42').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.publication.date.visible">
      <div class="form-group">
        <label class="app-biblio-title">(43) Date</label>
        <input id="date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" [formControl]="formGroup.get('date')">
        <div *ngIf="formGroup.get('date').hasError('required') && formGroup.get('date').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_44.visible">
      <div class="form-group">
        <label class="app-biblio-title">(44) Date</label>
        <input id="date_44" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_44">
        <div *ngIf="formGroup.get('date_44').hasError('required')  && formGroup.get('date_44').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_44').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_45.visible">
      <div class="form-group">
        <label class="app-biblio-title">(45) Date</label>
        <input id="date_45" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_45">
        <div *ngIf="formGroup.get('date_45').hasError('required')  && formGroup.get('date_45').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_45').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_46.visible">
      <div class="form-group">
        <label class="app-biblio-title">(46) Date</label>
        <input id="date_46" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_46">
        <div *ngIf="formGroup.get('date_46').hasError('required')  && formGroup.get('date_46').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_46').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_47.visible">
      <div class="form-group">
        <label class="app-biblio-title">(47) Date</label>
        <input id="date_47" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_47">
        <div *ngIf="formGroup.get('date_47').hasError('required')  && formGroup.get('date_47').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_47').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="config && config.dOfPublication.date_48.visible">
      <div class="form-group">
        <label class="app-biblio-title">(48) Date</label>
        <input id="date_48" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date_48">
        <div *ngIf="formGroup.get('date_48').hasError('required')  && formGroup.get('date_48').touched" class="alert alert-danger">Required field.</div>
        <div *ngIf="formGroup.get('date_48').hasError('error')" class="alert alert-danger">Date format not match</div>
      </div>
    </div>
  </div>
</accordion-group>