import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountService } from '../account.service';
import { AccountManager } from '../models/account-manager';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  account: AccountManager;
  visible: boolean;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  constructor(private accountService: AccountService) { }

  ngOnInit() {
  }

  save($event: any) {
    this.accountService.post(this.account).subscribe(d => {
      this.visible = false;
      this.refresh.emit(true);
    });
  }

  show(data: AccountManager){
    this.visible = true;
    this.account = new AccountManager;
    Object.assign(this.account, data);
  }

  close($event: any) {
    this.visible = false;
  }
}
