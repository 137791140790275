import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Title, Translation } from '../../../shared/models/ibiblio.model';
import { PatentService } from '../../patent.service';
import { BiblioFormArrayBaseComponent } from '../biblio-form-array-base.component';

@Component({
  selector: 'app-biblio-titles',
  templateUrl: './biblio-titles.component.html',
  styleUrls: ['./biblio-titles.component.scss']
})
export class BiblioTitlesComponent extends BiblioFormArrayBaseComponent {
  constructor(protected formBuilder: FormBuilder, protected patentService: PatentService) {
    super(formBuilder);
  }
  setFormArray(data: Title[]) {
    // if(!data && data.length ===0) {
    //   data = [<Title>{translations: []}]
    // }

    const itemFGs = data.map((item, index) => {
      const itemG = this.formBuilder.group({
        language: item.language,
        text: item.text,
        translations: this.formBuilder.array([])
      });
      this.setTranslations(itemG, item.translations);
      return itemG;
    });
    this.formGroup.setControl('formArray', this.formBuilder.array(itemFGs));
  }

  addFormArray() {
    const control = this.formBuilder.group({
      language: this.config.language.default || '',
      text: '',
      translations: this.formBuilder.array([])
    });
    if (this.showTranslations) {
      this.addTranslation(control)
    }

    this.formArray.push(control);
    this.setValidator(control, this.config, this.config.ConfigName);
  }


  setTranslations(itemG: FormGroup, data: Translation[]) {
    if ((null === data || (data && data.length === 0)) && this.config.translations.required()) {
      data = [<Translation>{ type: this.nameType, language: this.config.translations.language.default}];
    }
    if (data && data.length > 0) {
      this.showTranslations = true;
      const trFGs = data.map((item, index) => this.formBuilder.group({
        type: item.type,
        language: item.language,
        tr: item.tr,
      }));
      itemG.setControl('translations', this.formBuilder.array(trFGs));
    }
  }

  addTranslation(item: AbstractControl) {
    // const item = this.formArray.at(i)
    const translations = item.get('translations') as FormArray
    const translate = this.formBuilder.group({
      type: this.nameType,
      language: this.config.translations.language.default,
      tr: '',
    });

    item.get('text').valueChanges.subscribe(value => this.getTranslate(translate.get('tr'), translate.get('language').value, value))
    this.getTranslate(translate.get('tr'), translate.get('language').value, item.get('text').value)
    translations.push(translate);
    this.setValidator(translate, this.config.translations, 'translations');
  }
}
