<div class="popup">
    <dx-popup container=".popup" [width]="800" [height]="500" [showTitle]="true"
        title="The event(s) with this Application/Publication number is already exists" [dragEnabled]="false"
        [closeOnOutsideClick]="true" [(visible)]="popupVisible">
        <div *dxTemplate="let data of 'content'">
            <dx-scroll-view [height]="360" direction="both">
                <table>
                    <thead>
                        <th>SubCode</th>
                        <th>Category</th>
                        <th>Application/Publication number</th>
                        <th>Date</th>
                    </thead>
                    <tr class="tr" *ngFor="let patent of patents; let i=index" (click)="loadBiblio(patent.id)">
                        <td>{{patent.subCode }}</td>
                        <td>{{patent.category }}</td>
                        <td>{{ patent.pubNumber ? " Pub: " + patent.pubNumber: "" }}{{ patent.appNumber ? " App: " +
                            patent.appNumber:
                            "" }}{{ patent.legalNumber ? " Legal: " + patent.legalNumber: "" }} {{ patent.epPubNumber ?
                            " EP_Pub: "
                            + patent.epPubNumber: "" }} {{ patent.epAppNumber ? " EP_App: "
                            + patent.epAppNumber: "" }}{{ patent.pctApplNumber ? " PCT_App: "
                            + patent.pctApplNumber: "" }}{{ patent.pctPublNumber ? " PCT_Pub: "
                            + patent.pctPublNumber: "" }}</td>
                        <td>{{ patent.pubDate ? " Pub: " + (patent.pubDate | date: 'yyyy/MM/dd') : "" }}{{
                            patent.appDate ? " App: " + (patent.appDate | date: 'yyyy/MM/dd'):
                            "" }}{{ patent.legalDate ? " Legal: " + (patent.legalDate | date: 'yyyy/MM/dd'): "" }} {{
                            patent.epPubDate ? " EP_Pub: "
                            + (patent.epPubDate | date: 'yyyy/MM/dd'): "" }}{{ patent.epAppDate ? " EP_App: "
                            + (patent.epAppDate | date: 'yyyy/MM/dd'): "" }}</td>
                    </tr>
                </table>
            </dx-scroll-view>
            <div class="buttons">
                <button (click)="create()" class="btn btn-primary align-bottom">Create New Event</button>
                <button (click)="exit()" class="btn btn-primary align-bottom">Exit</button>
            </div>
        </div>
    </dx-popup>
</div>