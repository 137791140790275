import { Component, OnInit } from '@angular/core';
import config from 'devextreme/core/config';
import { SecurityService } from './shared/services/security.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  Authenticated: boolean;
  constructor(private securityService: SecurityService) {
    if (this.securityService.msalInstance.getAccount()) {
      this.securityService.getHeaders().subscribe(() => {
        this.Authenticated = true;
      })
    }
    config({
      forceIsoDateParsing: true,
    });
  }
}
