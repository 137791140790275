<accordion-group *ngIf="config && config.visible(stable)" [isOpen]="openToggle" (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
       {{headerText}}
      <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  
  <div class="row">
    <app-biblio-license-member style="width: 100%;" #licensor headerText="Licensor" [configname]="licensor" [data]="data ? data.licensor : null" [config]="config.licensor" [stable]="true"></app-biblio-license-member>
  </div>
  <div class="row">
    <app-biblio-license-member style="width: 100%;" #licensee headerText="Licensee" [configname]="licensee" [data]="data ? data.licensee : null" [config]="config.licensee" [stable]="true"></app-biblio-license-member>
  </div>
  <div [formGroup]="formGroup" class="row">
      <div class="col-md-3" *ngIf="config && config.number.visible">
          <div class="form-group">
              <label class="app-biblio-title">Registration number</label>
              <input id="number" class="form-control form-input" type="text" formControlName="number">
              <div *ngIf="formGroup.get('number').hasError('required')  && formGroup.get('number').touched" class="alert alert-danger">Required field.</div>
          </div>
      </div>
      <div class="col-md-3" *ngIf="config && config.period.visible">
          <div class="form-group">
              <label class="app-biblio-title">Period of validity</label>
              <input id="period" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="period">
              <div *ngIf="formGroup.get('period').hasError('required')  && formGroup.get('period').touched" class="alert alert-danger">Required field.</div>
          </div>
      </div>
  </div>
</accordion-group>
