
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Observable, Subject, Subscription, throwError as observableThrowError } from 'rxjs';
import { ConfigItem } from '../shared/models/config-item';
import { GazetteModel } from '../shared/models/gazette-model';
import { Claim, DOfPublication, EuropeanPatent, IBiblio, LegalEvent, License, LicenseMember, Pledge } from '../shared/models/ibiblio.model';
import { Config, IConfig } from '../shared/models/iconfig.model';
import { Patent } from '../shared/models/patent';
import { PatentId } from '../shared/models/patent-id';
import { PdfModel } from '../shared/models/pdf-model';
import { DataService } from '../shared/services/data.service';
import { SecurityService } from '../shared/services/security.service';
import { BiblioComponent } from './biblio/biblio.component';
import { ExistlistComponent } from './biblio/existlist/existlist.component';
import { PatentlistComponent } from './biblio/patentlist/patentlist.component';
import { EventViewComponent } from './event-view/event-view.component';
import { PatentService } from './patent.service';
import { SubcodeChangeComponent } from './subcode-change/subcode-change.component';

@Component({
  selector: 'app-patent',
  templateUrl: './patent.component.html',
  styleUrls: ['./patent.component.scss']
})
export class PatentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(PatentlistComponent, { static: true }) patentList: PatentlistComponent;
  @ViewChild(SubcodeChangeComponent, { static: true }) changeSubcode: SubcodeChangeComponent;
  @ViewChild('biblioComponent', { static: true }) biblioComponent: BiblioComponent;
  @ViewChild('visiblediv', { static: true }) visiblediv: ElementRef;
  @ViewChild('submitnext', { static: true }) submitnext: ElementRef;
  @ViewChild('submitcomplete', { static: true }) submitcomplete: ElementRef;
  @ViewChild(ExistlistComponent, { static: true }) existlistComponent: ExistlistComponent;
  @ViewChild(EventViewComponent, { static: true }) eventView: EventViewComponent;
  config = new Config;
  configs: Array<ConfigItem>;
  pdfUrl: string;
  Categories: Array<any>;
  createNewBiblio: boolean;
  pdf: PdfModel;
  gazetteInfo = new GazetteModel;
  biblioData: IBiblio;
  newBiblioData: IBiblio;
  private patentSubscribe: Subscription;
  private pdfSubscribe: Subscription;
  private configSubscribe: Subscription;
  private existSubscribe: Subscription;
  visibleToggle: boolean;
  patents: Array<PatentId>;
  patentsDataSource: DataSource;
  subCodesDataSource: DataSource;
  existList: Array<PatentId>;
  constructor(private patentService: PatentService, private securityService: SecurityService,
    private route: ActivatedRoute, private router: Router) {
    this.visibleToggle = true;
  }
  ngAfterViewInit(): void {
    this.route.paramMap.pipe(paramsSub => Observable.create(observer => {
      paramsSub.subscribe(params => {
        this.patentService.getCategories().pipe(
          c => Observable.create(cobserver => {
            c.subscribe(d => {
              this.Categories = d;
              cobserver.next(d);
            })
          }),
          catSub => new Observable<any>(pdfobserver => {
            catSub.subscribe(() => {
              this.patentService.getPdf(Number.parseInt(params.get('pdfId'))).subscribe(p => {
                this.pdf = p;
                const gazette = {
                  date: this.pdf.date,
                  gazetteNumber: this.pdf.gazetteNumber
                };
                Object.assign(this.gazetteInfo, gazette);
                this.setUrl();
                pdfobserver.next(p);
              });
            });
          }),
          pdfSub => this.subCodesProcess(pdfSub, params)
        ).subscribe();
        observer.next(params)
      });
    })).subscribe();
  }

  ngOnInit(): void {


    this.existSubscribe = this.patentService.existCheck$.subscribe(data => this.checkExist(data));

    this.patentSubscribe = this.patentService.patent$.subscribe(patent => {
      this.changeSubcode.changeSubCode(patent.subCode);
      const biblio: IBiblio = JSON.parse(patent.biblio);
      patent.newBiblio = patent.newBiblio ? patent.newBiblio : JSON.stringify(this.createBiblio(this.config));
      const newBiblio: IBiblio = JSON.parse(patent.newBiblio);
      biblio.id = patent.id;
      newBiblio.id = patent.id;
      biblio.legalEvent = patent.legalEvent ? JSON.parse(patent.legalEvent) : <LegalEvent>{ number: null, date: null, note: null };

      biblio.screenShots = (biblio.screenShots ? biblio.screenShots : (biblio.images ? biblio.images : []));
      newBiblio.screenShots = (newBiblio.screenShots ? newBiblio.screenShots : (newBiblio.images ? newBiblio.images : []));

      biblio.license = biblio.license ? biblio.license : <License>{ number: null, period: null, licensee: [<LicenseMember>{}], licensor: [<LicenseMember>{}] }
      newBiblio.license = newBiblio.license ? newBiblio.license : <License>{ number: null, period: null, licensee: [<LicenseMember>{}], licensor: [<LicenseMember>{}] }

      biblio.pledge = biblio.pledge ? biblio.pledge : <Pledge>{ effectiveDate: null, expirationDate: null, pledgee: [<LicenseMember>{}], pledgor: [<LicenseMember>{}] }
      newBiblio.pledge = newBiblio.pledge ? newBiblio.pledge : <Pledge>{ effectiveDate: null, expirationDate: null, pledgee: [<LicenseMember>{}], pledgor: [<LicenseMember>{}] }

      // codes used to solve the problems of already processed patents
      biblio.dOfPublication = biblio.dOfPublication || <DOfPublication>{
        date_41: null,
        date_42: null, date_44: null, date_45: null, date_46: null, date_47: null, date_48: null
      };
      newBiblio.dOfPublication = newBiblio.dOfPublication || <DOfPublication>{
        date_41: null,
        date_42: null, date_44: null, date_45: null, date_46: null, date_47: null, date_48: null
      };

      const newEnuropean = <EuropeanPatent>{
        spc92Number: null,
        spc92Date: null,
        spc92Country: this.config.europeanPatents.spc92Country.default || '',
        number: null,
        date: null,
        country: this.config.europeanPatents.country.default || '',
        expiredDate: null,
        patent: null,
        appNumber: null,
        appKind: null,
        appDate: null,
        appCountry: this.config.europeanPatents.appCountry.default || '',
        pubNumber: null,
        pubKind: null,
        pubDate: null,
        pubCountry: this.config.europeanPatents.pubCountry.default || ''
      };

      biblio.europeanPatents = biblio.europeanPatents ? (!Array.isArray(biblio.europeanPatents) ? [biblio.europeanPatents] : biblio.europeanPatents) : [JSON.parse(JSON.stringify(Object.assign(newEnuropean, biblio.spc || {})))];
      newBiblio.europeanPatents = newBiblio.europeanPatents ? (!Array.isArray(newBiblio.europeanPatents) ? [newBiblio.europeanPatents] : newBiblio.europeanPatents) : [JSON.parse(JSON.stringify(Object.assign(newEnuropean, newBiblio.spc || {})))];

      biblio.claims = biblio.claims ? biblio.claims : [<Claim>{ language: null, number: '1', text: null, screenShots: [] }]
      newBiblio.claims = newBiblio.claims ? newBiblio.claims : [<Claim>{ language: null, number: '1', text: null, screenShots: [] }]

      this.biblioData = JSON.parse(JSON.stringify(biblio));
      this.newBiblioData = JSON.parse(JSON.stringify(newBiblio));

    });

    this.configSubscribe = this.patentService.config$.subscribe((s) => this.configFun(s));
  }


  private configProcess(subCodesSub) {
    return new Observable(observer => {
      subCodesSub.subscribe(subCodes => {
        this.configs = [];
        subCodes.forEach(e => {
          const c: IConfig = JSON.parse(e.config);
          const category = this.Categories.find(con => con.key === c.legalEventCategory);
          this.configs.push(<ConfigItem>{
            id: e.id,
            countryCode: c.country,
            subCode: c.subCode,
            description: c.description,
            legalEventCategory: category ? category.value : '',
            st17Code: c.st17Code,
            sectionHeaderOrg: c.sectionHeaderOrg,
            sectionHeaderEng: c.sectionHeaderEng
          });
        }, this);
        this.subCodesDataSource = new DataSource({
          store: new ArrayStore({
            data: this.configs,
            key: 'id',
          }),
          group: 'legalEventCategory'
        });
        observer.next(this.configs);
      });
    });
  }

  private subCodesProcess(pdfsub, params) {
    return new Observable(observer => {
      pdfsub.subscribe((data) => {
        this.patentService.getSubCodes(data.countryCode).pipe(subcodes => this.configProcess(subcodes)).subscribe(
          (d) => {
            this.refreshList().subscribe(datasource => {
              this.patentsDataSource = datasource;
              if (!params.has('biblioId')) {
                this.createNewBiblio = true;
                this.changeSubcode.show(null);
              } else {
                this.patentService.getBiblio(Number.parseInt(params.get('biblioId'))).subscribe(da => {
                  this.createNewBiblio = false;
                  this.patentService.patent.next(da);
                });
              }
            });
          });
        observer.next(data);
      });
    })
  }

  setUrl(): void {
    this.pdfUrl = this.patentService.api_base_url + 'api/patentpdf/download/' + this.pdf.id;
  }

  createBiblio(cfg: IConfig): IBiblio {
    const nb = new IBiblio();
    nb.screenShots = [];
    nb.countryCode = cfg.country;
    nb.subCode = cfg.subCode;
    nb.publication.authority = cfg.publication.authority.default;
    nb.publication.kind = cfg.publication.kind.default || '';
    nb.publication.languageDesignation = cfg.publication.languageDesignation.default || '';
    nb.application.language = cfg.application.language.default;
    nb.priorities[0].country = cfg.priorities.country.default;
    nb.ipcs[0].edition = cfg.ipcs.edition.default;
    nb.ipcs[0].date = cfg.ipcs.date.default;
    nb.applicants[0].country = cfg.applicants.country.default;
    nb.applicants[0].language = cfg.applicants.language.default;
    nb.inventors[0].country = cfg.inventors.country.default;
    nb.inventors[0].language = cfg.inventors.language.default;
    nb.assignees[0].country = cfg.assignees.country.default;
    nb.assignees[0].language = cfg.assignees.language.default;
    nb.invAppGrants[0].country = cfg.invAppGrants.country.default;
    nb.invAppGrants[0].language = cfg.invAppGrants.language.default;
    nb.agents[0].country = cfg.agents.country.default;
    nb.agents[0].language = cfg.agents.language.default;
    nb.invOrApps[0].country = cfg.invOrApps.country.default;
    nb.invOrApps[0].language = cfg.invOrApps.language.default;
    nb.titles[0].language = cfg.titles.language.default;
    nb.abstracts[0].language = cfg.abstracts.language.default;
    nb.europeanPatents[0].spc92Country = cfg.europeanPatents.spc92Country.default;
    nb.europeanPatents[0].country = cfg.europeanPatents.country.default;
    nb.europeanPatents[0].appCountry = cfg.europeanPatents.appCountry.default;
    nb.europeanPatents[0].pubCountry = cfg.europeanPatents.pubCountry.default;
    nb.intConvention.pctApplCountry = cfg.intConvention.pctApplCountry.default;
    nb.intConvention.pctPublCountry = cfg.intConvention.pctPublCountry.default;
    nb.patentCitations[0].language = cfg.patentCitations.language.default;
    nb.nonPatentCitations[0].language = cfg.nonPatentCitations.language.default;
    nb.related[0].country = cfg.related.country ? cfg.related.country.default : '';
    nb.legalEvent.language = cfg.legalEvent && cfg.legalEvent.language ? cfg.legalEvent.language.default : '';
    nb.license.licensor[0].language = cfg.license.licensor.language.default;
    nb.license.licensor[0].country = cfg.license.licensor.country.default;
    nb.license.licensee[0].language = cfg.license.licensee.language.default;
    nb.license.licensee[0].country = cfg.license.licensee.country.default;
    nb.pledge.pledgor[0].language = cfg.pledge.pledgor.language.default;
    nb.pledge.pledgor[0].country = cfg.pledge.pledgor.country.default;
    nb.pledge.pledgee[0].language = cfg.pledge.pledgee.language.default;
    nb.pledge.pledgee[0].country = cfg.pledge.pledgee.country.default;
    nb.claims[0].language = cfg.claims.language.default;
    nb.claims[0].number = cfg.claims.number.default;
    return nb;
  }


  private configFun(s: any) {
    DataService.patchValue(this.config, s);
    const gazette = {
      countryCode: s.country,
      subCode: s.subCode,
      description: s.description,
      legalEventCategory: this.Categories.find(con => con.key === s.legalEventCategory).value.replace(/([A-Z])/g, ' $1'),
      st17Code: s.st17Code,
      sectionHeaderOrg: s.sectionHeaderOrg,
      sectionHeaderEng: s.sectionHeaderEng
    };

    Object.assign(this.gazetteInfo, gazette);
    if (this.createNewBiblio) {
      const biblio = this.createBiblio(this.config);
      this.biblioData = biblio.clone();
      this.newBiblioData = biblio.clone();
      this.createNewBiblio = false;
    }
  }

  public submit($event: any, status: number) {
    if (!this.biblioComponent.Valid) {
      return;
    } else {
      this.submitprocess(status);
    }
  }

  private submitprocess(status: number) {
    if (status === 4) {
      const dialogCompelete = confirm('Are you sure all the items on this pdf have been finished?', 'Confirm');
      dialogCompelete.then(dialogResultCompelete => {
        if (dialogResultCompelete) {
          this.save(status);
        }
      });
    } else if (status === 8) {
      const message = `Are you sure all the items on this section have been finished?
      Sub Code: ${this.config.subCode} St. 17 code: ${this.config.st17Code}\n Section heading original language: ${this.config.sectionHeaderOrg}\n Section heading text english:${this.config.sectionHeaderEng}`
      const dialogCompelete = confirm(message, 'Confirm');
      dialogCompelete.then(dialogResultCompelete => {
        if (dialogResultCompelete) {
          this.save(status);
        }
      });
    } else {
      this.save(status);
    }
  }

  private save(status: number) {
    this.submitnext.nativeElement.disabled = true;
    this.submitcomplete.nativeElement.disabled = true;
    this.patentService.postBibilio({
      PdfId: this.pdf.id,
      Id: this.biblioComponent.value.id,
      SubCode: this.config.subCode,
      SectionCode: this.config.st17Code,
      LegalEventCategory: this.config.legalEventCategory,
      Biblio: JSON.stringify(this.biblioComponent.value.orignal),
      newBiblio: JSON.stringify(this.biblioComponent.value.new),
      LegalEvent: JSON.stringify(this.biblioComponent.value.legalEvent),
      UserId: this.securityService.UserData.accountIdentifier,
      Status: status,
      Translations: JSON.stringify(this.biblioComponent.value.translations),
      CompeletedSection: (status === 8) ? JSON.stringify({ configId: this.config.id, subCode: this.config.subCode, compeleteTime: new Date() }) : null
    }).subscribe(
      (res) => {
        notify('Save Success', 'success', 2000);
        this.refreshList().subscribe(datasource => this.patentsDataSource = datasource);
        if (status === 4) {
          this.router.navigate(['pdf']);
        } else if (status === 8) {
          this.createNewBiblio = true;
          this.showSubCodeSelection();
        }
        else {
          const biblio = this.createBiblio(this.config);
          this.biblioData = biblio.clone();
          this.newBiblioData = biblio.clone();
        }
        this.submitnext.nativeElement.disabled = false;
        this.submitcomplete.nativeElement.disabled = false;
      },
      (errMessage) => {
        notify(errMessage, 'error', 2000);
        observableThrowError(errMessage);
        this.submitnext.nativeElement.disabled = false;
        this.submitcomplete.nativeElement.disabled = false;
      }
    );
  }

  showList() {
    this.refreshList().subscribe(datasource => this.patentsDataSource = datasource);
    this.patentList.show();
  }

  showSubCodeSelection() {
    const newvalue = this.biblioComponent.value;
    this.biblioData = newvalue.orignal;
    this.biblioData.legalEvent = newvalue.legalEvent;
    this.biblioData.id = newvalue.id;
    this.newBiblioData = newvalue.new;
    this.newBiblioData.id = newvalue.id;
    this.changeSubcode.show(this.config.subCode);
  }

  mouseover() {
    this.visiblediv.nativeElement.style.display = 'inline';
  }
  mouseleave() {
    this.visiblediv.nativeElement.style.display = 'none';
  }

  checkExist(data: PatentId): Observable<boolean> {
    const observer = new Subject<boolean>();
    const find = this.patents.filter(s => (data.pubNumber && s.pubNumber === data.pubNumber) ||
      (data.appNumber && s.appNumber === data.appNumber) ||
      (data.epPubNumber && s.epPubNumber && s.epPubNumber.split(/;[\s]*/).find(x => x === data.epPubNumber)) ||
      (data.epAppNumber && s.epAppNumber && s.epAppNumber.split(/;[\s]*/).find(x => x === data.epAppNumber)) ||
      (data.pctApplNumber && s.pctApplNumber === data.pctApplNumber) ||
      (data.pctPublNumber && s.pctPublNumber === data.pctPublNumber) ||
      (data.legalNumber && s.legalNumber === data.legalNumber));
    if (find && find.length > 0) {
      this.existList = find.map(v => {
        const item = Object.assign({}, v);
        return item;
      });

      this.existlistComponent.show();
      observer.next(true);
    } else {
      return Observable.create((ob) => ob.next(false));
    }

    return observer.asObservable();
  }

  createEventReturn(value: boolean) {
    this.changeSubcode.show(this.config.subCode);
  }

  exitEventReturn(value: boolean) {
    const biblio = this.createBiblio(this.config);
    this.biblioData = biblio.clone();
    this.newBiblioData = biblio.clone();
  }

  patentDeleted() {
    this.refreshList().subscribe(datasource => this.patentsDataSource = datasource);
  }

  refreshList() {
    return this.patentService.getPdf(this.pdf.id).pipe(pdfSub => new Observable<any[]>(subscribe => {
      pdfSub.subscribe(pdf => {
        subscribe.next(JSON.parse(pdf.sectionsCompeleted || '[]'))
      })
    }), compeletedSectionsSub => new Observable<PatentId[]>(subscribe => {
      compeletedSectionsSub.subscribe(compeletedSections => {
        return this.patentService.getProcessedPatents(this.pdf.id).subscribe(resut => {
          this.patents = [];
          resut.forEach(e => {
            const c = this.configs.find(con => con.subCode === e.subCode);
            const biblio = JSON.parse(e.dataBiblio);
            const legal = JSON.parse(e.dataLegal);
            const european = biblio.europeanPatents;
            const Completed = compeletedSections.find(complete => complete.SubCode === e.subCode);
            this.patents.push(<PatentId>{
              id: e.id,
              pubNumber: biblio.publication.number,
              appNumber: biblio.application.number,
              epPubNumber: european ? (Array.isArray(european) ? european.filter(x => x.pubNumber || false).map(x => x.pubNumber).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.pubNumber) : null,
              epAppNumber: european ? (Array.isArray(european) ? european.filter(x => x.appNumber || false).map(x => x.appNumber).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.appNumber) : null,
              legalNumber: legal ? legal.number : null,
              categoryId: e.legalEventCategoryId,
              category: `${e.subCode} ${c ? c.sectionHeaderEng : ''} ${Completed ? '(Completed)' : ''}`,
              subCode: e.subCode,
              pubDate: biblio.publication.date,
              appDate: biblio.application.date,
              epPubDate: european ? (Array.isArray(european) ? european.filter(x => x.pubDate || false).map(x => x.pubDate).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.pubDate) : null,
              epAppDate: european ? (Array.isArray(european) ? european.filter(x => x.appDate || false).map(x => x.appDate).reduce((x, y) => `${x}; ${y}`, '').replace(/^;[\s]*/, '') : european.appDate) : null,
              pctApplNumber: biblio.intConvention ? biblio.intConvention.pctApplNumber : null,
              pctPublNumber: biblio.intConvention ? biblio.intConvention.pctPublNumber : null,
              legalDate: legal ? legal.date : null,

            });
          }, this);
          subscribe.next(this.patents)
        });
      })
    }), patentsSub => new Observable<DataSource>(observer => {
      patentsSub.subscribe(patents => {
        observer.next(new DataSource({
          store: new ArrayStore({
            data: patents,
            key: 'id',
          }),
          group: ['category'],
          searchExpr: ["pubNumber",
            "appNumber",
            "epPubNumber",
            "epAppNumber",
            "legalNumber"
          ]
        }))
      });
    })
    );
  }

  ngOnDestroy(): void {
    this.patentSubscribe.unsubscribe();
    this.configSubscribe.unsubscribe();
    this.existSubscribe.unsubscribe();
    // this.changeSubcode = null;
  }

  toggleVisible() {

    this.visibleToggle ? this.biblioComponent.expandAll() : this.biblioComponent.collapseAll();
    this.visibleToggle = !this.visibleToggle;

  }

  showEventView() {
    const patent: Patent = { subCode: this.config.subCode, biblio: this.biblioComponent.value.orignal, newBiblio: this.biblioComponent.value.new, legalEvent: this.biblioComponent.value.legalEvent, pdf: this.pdf };
    this.eventView.show(patent, this.config);
  }
}
