import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxButtonModule, DxDataGridModule, DxFormModule, DxPopupModule, DxToolbarModule } from 'devextreme-angular';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { AccountService } from './account.service';

@NgModule({
  declarations: [AccountManagerComponent, AccountEditComponent],
  imports: [
    CommonModule,DxButtonModule, DxDataGridModule,DxToolbarModule, DxFormModule, DxPopupModule
  ],
  exports: [AccountManagerComponent],
  providers: [
    AccountService
  ]
})
export class AccountModule { }
