<accordion-group *ngIf="config && config.visible(stable)" [isOpen]="openToggle" (paste)="paste($event)"
    [ngClass]="{'heading-blue': !stable}">
    <div accordion-heading>
        {{headerText}}
        <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
    </div>
    <div class="row">
        <app-biblio-license-member style="width: 100%;" #pledgor headerText="Pledgor" [configname]="pledgor"
            [data]="data ? data.pledgor : null" [config]="config.pledgor" [stable]="true">
        </app-biblio-license-member>
    </div>
    <div class="row">
        <app-biblio-license-member style="width: 100%;" #pledgee headerText="Pledgee" [configname]="pledgee"
            [data]="data ? data.pledgee : null" [config]="config.pledgee" [stable]="true">
        </app-biblio-license-member>
    </div>
    <div [formGroup]="formGroup">
        <div class="row">
            <div class="col-md-3" *ngIf="config && config.effectiveDate.visible">
                <div class="form-group">
                    <label class="app-biblio-title">Effective Date</label>
                    <input id="effectiveDate" class="form-control form-input" type="text"
                        placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="effectiveDate">
                    <div *ngIf="formGroup.get('effectiveDate').hasError('required')  && formGroup.get('effectiveDate').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formGroup.get('effectiveDate').hasError('error')" class="alert alert-danger">Date format
                        not match</div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="config && config.expirationDate.visible">
                <div class="form-group">
                    <label class="app-biblio-title">Expiration Date</label>
                    <input id="expirationDate" class="form-control form-input" type="text"
                        placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="expirationDate">
                    <div *ngIf="formGroup.get('expirationDate').hasError('required')  && formGroup.get('expirationDate').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formGroup.get('expirationDate').hasError('error')" class="alert alert-danger">Date
                        format not match</div>
                </div>
            </div>
        </div>
    </div>
</accordion-group>