import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-license',
  templateUrl: './view-license.component.html',
  styleUrls: ['./view-license.component.scss']
})
export class ViewLicenseComponent implements OnInit {
  @Input() data: any;
  @Input() config: any;
  @Input() stable: boolean;
  @Input() headerText: string;
  constructor() { }

  ngOnInit() {
  }

}
