import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { promise } from 'protractor';
import { PatentService } from '../patent.service';

@Component({
  selector: 'app-event-validate',
  templateUrl: './event-validate.component.html',
  styleUrls: ['./event-validate.component.scss']
})
export class EventValidateComponent implements OnInit {
  eventsDataSource: any = {};
  constructor(private patentService: PatentService, private router: Router) { 
    this.eventsDataSource.store = new CustomStore({
      load: async (loadOptions: any) => {
        let params = '?';
        params += 'skip=' + (loadOptions.skip || 0);
        params += '&take=' + (loadOptions.take || 12);
        params += loadOptions.filter ? '&filter=' + JSON.stringify(loadOptions.filter) : '';
        params += loadOptions.sort ? '&sort=' + JSON.stringify(loadOptions.sort) : '';
        params += '&requireTotalCount=true';
        return this.patentService.getValidations(params).toPromise();
      }
    });
  }

  edit(data: any) {
    this.router.navigate(['patent', { pdfId: data.pdfId, biblioId: data.legalEventStatusId, validateId: data.id }]);
  }

  ngOnInit() {
  }

}
