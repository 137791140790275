import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';
import { BiblioBaseComponent } from '../biblio-base.component';

@Component({
  selector: 'app-biblio-application',
  templateUrl: './biblio-application.component.html',
  styleUrls: ['./biblio-application.component.scss']
})
export class BiblioApplicationComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  private previousValue = '';
  @Input() biblioId?: number;

  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      (this.formGroup as AbstractControl).patchValue(this.data);
    }
    if (this.data && this.config && changes.config && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      this.setValidator(this.formGroup, this.config, this.config.ConfigName);
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      number: '',
      language: '',
      otherDate: '',
      date: '',
      effectiveDate: ''
    });

    this.formGroup.get('number').valueChanges.subscribe(value => this.checkexist(value));
  }

  private checkexist(value: any) {
    if (!this.biblioId && this.previousValue !== value) {
      this.previousValue = value;
      this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, appNumber: value });
    }
  }

}
