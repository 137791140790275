<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.domestic.visible && item.domestic">
      <label>(52) {{headerText}}</label>
      <div class="value"> {{item.domestic}} </div>
    </div>
    <div *ngIf="config.class.visible && item.class">
      <label>(51) {{headerText}}Classification</label>
      <div class="value">{{item.class}}</div>
    </div>
    <div *ngIf="config.date.visible && item.date">
      <label>(51) {{headerText}}IPC Version</label>
      <div class="value">{{item.date}}</div>
    </div>
    <div *ngIf="config.edition.visible && item.edition">
      <label>(51) {{headerText}}IPC Edition</label>
      <div class="value">{{item.edition}}</div>
    </div>
  </div>
</div>