import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';

@Component({
  selector: 'app-existlist',
  templateUrl: './existlist.component.html',
  styleUrls: ['./existlist.component.scss']
})
export class ExistlistComponent implements OnInit {
  @Output() createEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() exitEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() patents: Array<PatentId>;
  popupVisible: boolean;
  constructor(private service: PatentService) { }

  ngOnInit() {
  }

  show() {
    this.popupVisible = true;
  }

  loadBiblio(id: number) {
    this.service.getBiblio(id).subscribe(data => {
      this.service.patent.next(data);
      this.popupVisible = false;
    });
  }

  create() {
    this.createEvent.emit(true);
    this.popupVisible = false;
  }

  exit() {
    this.exitEvent.emit(true);
    this.popupVisible = false;
  }
}
