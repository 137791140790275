export const environment = {
  production: true,
  api_base_url: '/',
  reportUrl: '/report/',
  client_id: '6cd08f22-bc2e-4bce-afd0-3e75714219a6',
  tenant_id: '8e75c5ad-49d7-4aab-a36a-15cb4aed6043',
  msal_diamond_api: 'api://19a514bb-d100-4b05-8f27-362db4b9beca/diamond.api',
  msal_diamond_report: 'api://fb70a568-4d9f-4938-a9c8-32469d516ef7/diamond.report',
  client_redirect_uri: 'https://diamond.lighthouseip.online',
  ws_conn_str: 'wss://diamond.lighthouseip.online/report'
};
