<div class="app-event-validate container">
  <h4>Events Validation Details</h4>
  <dx-data-grid [showBorders]="true" [showRowLines]="true" [dataSource]="eventsDataSource" [hoverStateEnabled]="true" [allowColumnResizing]="true">
      <dxi-column dataField="countryCode"></dxi-column>
      <dxi-column dataField="subCode" sortOrder="asc"></dxi-column>
      <dxi-column dataField="fileName"></dxi-column>
      <dxi-column dataField="pubNumber"></dxi-column>
      <dxi-column dataField="pubKind"></dxi-column>
      <dxi-column dataField="appNumber"></dxi-column>
      <dxi-column caption="Actions" cellTemplate="actionTemplate">
      </dxi-column>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true"></dxo-remote-operations>
      <dxo-paging [pageSize]="12"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
      <dxo-filter-row [visible]="true" applyFilter="auto"></dxo-filter-row>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-state-storing [enabled]="true" type="localStorage" savingTimeout="500" storageKey="validateGridState"></dxo-state-storing>

      <div *dxTemplate="let info of 'actionTemplate'">
          <button (click)="edit(info.data)" class="btn btn-link btn-success btn-sm">
              <span class="dx-icon-edit"></span>Edit
          </button>
      </div>
  </dx-data-grid>
</div>
