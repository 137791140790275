<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngIf="config.pctApplNumber.visible">
    <label>(86) {{headerText}}PCT App. Number</label>
    <div class="value">{{data.pctApplNumber}}</div>
  </div>
  <div *ngIf="config.pctApplKind.visible">
    <label>(86) {{headerText}}PCT App. Kind</label>
    <div class="value">{{data.pctApplKind}}</div>
  </div>
  <div *ngIf="config.pctApplDate.visible">
    <label>(86) {{headerText}}PCT App. Date</label>
    <div class="value">{{data.pctApplDate}}</div>
  </div>
  <div *ngIf="config.pctApplCountry.visible">
    <label>(86) {{headerText}}PCT Application Country</label>
    <div class="value">{{data.pctApplCountry}}</div>
  </div>

  <div *ngIf="config.pctPublNumber.visible">
    <label>(87) {{headerText}}PCT Pub. Number</label>
    <div class="value">{{data.pctPublNumber}}</div>
  </div>
  <div *ngIf="config.pctPublKind.visible">
    <label>(87) {{headerText}}PCT Pub. Kind</label>
    <div class="value">{{data.pctPublKind}}</div>
  </div>
  <div *ngIf="config.pctPublDate.visible">
    <label>(87) {{headerText}}PCT Pub. Date</label>
    <div class="value">{{data.pctPublDate}}</div>
  </div>
  <div *ngIf="config.pctPublCountry.visible">
    <label>(87) {{headerText}}PCT Publication Country</label>
    <div class="value">{{data.pctPublCountry}}</div>
  </div>

  <div *ngIf="config.pctSearchDate.visible">
    <label>(87) {{headerText}}Date of deferred publication of the search report</label>
    <div class="value">{{data.pctSearchDate}}</div>
  </div>


  <div *ngIf="config.pctNationalDate.visible">
    <label>(85) {{headerText}}</label>
    <div class="value">{{data.pctNationalDate}}</div>
  </div>

  <div *ngIf="config.designatedStates.state.visible">
    <label>(84) {{headerText}}</label>
    <div class="value">
      <div class="line_item" *ngFor="let item of data.designatedStates ">{{item}}</div>
    </div>
  </div>
</div>