<accordion-group [formGroup]="formGroup" #group15 *ngIf="config &&  config.visible(stable)" [isOpen]="openToggle" (paste)="paste($event)"
    [ngClass]="{'heading-blue': !stable}">
    <div accordion-heading>
        {{headerText}}
        <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
    </div>
    <div formArrayName="formArray" class="well well-lg">
        <div *ngFor="let item of formArray.controls; let i=index" [formGroupName]="i" class="row">
            <div class="col-md-6" *ngIf="config && config.spc92Number.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(92) SPC Number</label>
                    <input id="{{i}}-spc92Number" class="form-control form-input" type="text" formControlName="spc92Number">
                    <div *ngIf="formArray.at(i).get('spc92Number').hasError('required') && formArray.at(i).get('spc92Number').touched"
                        class="alert alert-danger">Required field.</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.spc92Date.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(92) SPC Date</label>
                    <input id="{{i}}-spc92Date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                        formControlName="spc92Date">
                    <div *ngIf="formArray.at(i).get('spc92Date').hasError('required')  && formArray.at(i).get('spc92Date').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formArray.at(i).get('spc92Date').hasError('error')" class="alert alert-danger">Date
                        format
                        not
                        match</div>
                </div>
            </div>
            <div class="col-md-2" *ngIf="config && config.spc92Country.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(92) SPC Country</label>
                    <input id="{{i}}-spc92Country" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                        formControlName="spc92Country">
                    <div *ngIf="formArray.at(i).get('spc92Country').hasError('required')  && formArray.at(i).get('spc92Country').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formArray.at(i).get('spc92Country').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="config && config.number.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(93) SPC Number</label>
                    <input id="{{i}}-number" class="form-control form-input" type="text" formControlName="number">
                    <div *ngIf="formArray.at(i).get('number').hasError('required') && formArray.at(i).get('number').touched"
                        class="alert alert-danger">Required
                        field.</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.date.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(93) SPC Date</label>
                    <input id="{{i}}-date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                        formControlName="date">
                    <div *ngIf="formArray.at(i).get('date').hasError('required')  && formArray.at(i).get('date').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('date').hasError('error')" class="alert alert-danger">Date format
                        not
                        match</div>
                </div>
            </div>
            <div class="col-md-2" *ngIf="config && config.country.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(93) SPC Country</label>
                    <input id="{{i}}-country" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                        formControlName="country">
                    <div *ngIf="formArray.at(i).get('country').hasError('required')  && formArray.at(i).get('country').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('country').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.expiredDate.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(94) SPC Expired Date</label>
                    <input id="{{i}}-expiredDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                        formControlName="expiredDate">
                    <div *ngIf="formArray.at(i).get('expiredDate').hasError('required')  && formArray.at(i).get('expiredDate').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formArray.at(i).get('expiredDate').hasError('error')" class="alert alert-danger">Date
                        format
                        not
                        match</div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="config && config.patent.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(95) SPC Protected by the basic patent</label>
                    <input id="{{i}}-patent" class="form-control form-input" type="text" formControlName="patent">
                    <div *ngIf="formArray.at(i).get('patent').hasError('required')  && formArray.at(i).get('patent').touched"
                        class="alert alert-danger">Required
                        field.</div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="config && config.appNumber.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(96) Application Number</label>
                    <input id="{{i}}-appNumber" class="form-control form-input" type="text" formControlName="appNumber">
                    <div *ngIf="formArray.at(i).get('appNumber').hasError('required') && formArray.at(i).get('appNumber').touched"
                        class="alert alert-danger">Required field.</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.appKind.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(96) Application Kind</label>
                    <input id="{{i}}-appKind" class="form-control form-input" type="text" placeholder="^[A-Z][0-9]?$"
                        formControlName="appKind">
                    <div *ngIf="formArray.at(i).get('appKind').hasError('required') && formArray.at(i).get('appKind').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('appKind').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.appDate.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(96) Application Date</label>
                    <input id="{{i}}-appDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                        formControlName="appDate">
                    <div *ngIf="formArray.at(i).get('appDate').hasError('required')  && formArray.at(i).get('appDate').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('appDate').hasError('error')" class="alert alert-danger">Date
                        format not
                        match</div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="config && config.appCountry.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(96) Application Country</label>
                    <input id="{{i}}-appCountry" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                        formControlName="appCountry">
                    <div *ngIf="formArray.at(i).get('appCountry').hasError('required')  && formArray.at(i).get('appCountry').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formArray.at(i).get('appCountry').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="config && config.pubNumber.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(97) Publication Number </label>
                    <input id="{{i}}-pubNumber" class="form-control form-input" type="text" formControlName="pubNumber">
                    <div *ngIf="formArray.at(i).get('pubNumber').hasError('required')  && formArray.at(i).get('pubNumber').touched"
                        class="alert alert-danger">Required field.</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.pubKind.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(97) Publication Kind</label>
                    <input id="{{i}}-pubKind" class="form-control form-input" type="text" placeholder="^[A-Z][0-9]?$"
                        formControlName="pubKind">
                    <div *ngIf="formArray.at(i).get('pubKind').hasError('required') && formArray.at(i).get('pubKind').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('pubKind').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="config && config.pubDate.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(97) Publication Date </label>
                    <input id="{{i}}-pubDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                        formControlName="pubDate">
                    <div *ngIf="formArray.at(i).get('pubDate').hasError('required')  && formArray.at(i).get('pubDate').touched"
                        class="alert alert-danger">Required
                        field.</div>
                    <div *ngIf="formArray.at(i).get('pubDate').hasError('error')" class="alert alert-danger">Date
                        format not
                        match</div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="config && config.pubCountry.visible">
                <div class="form-group">
                    <label class="app-biblio-title">(97) Publication Country</label>
                    <input id="{{i}}-pubCountry" class="form-control form-input" type="text" placeholder="[A-Z]{2}"
                        formControlName="pubCountry">
                    <div *ngIf="formArray.at(i).get('pubCountry').hasError('required')  && formArray.at(i).get('pubCountry').touched"
                        class="alert alert-danger">Required field.</div>
                    <div *ngIf="formArray.at(i).get('pubCountry').hasError('pattern')" class="alert alert-danger">Pattern
                        not
                        matched</div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group align-text-bottom">
                    <a (click)="deleteFormArray(i)" class="dx-icon-remove"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addFormArray()"> Add</button>
        </div>
    </div>
</accordion-group>