<accordion-group *ngIf="config && config.visible(stable)" [isOpen]="openToggle" (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
       {{headerText}}
      <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div [formGroup]="formGroup" class="row">
      <div class="col-md-3" *ngIf="config && config.number.visible">
          <div class="form-group">
              <label class="app-biblio-title">(21) Appl. Number</label>
              <input id="number" class="form-control form-input" type="text" formControlName="number" placeholder="{{config.number.pattern}}">
              <div *ngIf="formGroup.get('number').hasError('required')  && formGroup.get('number').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('number').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>
      </div>
      <div class="col-md-3" *ngIf="config && config.date.visible">
          <div class="form-group">
              <label class="app-biblio-title">(22) Appl. Date</label>
              <input id="date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="date">
              <div *ngIf="formGroup.get('date').hasError('required')  && formGroup.get('date').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('date').hasError('error')" class="alert alert-danger">Date format not match</div>
          </div>
      </div>
      <div class="col-md-3" *ngIf="config && config.otherDate.visible">
          <div class="form-group">
              <label class="app-biblio-title">(23) Other. Date</label>
              <input id="otherDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD" formControlName="otherDate">
              <div *ngIf="formGroup.get('otherDate').hasError('required')  && formGroup.get('otherDate').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('otherDate').hasError('error')" class="alert alert-danger">Date format not match</div>
          </div>
      </div>
      <div class="col-md-3" *ngIf="config && config.effectiveDate.visible">
          <div class="form-group">
              <label class="app-biblio-title">(24) Effective date</label>
              <input id="effectiveDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM[\s\.\-]DD"
                  formControlName="effectiveDate">
              <div *ngIf="formGroup.get('effectiveDate').hasError('required')  && formGroup.get('effectiveDate').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('effectiveDate').hasError('error')" class="alert alert-danger">Date format not match</div>
          </div>
      </div>
      <div class="col-md-2" *ngIf="config && config.language.visible">
          <div class="form-group">
              <label class="app-biblio-title">(25) Language</label>
              <input id="language" class="form-control form-input" type="text" placeholder="[A-Z]{2}" formControlName="language">
              <div *ngIf="formGroup.get('language').hasError('required')  && formGroup.get('language').touched" class="alert alert-danger">Required field.</div>
              <div *ngIf="formGroup.get('language').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
          </div>
      </div>
  </div>
</accordion-group>