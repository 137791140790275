import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-d-of-publication',
  templateUrl: './view-d-of-publication.component.html',
  styleUrls: ['./view-d-of-publication.component.scss']
})
export class ViewDOfPublicationComponent implements OnInit {
  @Input() data: any;
  @Input() config: any;
  @Input() stable: boolean;
  @Input() headerText: string;
  constructor() { }

  ngOnInit() {
  }

  get visible() {
    return this.config && (
      this.config.dOfPublication.visible(this.stable) || (
        this.stable ? this.config.publication.date.visible : (
          this.config.dOfPublication.new && this.config.publication.date.visible
        )
      )
    );
  }
}
