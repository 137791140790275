
export class Title {
  language: string;
  text: string;
  translations: Translation[]
}

export class Publication {
  authority?: string;
  number?: string;
  kind?: string;
  date?: string;
  language?: string;
  languageDesignation?: string;
}

export class Application {
  number: string;
  date: string;
  otherDate: string;
  language: string;
  effectiveDate: string;
}

export class Priority {
  sequence: number;
  country: string;
  number: string;
  date: string;
}

export class Abstract {
  language: string;
  text: string;
  translations: Translation[]
}

export class Claim {
  language: string;
  number: string;
  text: string;
  translations: Translation[];
  screenShots?: any[];
}

export class Translation {
  type: string;
  language: string;
  org: string;
  tr: string;
}

export class PartyTranslation {
  type: string;
  language: string;
  orgName: string;
  trName: string;
  orgAddress1: string;
  trAddress1: string
}

export class PartyMember {
  language: string;
  name: string;
  firstName: string;
  lastName: string;
  address1: string;
  postCode: string;
  city: string;
  country: string;
  translations: PartyTranslation[];
}

export class Ipc {
  edition: number;
  class: string;
  date: string;
  ipcDate: string;
  domestic: string;
}

export class Ipcr {
  sequence: number;
  classRaw: string;
  section: string;
  subclass: string;
  maingroup: string;
  subgroup: string;
  classscheme: string;
  classValue: string;
  actionDate: string;
  classStatus: string;
  classDataSource: string;
  office: string;
}

export class Cpc {
  sequence: number;
  group: string;
  rank: string;
  office: string;
  schema: string;
  schemaDate: string;
  class: string;
  position: string;
  value: string;
  status: string;
  source: string;
  generatingOffice: string;
  actionDate: string;
}

export class IntConvention {
  pctNationalDate: string;
  pctApplNumber: string;
  pctApplKind: string;
  pctApplDate: string;
  pctApplCountry: string;
  pctPublNumber: string;
  pctPublKind: string;
  pctPublDate: string;
  pctPublCountry: string;
  pctSearchDate: string;
  designatedStates: string[];
}

export class PatentCitation {
  sequence: number;
  authority: string;
  number: string;
  kind: string;
  date: string;
  applicant: string;
  language: string;
  translations: Translation[]
}

export class NonPatentCitation {
  sequence: number;
  text: string;
  xpNumber: string;
  language: string;
  translations: Translation[]
}

export class RelatedDocument {
  source: string;
  format: string;
  type: string;
  number: string;
  date: string;
  country: string;
}

export class EuropeanPatent {
  spc92Number: string;
  spc92Date: string;
  spc92Country: string;
  number: string;
  date: string;
  country: string;
  expiredDate: string;
  patent: string;
  appNumber: string;
  appKind: string;
  appDate: string;
  appCountry: string;
  pubNumber: string;
  pubKind: string;
  pubDate: string;
  pubCountry: string;
}

export class LegalEvent {
  number: string;
  date: string;
  note: string;
  language: string;
  translations: Translation[];
}

export class License {
  number: string;
  period: string;
  licensor: LicenseMember[];
  licensee: LicenseMember[];
}

export class Pledge {
  effectiveDate: string;
  expirationDate: string;
  pledgor: LicenseMember[];
  pledgee: LicenseMember[];
}

export class LicenseTranslation {
  type: string;
  language: string;
  orgName: string;
  trName: string;
  orgAddress: string;
  trAddress: string
}

export class LicenseMember {
  language: string;
  name: string;
  address: string;
  country: string;
  translations: LicenseTranslation[];
}

export class SPC {
  number: string;
  date: string;
  expiredDate: string;
  patent: string;
}

export class DOfPublication {
  date_41: string;
  date_42: string;
  date_43: string;
  date_44: string;
  date_45: string;
  date_46: string;
  date_47: string;
  date_48: string;
}


export class IBiblio {
  screenShots?: any[] = [];
  id?: number;
  countryCode?: string;
  subCode?: string;
  publication?: Publication;
  application?: Application;
  priorities?: Priority[];
  dOfPublication?: DOfPublication;
  titles?: Title[];
  abstracts?: Abstract[];
  applicants?: PartyMember[];
  inventors?: PartyMember[];
  assignees?: PartyMember[];
  agents?: PartyMember[];
  invOrApps?: PartyMember[];
  invAppGrants?: PartyMember[];

  ipcs?: Ipc[];
  ipcrs?: Ipcr[];
  cpcs?: Cpc[];
  intConvention?: IntConvention;
  designatedStates?: string[];
  patentCitations?: PatentCitation[];
  nonPatentCitations?: NonPatentCitation[];
  related?: RelatedDocument[];
  europeanPatents?: EuropeanPatent[];
  images?: any[];
  legalEvent?: LegalEvent;
  license: License;
  pledge: Pledge;
  licensor: LicenseMember[];
  licensee: LicenseMember[];
  spc?: SPC;
  claims?: Claim[];


  constructor() {
    this.publication = <Publication>{ authority: null, date: null, language: null, kind: null, number: null, languageDesignation: null };
    this.application = <Application>{ date: null, language: null, number: null, otherDate: null, effectiveDate: null };
    this.priorities = [
      <Priority>{ country: null, number: null, date: null, sequence: 0 }
    ];
    this.dOfPublication = <DOfPublication>{
      date_41: null,
      date_42: null,
      date_43: null,
      date_44: null,
      date_45: null,
      date_46: null,
      date_47: null,
      date_48: null
    };
    this.titles = [
      <Title>{ language: null, text: null, translations: [] }
    ];
    this.abstracts = [
      <Abstract>{ language: null, text: null, translations: [] }
    ];
    this.claims = [<Claim>{language: null, text: null, number: "1", screenShots: [], translations: [] }];
    this.applicants = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.inventors = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.assignees = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.invOrApps = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.invAppGrants = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.agents = [
      <PartyMember>{
        address1: null, city: null, country: null,
        firstName: null, language: null, lastName: null, name: null, postCode: null,
        translations: []
      }
    ];
    this.ipcs = [
      <Ipc>{ class: null, date: null, ipcDate: null, edition: null }
    ];
    this.ipcrs = [
      <Ipcr>{
        actionDate: null, classDataSource: null, classRaw: null,
        classscheme: null, classStatus: null, classValue: null, maingroup: null, office: null,
        section: null, sequence: 0, subclass: null, subgroup: null
      }
    ];
    this.cpcs = [
      <Cpc>{
        actionDate: null, class: null, generatingOffice: null, group: null,
        office: null, position: null, rank: null, schema: null, schemaDate: null, sequence: null,
        source: null, status: null, value: null
      }
    ];
    this.intConvention = <IntConvention>{
      pctApplDate: null, pctApplNumber: null,
      pctNationalDate: null, pctPublDate: null, pctPublNumber: null, pctPublKind: null, pctApplCountry: null, pctApplKind: null, pctPublCountry: null, pctSearchDate: null, designatedStates: [],
    };
    this.patentCitations = [
      <PatentCitation>{
        applicant: null, authority: null, language: null,
        date: null, kind: null, number: null, sequence: 0,
        translations: []
      }
    ];
    this.nonPatentCitations = [
      <NonPatentCitation>{ sequence: null, text: null, language: null, xpNumber: null, translations: [] }
    ];
    this.related = [
      <RelatedDocument>{ date: null, format: null, number: null, source: null, type: null }
    ];
    this.images = [];
    this.europeanPatents = [<EuropeanPatent>{
      appNumber: null, appKind: null, appDate: null, appCountry: null, pubDate: null, pubNumber: null, pubKind: null, pubCountry: null,
      spc92Number: null, spc92Date: null, spc92Country: null, date: null, number: null, country: null, expiredDate: null, patent: null,
    }];
    this.legalEvent = <LegalEvent>{ date: null, number: null, note: null, language: null, translations: [] };
    this.license = <License>{
      period: null, number: null, licensor: [
        <LicenseMember>{ name: null, address: null, language: null, country: null, translations: [] }
      ], licensee: [
        <LicenseMember>{ name: null, address: null, language: null, country: null, translations: [] }
      ]
    };
    this.pledge = <Pledge>{
      effectiveDate: null, expirationDate: null, pledgor: [
        <LicenseMember>{ name: null, address: null, language: null, country: null, translations: [] }
      ], pledgee: [
        <LicenseMember>{ name: null, address: null, language: null, country: null, translations: [] }
      ]
    };
    this.spc = <SPC>{ date: null, number: null, expiredDate: null, patent: null };
  }

  clone() {
    return JSON.parse(JSON.stringify(this));
  }
}
