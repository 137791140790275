<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.name.visible && item.name">
      <label>{{headerText}}Name</label>
      <div class="value">{{item.name}}</div>
    </div>
    <div *ngIf="config.country.visible && item.country">
      <label>{{headerText}}Country</label>
      <div class="value">{{item.country}}</div>
    </div>
    <div *ngIf="config.language.visible && item.language">
      <label>{{headerText}}Language</label>
      <div class="value">{{item.language}}</div>
    </div>
    <div *ngIf="config.address1.visible && item.address1">
      <label>{{headerText}} Address</label>
      <div class="value">{{item.address1}}</div>
    </div>
    <div *ngIf="config.postCode.visible && item.postCode">
      <label>{{headerText}} Post Code</label>
      <div class="value">{{item.postCode}}</div>
    </div>
    <div *ngIf="config.city.visible && item.city">
      <label>{{headerText}} City</label>
      <div class="value">{{item.city}}</div>
    </div>

    <div *ngFor="let tr of item.translations">
      <div *ngIf="config.translations.language.visible && tr.language">
        <label>{{headerText}} Language</label>
        <div class="value">{{tr.language}}</div>
      </div>
      <div *ngIf="config.translations.trName.visible && tr.trName">
        <label>{{headerText}} Name</label>
        <div class="value">{{tr.trName}}</div>
      </div>
      <div *ngIf="config.translations.trAddress1.visible && tr.trAddress1">
        <label>{{headerText}} Address</label>
        <div class="value">{{tr.trAddress1}}</div>
      </div>
    </div>
  </div>
</div>