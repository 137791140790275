<div #scrollview class="app-biblio h-100" style="overflow-x: auto;">
    <!-- <div>{{value | json}}</div> -->
    <accordion>
        <app-biblio-publication #Publication headerText="(10) Publication Information" [biblioId]="biblio ? biblio.id: null " [data]="biblio ? biblio.publication : null" [config]="config.publication" [stable]="true"></app-biblio-publication>
        <app-biblio-publication #PublicationNew headerText="(10) Publication Information (New)" [biblioId]="newBiblio ? newBiblio.id: null " [data]="newBiblio ? newBiblio.publication : null" [config]="config.publication" [stable]="false"></app-biblio-publication>
        <app-biblio-application #Application headerText="(20) Application Information" [biblioId]="biblio ? biblio.id: null " [data]="biblio ? biblio.application : null" [config]="config.application" [stable]="true"></app-biblio-application>
        <app-biblio-application #ApplicationNew headerText="(20) Application Information (New)" [biblioId]="newBiblio ? newBiblio.id: null " [data]="newBiblio ? newBiblio.application : null" [config]="config.application" [stable]="false"></app-biblio-application>
        <app-biblio-priorities #Priorities headerText="(30) Priority Information" [data]="biblio ? biblio.priorities : null" [config]="config.priorities" [stable]="true"></app-biblio-priorities>
        <app-biblio-priorities #PrioritiesNew headerText="(30) Priority Information (New)" [data]="newBiblio ? newBiblio.priorities : null" [config]="config.priorities" [stable]="false"></app-biblio-priorities>
        
        <app-biblio-d-of-publication #DOfPublication headerText="(40) Dates of making available to the public" [data]="biblio ? biblio : null" [config]="config" [stable]="true"></app-biblio-d-of-publication>
        <app-biblio-d-of-publication #DOfPublicationNew headerText="(40) Dates of making available to the public (New)" [data]="newBiblio ? newBiblio : null" [config]="config" [stable]="false"></app-biblio-d-of-publication>
        
        <app-biblio-ipcs #Ipcs headerText="(50) Classification Information" [data]="biblio ? biblio.ipcs : null" [config]="config.ipcs" [stable]="true"></app-biblio-ipcs>
        <app-biblio-ipcs #IpcsNew headerText="(50) Classification Information (New)" [data]="newBiblio ? newBiblio.ipcs : null" [config]="config.ipcs" [stable]="false"></app-biblio-ipcs>
        
        <app-biblio-parties nameType="71" #Applicants headerText="(71) Applicant Information" [data]="biblio ? biblio.applicants : null" [config]="config.applicants" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="71" #ApplicantsNew headerText="(71) Applicant Information"  [data]="newBiblio ? newBiblio.applicants : null" [config]="config.applicants" [stable]="false"></app-biblio-parties>
        
        <app-biblio-parties nameType="72" #Inventors headerText="(72) Inventor Information" [data]="biblio ? biblio.inventors : null" [config]="config.inventors" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="72" #InventorsNew headerText="(72) Inventor Information"  [data]="newBiblio ? newBiblio.inventors : null" [config]="config.inventors" [stable]="false"></app-biblio-parties>
        
        <app-biblio-parties nameType="73" #Assignees headerText="(73) Grantee/Assignee/Owner Information" [data]="biblio ? biblio.assignees : null" [config]="config.assignees" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="73" #AssigneesNew headerText="(73) Grantee/Assignee/Owner Information"  [data]="newBiblio ? newBiblio.assignees : null" [config]="config.assignees" [stable]="false"></app-biblio-parties>
        
        <app-biblio-parties nameType="74" #Agents headerText="(74) Agent Information" [data]="biblio ? biblio.agents : null" [config]="config.agents" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="74" #AgentsNew headerText="(74) Agent Information"  [data]="newBiblio ? newBiblio.agents : null" [config]="config.agents" [stable]="false"></app-biblio-parties>
        
        <app-biblio-parties nameType="75" #InvOrApps headerText="(75) Inventor/Applicant Information" [data]="biblio ? biblio.invOrApps : null" [config]="config.invOrApps" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="75" #InvOrAppsNew headerText="(75) Inventor/Applicant Information"  [data]="newBiblio ? newBiblio.invOrApps : null" [config]="config.invOrApps" [stable]="false"></app-biblio-parties>
        
        <app-biblio-parties nameType="76" #InvAppGrants headerText="(76) Inventor/Applicant/Grantee Information" [data]="biblio ? biblio.invAppGrants : null" [config]="config.invAppGrants" [stable]="true"></app-biblio-parties>
        <app-biblio-parties nameType="76" #InvAppGrantsNew headerText="(76) Inventor/Applicant/Grantee Information"  [data]="newBiblio ? newBiblio.invAppGrants : null" [config]="config.invAppGrants" [stable]="false"></app-biblio-parties>
        
        <app-biblio-titles nameType="54" headerText="(54) Title" #Titles [data]="biblio ? biblio.titles : null" [config]="config.titles" [stable]="true"></app-biblio-titles>
        <app-biblio-titles nameType="54" headerText="(54) Title (New)" #TitlesNew [data]="newBiblio ? newBiblio.titles : null" [config]="config.titles" [stable]="false"></app-biblio-titles>
        
        <app-biblio-abstracts nameType="57" headerText="(57) Abstract" #Abstracts [data]="biblio ? biblio.abstracts : null" [screenShots]="biblio ? biblio.screenShots : null" [config]="config.abstracts" [stable]="true"></app-biblio-abstracts>
        <app-biblio-abstracts nameType="57" headerText="(57) Abstract (New)" #AbstractsNew [data]="newBiblio ? newBiblio.abstracts : null" [screenShots]="newBiblio ? newBiblio.screenShots : null" [config]="config.abstracts" [stable]="false"></app-biblio-abstracts>
        
        <app-biblio-int-convention  headerText="(84) (85) (86) (87) PCT National Phase Information" #IntConvention [biblioId]="biblio ? biblio.id: null " [data]="biblio ? biblio.intConvention : null" [config]="config.intConvention" [stable]="true"></app-biblio-int-convention>
        <app-biblio-int-convention  headerText="(84) (85) (86) (87) PCT National Phase Information (New)" #IntConventionNew [biblioId]="newBiblio ? newBiblio.id: null " [data]="newBiblio ? newBiblio.intConvention : null" [config]="config.intConvention" [stable]="false"></app-biblio-int-convention>
<!--         
        <app-biblio-spc headerText="(93)(94)(95) SPC Information" #Spc [data]="biblio ? biblio.spc : null" [config]="config.spc" [stable]="true"></app-biblio-spc>
        <app-biblio-spc headerText="(93)(94)(95) SPC Information (New)" #SpcNew [data]="newBiblio ? newBiblio.spc : null" [config]="config.spc" [stable]="false"></app-biblio-spc>
         -->
        <app-biblio-european-patents #EuropeanPatents headerText="(92) (93) (94) (95) (96) (97) European Patents" [biblioId]="biblio ? biblio.id: null "  [data]="biblio ? biblio.europeanPatents : null" [config]="config.europeanPatents" [stable]="true"></app-biblio-european-patents>
        <app-biblio-european-patents #EuropeanPatentsNew headerText="(92) (93) (94) (95) (96) (97) European Patents (New)" [biblioId]="newBiblio ? newBiblio.id: null " [data]="newBiblio ? newBiblio.europeanPatents : null" [config]="config.europeanPatents" [stable]="false"></app-biblio-european-patents>
        
        <app-biblio-patent-citations nameType="561" headerText="(56) Patent Citation Details" #PatentCitations [data]="biblio ? biblio.patentCitations : null" [config]="config.patentCitations" [stable]="true"></app-biblio-patent-citations>
        <app-biblio-patent-citations nameType="561" headerText="(56) Patent Citation Details (New)" #PatentCitationsNew [data]="newBiblio ? newBiblio.patentCitations : null" [config]="config.patentCitations" [stable]="false"></app-biblio-patent-citations>
        
        <app-biblio-non-patent-citations nameType="562" headerText="(56) Non Patent Citation Details" #NonPatentCitations [data]="biblio ? biblio.nonPatentCitations : null" [config]="config.nonPatentCitations" [stable]="true"></app-biblio-non-patent-citations>
        <app-biblio-non-patent-citations nameType="562" headerText="(56) Non Patent Citation Details (New)" #NonPatentCitationsNew [data]="newBiblio ? newBiblio.nonPatentCitations : null" [config]="config.nonPatentCitations" [stable]="false"></app-biblio-non-patent-citations>
        
        <app-biblio-related #Related headerText="(6x) Related Publication Information" [data]="biblio ? biblio.related : null" [config]="config.related" [stable]="true"></app-biblio-related>
        <app-biblio-related #RelatedNew headerText="(6x) Related Publication Information (New)" [data]="newBiblio ? newBiblio.related : null" [config]="config.related" [stable]="false"></app-biblio-related>
        
        <app-biblio-legal-event #LegalEvent headerText="Legal Status Events" [biblioId]="biblio ? biblio.id: null " [data]="biblio ? biblio.legalEvent : null" [config]="config" [stable]="true"></app-biblio-legal-event>
       
        <app-biblio-license #License headerText="License Information" [data]="biblio ? biblio.license : null" [config]="config.license" [stable]="true"></app-biblio-license>
        <app-biblio-license #LicenseNew headerText="License Information (New)" [data]="newBiblio ? newBiblio.license : null" [config]="config.license" [stable]="false"></app-biblio-license>
        
        <app-biblio-pledge #Pledge headerText="Pledge Information" [data]="biblio ? biblio.pledge : null" [config]="config.pledge" [stable]="true"></app-biblio-pledge>
        <app-biblio-pledge #PledgeNew headerText="Pledge Information (New)" [data]="newBiblio ? newBiblio.pledge : null" [config]="config.pledge" [stable]="false"></app-biblio-pledge>
        

        <app-biblio-claims nameType="CLM" headerText="Claims" #Claims [data]="biblio ? biblio.claims : null" [config]="config.claims" [stable]="true"></app-biblio-claims>
        <app-biblio-claims nameType="CLM" headerText="Claims (New)" #ClaimsNew [data]="newBiblio ? newBiblio.claims : null" [config]="config.claims" [stable]="false"></app-biblio-claims>
    </accordion>
</div>