import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, Configuration, UserAgentApplication } from 'msal';
import { Observable, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class SecurityService {
    msalInstance: UserAgentApplication
    private storage: StorageService;
    private authenticationSource = new Subject<boolean>();
    public UserData: Account;
    authenticationChallenge$ = this.authenticationSource.asObservable();
    private msalConfig: Configuration = {
        auth: {
            clientId: `${environment.client_id}`,
            authority: `https://login.microsoftonline.com/${environment.tenant_id}`,
            redirectUri: `${environment.client_redirect_uri}`
        },
    };
    // tslint:disable-next-line:max-line-length
    constructor(private _http: HttpClient, private _router: Router, private route: ActivatedRoute, private _storageService: StorageService) {
        this.storage = _storageService;
        this.msalInstance = new UserAgentApplication(this.msalConfig);
        this.msalInstance.handleRedirectCallback((autError, authResponse) => {
            this.ResetAuthorizationData();
            if (authResponse) {
                this.UserData = this.msalInstance.getAccount();
                this.IsAuthorized = true;
                this.authenticationSource.next(true);
                this.SetAuthorizationData(authResponse.accessToken, authResponse.idToken);
            }
        });

        if (this.storage.retrieve('IsAuthorized') !== '') {
            this.IsAuthorized = this.storage.retrieve('IsAuthorized');
            this.authenticationSource.next(true);
            this.UserData = this.storage.retrieve('userData');
        }
    }

    public IsAuthorized: boolean;

    public GetToken() {
        return fromPromise(this.msalInstance.acquireTokenSilent({ scopes: [`${environment.msal_diamond_api}`] }).then(authResponse => {
            return authResponse.accessToken;
        }));
    }

    public GetDiamondReportToken() {
        return fromPromise(this.msalInstance.acquireTokenSilent({ scopes: [`${environment.msal_diamond_report}`] }).then(authResponse => {
            return authResponse.accessToken;
        }));
    }

    public ResetAuthorizationData() {
        this.storage.store('authorizationData', '');
        this.storage.store('authorizationDataIdToken', '');
        this.storage.store('account', '');
        this.storage.store('userData', '');
        this.IsAuthorized = false;
        this.storage.store('IsAuthorized', false);
    }

    public SetAuthorizationData(token: any, id_token: any) {
        if (this.storage.retrieve('authorizationData') !== '') {
            this.storage.store('authorizationData', '');
        }

        this.storage.store('authorizationData', token);
        this.storage.store('authorizationDataIdToken', id_token);
        this.IsAuthorized = true;
        this.storage.store('IsAuthorized', true);
        this.storage.store('userData', this.UserData);
    }

    public Authorize() {
        this.msalInstance.loginRedirect({ scopes: ["user.read", `${environment.msal_diamond_api}`, `${environment.msal_diamond_report}`] });
    }


    public Logoff() {
        this.msalInstance.logout();
        this.ResetAuthorizationData()
        this.authenticationSource.next(false);
    }

    public HandleError(error: any) {
        console.log(error);
        // if (error.status === 403) {

        // } else if (error.status === 401) {
        // }
    }

    public getHeaders() {
        return this.GetToken().pipe(tokensub => new Observable<HttpHeaders>(subscriber => {
            tokensub.subscribe(token => {
                subscriber.next(new HttpHeaders({
                    'Authorization': token === null ? '' : 'Bearer ' + token,
                }));
            })
        }));
    }

    public getDiamondReportHeaders() {
        return this.GetDiamondReportToken().pipe(tokensub => new Observable<HttpHeaders>(subscriber => {
            tokensub.subscribe(token => {
                subscriber.next(new HttpHeaders({
                    'Authorization': token === null ? '' : 'Bearer ' + token,
                }));
            })
        }));
    }
}
