import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/services/data.service';
import { PatentService } from '../../patent.service';

@Component({
  selector: 'app-config-download',
  templateUrl: './config-download.component.html',
  styleUrls: ['./config-download.component.scss']
})
export class ConfigDownloadComponent implements OnInit, OnDestroy {

  countrycodes: Array<string> = [];
  popupVisible: boolean = false;
  countyCodesSub: Subscription;
  selectedCountry: string;
  loadingVisible: boolean = false;
  constructor(private service: PatentService, private dataService: DataService, private client: HttpClient) {

  }

  ngOnInit() {
    this.countyCodesSub = this.service.getAllCountryCodes().subscribe(d => this.countrycodes = d)
  }

  ngOnDestroy(): void {
    this.countyCodesSub.unsubscribe();
  }

  download() {
    this.loadingVisible = true;
    const url = `${this.service.reportUrl}config/${this.selectedCountry}/`;

    this.dataService.downloadExcel(url, `Configurations-${this.selectedCountry}`).subscribe(d => {
      this.loadingVisible = false;
      this.popupVisible = false;
    });
  }
}
