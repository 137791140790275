<div class="view-group" *ngIf="data && visible">
    <div *ngIf="config.dOfPublication.date_41.visible"><label>(41) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_41}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_42.visible"><label>(42) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_42}}</div>
    </div>
    <div *ngIf="config.publication.date.visible"><label>(43) {{headerText}}</label>
      <div class="value">{{data.publication.date}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_44.visible"><label>(44) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_44}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_45.visible"><label>(45) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_45}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_46.visible"><label>(46) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_46}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_47.visible"><label>(47) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_47}}</div>
    </div>
    <div *ngIf="config.dOfPublication.date_48.visible"><label>(48) {{headerText}}</label>
      <div class="value">{{data.dOfPublication.date_48}}</div>
    </div>
</div>