<accordion-group [formGroup]="formGroup" [isOpen]="openToggle" *ngIf="config && config.visible(stable)" (paste)="paste($event)" [ngClass]="{'heading-blue': !stable}">
  <div accordion-heading>
       {{headerText}}
      <i class="float-right" [ngClass]="{'dx-icon-spindown': !openToggle, 'dx-icon-spinup': openToggle}"></i>
  </div>
  <div formArrayName="formArray" class="well well-lg">
      <div *ngFor="let ipc of formArray.controls; let i=index" [formGroupName]="i" class="row">
          <div class="col-md-2" *ngIf="config && config.edition.visible">
              <div class="form-group">
                  <label class="app-biblio-title">(51) IPC Edition</label>
                  <input id="{{i}}-edition" class="form-control form-input" type="text" placeholder="[1-8]" formControlName="edition">
                  <div *ngIf="formArray.at(i).get('edition').hasError('required') && formArray.at(i).get('edition').touched" class="alert alert-danger">Required field.</div>
                  <div *ngIf="formArray.at(i).get('edition').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
              </div>
          </div>
          <div class="col-md-4" *ngIf="config && config.class.visible">
              <div class="form-group">
                  <label class="app-biblio-title">(51) Classification</label>
                  <input id="{{i}}-class" class="form-control form-input" type="text" formControlName="class" placeholder="{{config.class.pattern}}">
                  <div *ngIf="formArray.at(i).get('class').hasError('required') && formArray.at(i).get('class').touched" class="alert alert-danger">Required field.</div>
                  <div *ngIf="formArray.at(i).get('class').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
              </div>
          </div>
          <div class="col-md-4" *ngIf="config && config.date.visible">
              <div class="form-group">
                  <label class="app-biblio-title">(51) IPC Version</label>
                  <input id="{{i}}-date" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM" formControlName="date">
                  <div *ngIf="formArray.at(i).get('date').hasError('required') && formArray.at(i).get('date').touched" class="alert alert-danger">Required field.</div>
                  <div *ngIf="formArray.at(i).get('date').hasError('error')" class="alert alert-danger">Date format not match</div>
              </div>
          </div>
          <div class="col-md-4" *ngIf="config && config.ipcDate.visible">
            <div class="form-group">
                <label class="app-biblio-title">(51) IPC of the UI</label>
                <input id="{{i}}-ipcDate" class="form-control form-input" type="text" placeholder="YYYY[\s\.\-]MM" formControlName="ipcDate">
                <div *ngIf="formArray.at(i).get('ipcDate').hasError('required') && formArray.at(i).get('ipcDate').touched" class="alert alert-danger">Required field.</div>
                <div *ngIf="formArray.at(i).get('ipcDate').hasError('error')" class="alert alert-danger">Date format not match</div>
            </div>
        </div>
          <div class="col-md-4" *ngIf="config && config.domestic.visible">
            <div class="form-group">
                <label class="app-biblio-title">(52) Domestic or national classification</label>
                <input id="{{i}}-domestic" class="form-control form-input" type="text" placeholder="{{config.domestic.pattern}}" formControlName="domestic">
                <div *ngIf="formArray.at(i).get('domestic').hasError('required') && formArray.at(i).get('domestic').touched" class="alert alert-danger">Required field.</div>
                <div *ngIf="formArray.at(i).get('domestic').hasError('pattern')" class="alert alert-danger">Pattern not matched</div>
            </div>
        </div>
          <div class="col-md-2">
              <div class="form-group align-text-bottom">
                  <a (click)="deleteFormArray(i)" class="dx-icon-remove"></a>
              </div>
          </div>
      </div>
      <div class="row">
        <button class="dx-icon-add btn btn-outline-secondary btn-sm" (click)="addFormArray()"> Add</button>
    </div>
  </div>
</accordion-group>