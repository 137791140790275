<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.authority.visible && item.authority">
      <label>(56) {{headerText}}CC</label>
      <div class="value">{{item.authority}}</div>
    </div>
    <div *ngIf="config.number.visible && item.number">
      <label>(56) {{headerText}}Number</label>
      <div class="value">{{item.number}}</div>
    </div>
    <div *ngIf="config.kind.visible && item.kind">
      <label>(56) {{headerText}}Kind</label>
      <div class="value">{{item.kind}}</div>
    </div>
    <div *ngIf="config.date.visible && item.date">
      <label>(56) {{headerText}}Date</label>
      <div class="value">{{item.date}}</div>
    </div>
    <div *ngIf="config.language.visible && item.language">
      <label>(56) {{headerText}}Language</label>
      <div class="value">{{item.language}}</div>
    </div>
    <div *ngIf="config.applicant.visible && item.applicant">
      <label>(56) {{headerText}}Name</label>
      <div class="value">{{item.applicant}}</div>
    </div>


    <div *ngFor="let tr of item.translations">
      <div *ngIf="config.translations.language.visible && tr.language">
        <label>(561) Language</label>
        <div class="value">{{tr.language}}</div>
      </div>
      <div *ngIf="config.translations.tr.visible && tr.tr">
        <label>(561) Name</label>
        <div class="value">{{tr.tr}}</div>
      </div>
    </div>
  </div>
</div>