import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { PatentId } from '../../../shared/models/patent-id';
import { PatentService } from '../../patent.service';
import { BiblioBaseComponent } from '../biblio-base.component';

@Component({
  selector: 'app-biblio-int-convention',
  templateUrl: './biblio-int-convention.component.html',
  styleUrls: ['./biblio-int-convention.component.scss']
})
export class BiblioIntConventionComponent extends BiblioBaseComponent implements OnInit, OnChanges {
  private previousPubValue = '';
  private previousAppValue = '';
  @Input() biblioId: number;

  constructor(protected patentService: PatentService, protected formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && changes.data.currentValue && changes.data.previousValue !== changes.data.currentValue) {
      (this.formGroup as AbstractControl).patchValue(Object.assign({}, this.data, {designatedStates: []}));
      if (this.data.designatedStates) {
        this.setDesignatedStates(this.data.designatedStates);
      }
      else this.designatedStates.clear();
    }
    if (this.data && this.config && changes.config && changes.config.currentValue && changes.config.previousValue !== changes.config.currentValue) {
      this.setValidator(this.formGroup, this.config, this.config.ConfigName);
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      pctNationalDate: '',
      pctApplNumber: '',
      pctApplKind: '',
      pctApplDate: '',
      pctApplCountry: '',
      pctPublNumber: '',
      pctPublKind: '',
      pctPublDate: '',
      pctPublCountry: '',
      pctSearchDate: '',
      designatedStates: this.formBuilder.array([])
    });

    this.formGroup.get('pctPublNumber').valueChanges.subscribe((value) => {
      if (!this.biblioId && this.previousPubValue !== value) {
        this.previousPubValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, pctPublNumber: value });
      }
    });

    this.formGroup.get('pctApplNumber').valueChanges.subscribe((value) => {
      if (!this.biblioId && this.previousAppValue !== value) {
        this.previousAppValue = value;
        this.patentService.existCheck.next(<PatentId>{ id: this.biblioId, pctApplNumber: value });
      }
    });
  }

  setDesignatedStates(values: string[]) {
    const dStateFGs = values.map(value => this.formBuilder.group({
      state: value,
    }));
    const dStateFormArray = this.formBuilder.array(dStateFGs);
    this.formGroup.setControl('designatedStates', dStateFormArray);
  }

  get designatedStates(): FormArray {
    return this.formGroup.get('designatedStates') as FormArray;
  }

  addDesignatedState() {
    const control = this.formBuilder.group({
      state: '',
    });
    this.designatedStates.push(control);

    this.setValidator(control, this.config.designatedStates, 'designatedStates');
  }

  deleteDesignatedState(i: number) {
    this.designatedStates.removeAt(i);
  }

  get Value(): any {
     return Object.assign(this.formatObject(this.formGroup.value),
     { designatedStates: this.designatedStates.value.map(item => item.state) }) ;
  }

}

