import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import { Patent } from '../../../shared/models/patent';
import { PatentId } from '../../../shared/models/patent-id';
import { EventViewComponent } from '../../event-view/event-view.component';
import { PatentService } from '../../patent.service';

@Component({
  selector: 'app-patentlist',
  templateUrl: './patentlist.component.html',
  styleUrls: ['./patentlist.component.scss']
})
export class PatentlistComponent implements OnInit, OnDestroy {
  @Input() patents: Array<PatentId>;
  @Input() patentsDatasource: DataSource;
  popupVisible: boolean;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("list", { static: true }) list: DxListComponent;
  @ViewChild(EventViewComponent, { static: true }) eventView: EventViewComponent;
  currentGroupIndex: number = 0;
  currentGroupKey: string = '';
  searchValue: string;
  constructor(private service: PatentService) {
    // this.patentsDatasource = new DataSource({
    //   store: new ArrayStore({
    //     data: this.patents,
    //     key: 'id',
    //   }),
    //   group: 'category'
    // });
  }

  close() {
    this.popupVisible = false;
  }

  show() {
    this.popupVisible = true;
    if (this.currentGroupKey !== '') {
      this.patentsDatasource.filter(['category', '=', this.currentGroupKey]);
      this.patentsDatasource.load();

    }
  }

  searchChange(e: any) {
    // this.patentsDatasource.sea
    this.patentsDatasource.searchValue(e.value);
    this.patentsDatasource.load();
  }

  showAllGroup() {
    this.patentsDatasource.filter(['category', '<>', '']);
    this.patentsDatasource.reload();
    this.currentGroupKey = '';
    this.list.instance.repaint();
  }

  collapse(e: any) {
    if (this.currentGroupKey === '') {
      e.component.collapseGroup(e.groupIndex);
    }
  }

  changeGroupIndex(i: number, key: string) {
    this.currentGroupIndex = 0;
    this.currentGroupKey = key;
    this.patentsDatasource.filter(['category', '=', key]);
    this.patentsDatasource.reload()
  }

  ngOnInit() {


  }

  loadBiblio(id: number) {
    this.service.getBiblio(id).subscribe(data => {
      this.service.patent.next(data);
      this.popupVisible = false;
    });
  }

  deletePatent(patent: any, index: number) {
    const dialog = confirm('Do you really want to delete this patent? All data will be lost.', 'Confirm');
    dialog.then(dialogResult => dialogResult && this.remove(patent));
  }

  private remove(patent: any) {
    this.service.deleteBiblio(patent.id).subscribe(res => {
      const gi = this.list.items.findIndex(x => x.key == patent.category);
      const li = this.list.items.find(x => x.key == patent.category).items.findIndex(x => x.id == patent.id);
      this.list.instance.deleteItem({ group: gi, item: li });
    });
  }

  showView(id: number) {
    this.service.getPatentAndConfig(id).subscribe((data: any) => {
      const patent: Patent = { subCode: data.patent.subCode, biblio: JSON.parse(data.patent.biblio), newBiblio: JSON.parse(data.patent.newBiblio), legalEvent: JSON.parse(data.patent.legalEvent), pdf: data.patent.pdf };
      const config = JSON.parse(data.config.config);
      this.eventView.show(patent, config);
    });
  }

  ngOnDestroy(): void {
  }
}
