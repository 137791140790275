import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
// tslint:disable-next-line:max-line-length
import { DxButtonModule, DxCalendarModule, DxCheckBoxModule, DxTabsModule, DxScrollViewModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { AccountModule } from '../account/account.module';
import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { AppService } from './app.service';
import { PatentModule } from './patent/patent.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    HttpClientModule,
    SharedModule.forRoot(),
    PatentModule,
    DxButtonModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxCheckBoxModule, DxCalendarModule,
    DxScrollViewModule,
    BsDropdownModule.forRoot(),
    AccountModule,
    DxTabsModule
  ],
  providers: [
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
