<!-- <div class="container app-pdf-upload">
    <form [formGroup]="uploadForm" (ngSubmit)="submitForm()">
        <h3>PDF Upload</h3>
        <section>
            <div class="form-group">
                <label>Country Code</label>
                <input type="text" [formControl]="uploadForm.get('countryCode')">
            </div>
        </section>
        <section>
            <div id="fileuploader-container">
                <dx-file-uploader selectButtonText="Select PDF" [(value)]="files" labelText="" accept="*" uploadMode="useForm">
                </dx-file-uploader>
            </div>
        </section>

        <button type="submit" class="btn">Submit</button>
    </form>
</div> -->
<!-- <pre>{{uploadForm.value | json }} </pre> -->
<div class="container-fluid app-pdf-upload">
    <div class="row">
        <div class="col-md-3 h-100">
            <h3>Select files</h3>
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone">
                drop files here
            </div>
        </div>
        <div class="col-md-6" style="margin-bottom: 40px">
            <h3>Upload queue</h3>
            <p>Queue length: {{ uploader?.queue?.length }}</p>

            <table class="table">
                <thead>
                    <tr>
                        <th width="50%">Name</th>
                        <th>Size</th>
                        <th>Progress</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>
                        <td nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        <td>
                            <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                            </div>
                        </td>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-success btn-sm" (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                              <span class="dx-icon-upload"></span>Upload
                          </button>
                            <button type="button" class="btn btn-warning btn-sm" (click)="item.cancel()" [disabled]="!item.isUploading">
                              <span class="dx-icon-revert"></span>Cancel
                          </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="item.remove()">
                              <span class="dx-icon-trash"></span>Remove
                          </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div>
                <div>
                    Queue progress:
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                    </div>
                </div>
                <button type="button" class="btn btn-success btn-lg" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                    <span class="dx-icon-upload"></span>Upload all
                  </button>
                <button type="button" class="btn btn-warning btn-lg" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                  <span class="dx-icon-revert"></span>Cancel all
                  </button>
                <button type="button" class="btn btn-danger btn-lg" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                  <span class="dx-icon-trash"></span>Remove all
                  </button>
            </div>

        </div>

    </div>

</div>