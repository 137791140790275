import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { Config, Configuration } from '../../shared/models/iconfig.model';
import { Patent } from '../../shared/models/patent';
import { PdfModel } from '../../shared/models/pdf-model';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {
  popupVisible: boolean = false;
  constructor() { }
  pdf: PdfModel;
  event: Patent;
  config: Config;
  @Input() position: string;
  @ViewChild("popup", { static: true }) popup: DxPopupComponent;
  ngOnInit() {
    this.config = new Config;
    this.popup.width = this.width;
  }

  show(patent: Patent, config: Config) {
    this.pdf = patent.pdf;
    this.event = patent;
    const cfg = new Config;
    this.config = DataService.patchValue(cfg, config);
    this.popupVisible = true;
  }

  visible(path: string) {
    const c = this.findConfig(path);
    if (c) {
      return c.visible;
    }
  }


  get width(): number {
    return window.innerWidth * 0.49;
  }

  private findConfig(path: string): Configuration {
    const props = path.split('.');
    let obj = this.config as object;
    props.forEach(key => {
      if (obj && Object.keys(obj).length > 0) {
        obj = obj[key];
      }
    });

    return obj as Configuration;
  }
}
