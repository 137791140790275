<div class="view-group" *ngIf="data && config.visible(stable)">
  <div *ngFor="let item of data">
    <div *ngIf="config.language.visible && item.language">
      <label>{{headerText}} Language</label>
      <div class="value">{{item.language}}</div>
    </div>
    <div *ngIf="config.text.visible && item.text">
      <label>{{headerText}} Text</label>
      <div class="value">{{item.text}}</div>
    </div>


    <div *ngFor="let tr of item.translations">
      <div *ngIf="config.translations.language.visible && tr.language">
        <label>{{headerText}} Language</label>
        <div class="value">{{tr.language}}</div>
      </div>
      <div *ngIf="config.translations.tr.visible && tr.tr">
        <label>{{headerText}} Text</label>
        <div class="value">{{tr.tr}}</div>
      </div>

    </div>
  </div>
</div>